import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import axios from "axios"
import ReactPaginate from "react-paginate"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Modal,
  Label,
  FormGroup,
  Alert,
  CardHeader,
  BreadcrumbItem,
  Nav,
  NavItem,
  Button,
  Progress,
  Spinner,
} from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { AvField, AvForm } from "availity-reactstrap-validation"

import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import toast, { Toaster } from "react-hot-toast"
import applyIcon from "../../assets/images/apply-icon.svg"
import trashIcon from "../../assets/images/trash-icon.svg"
import purplePlus from "../../assets/images/purple-plus.svg"
import whitePlus from "../../assets/images/white-plus.svg"
import DataTable from "react-data-table-component"
import plus from "../../assets/images/white-plus.svg"
import SweetAlert from "react-bootstrap-sweetalert"
import skipIcon from "../../assets/images/skipicon.svg"
import Exclamation from "../../assets/images/triangleExclamation.svg"
// import 'react-data-table-component/styles.css';

import {
  getCountStats,
  downloadStats,
  getCampaign,
  getLeadStages,
  getCampaignMetrics,
  campaignFilterProspects,
} from "../../store/campaigns/actions"

import threedotIcon from "../../assets/images/threedot.svg"

import ic1 from "../../assets/images/icc1.svg"
import ic2 from "../../assets/images/icc2.svg"
import ic3 from "../../assets/images/icc3.svg"
import ic4 from "../../assets/images/icc4.svg"
import ic5 from "../../assets/images/icc5.svg"
import ic6 from "../../assets/images/icc6.svg"
import wrongNumberIcon from "../../assets/images/wrongNumberIcon.svg"
import downloadIcon from "../../assets/images/download.svg"

import dripIcon from "../../assets/images/dripIcon.svg"
import warningIcon from "../../assets/images/warningIcon.svg"
import crossIcon from "../../assets/images/crossIcon.svg"

import RadialChart from "../AllCharts/apex/apexdonut1"

import redWarning from "../../assets/images/redWarning.svg"

import ApexChartSemiDonut from "../AllCharts/apex/apexsemidonut"

import moment from "moment"
import CampaignHeader from "./CampaignHeader"

import "chartist/dist/scss/chartist.scss"
import close from "../../assets/images/close.svg"
import rightArrow from "../../assets/images/right-arrow.svg"
import qualifiedLead from "../../assets/images/qualifiedLead.svg"

import intervalIcon from "../../assets/images/interval.svg"
import startTimeIcon from "../../assets/images/startTime.svg"
import endTimeIcon from "../../assets/images/endTime.svg"
import dateIcon from "../../assets/images/Date.svg"
import planeWhite from "../../assets/images/planeWhite.svg"
import io from "socket.io-client"

class Metrics extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      campaign_id: null,
      id: this.props.match.params.id ? this.props.match.params.id : null,
      chartData: [],
      reloadKey: Math.random(),
      isLoading: false,
      selectCampaignData: {
        data: [],
        page: 1,
        limit: 10,
        totalCount: 0,
      },
      batches: [],
      activeBatchIndex: null,
      activeTab: "list",
      schedularData: [],
      schedularView: null,
      schedularDetail: null,
      deleteSchedular: {
        isOpen: false,
        data: null,
      },
      isValidDate: null,

      categoryList: [],
      activeBatch: {},
      addSchedularData: {},
      availableProspectCount: 0,
    }

    let authUser = localStorage.getItem("authUser")

    if (authUser) {
      let user = JSON.parse(authUser)

      this.socket = io(process?.env.REACT_APP_SOCKET_URL, {
        //process?.env.REACT_APP_PORT,
        //"http://192.168.29.121:5001"
        query: {
          email: user.user.name,
        },
      })
    }
    this.socket.on("shchedulerUpdate", data => {
      // console.log(data, 'at the top of the socket')
      if (data) {
        if (data?.error) {
          this.notify("error", data?.error)
        }

        if (this.state.activeTab === "list") {
          // console.log('another test ')
          const { schedularData } = this.state
          const updatedData = schedularData.map(obj => {
            if (obj._id === data?.scheduler?._id) {
              return { ...data?.scheduler }
            }
            return obj
          })

          // console.log(updatedData , 'udpated data')

          this.setState({ schedularData: updatedData })
        } else if (this.state.activeTab === "view") {
          if (
            data?.scheduler?._id === this.state?.schedularDetail?.scheduler?._id
          ) {
            // console.log(data , 'data of the socket ')
            const { schedularDetail } = this.state
            let copyObj = {
              ...schedularDetail,
              scheduler: { ...data?.scheduler },
            }

            //  console.log(copyObj , 'before the batches')
            const newObj = schedularDetail.batches.map(obj => {
              if (obj._id === data?.schedulerBatch?._id) {
                return {
                  ...obj,
                  status: data?.schedulerBatch?.status,
                  prospectSend: data?.schedulerBatch?.prospectSend,
                }
              } else {
                return { ...obj }
              }
            })

            // console.log(newObj , ' new object ')
            copyObj = { ...copyObj, batches: [...newObj] }

            // console.log(copyObj , 'copy obj ')

            this.setState({
              schedularDetail: copyObj,
            })
          }
        }
      }
    })
  }
  validPeriod = {
    startTime: "09:00",
    endTime: "18:00",
  }
  getData(id = this.props.match.params.id) {
    // let id = this.props.match.params.id
    if (id) {
      this.state.campaign_id = id

      this.props.getCampaign(this.props.match.params.id)
      // this.props.getCountStats(id)
      // this.props.getCampaignMetrics(id)
      // this.props.getCampaigns()
    }
  }
  closeSchedulerHandler = () => {
    this.setState({
      activeTab: "list",
      schedularView: null,
      isLoading: true,
    })
    this.getSchedulers(this.props.match.params.id)
  }

  getCategoryOptions = index => {
    const selectedCats = []
    this.state.batches.forEach((item, i) => {
      if (index !== i) {
        selectedCats.push(item?.templateCat)
      }
    })
    const unselectedCat = this.state.categoryList.filter(
      item => !selectedCats.includes(item?._id)
    )
    return unselectedCat.map((item, index) => {
      return (
        <option key={index} value={item?._id}>
          {item?.name}
        </option>
      )
    })
  }
  async getCampaigns(
    page = this.state.selectCampaignData.page,
    limit = this.state.selectCampaignData.limit
  ) {
    this.setState({ isLoading: true })
    const authUser = localStorage.getItem("authUser")
    let token = false
    if (authUser) {
      let user = JSON.parse(authUser)
      token = user?.user?.token
    } else {
      token = false
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
    try {
      const queryParams = new URLSearchParams({ page, limit })
      const response = await axios.get(
        process.env.REACT_APP_PORT +
          "/api/campaign/getAllCampaigns?" +
          queryParams,
        config
      )
      this.setState(prevState => ({
        selectCampaignData: {
          ...prevState.selectCampaignData,
          data: response.data?.data?.campaigns,
          totalCount: response.data?.data?.totalCount,
        },
        isLoading: false,
      }))
    } catch (err) {
      this.setState({ isLoading: false })
      this.notify("error", err)
    }
  }

  calculatePercentage(nr, dr, dec = 0) {
    if (dr == 0 || !dr || !nr) {
      return 0
    }
    return +((+nr / +dr) * 100).toFixed(dec)
  }
  toggleCampaignList() {
    this.state.show = !this.state.show
  }

  notify(type, text) {
    switch (type) {
      case "success":
        toast.success(text)
        break
      case "error":
        if (typeof text !== "string") {
          if (text?.message && typeof text?.message === "string") {
            toast.error(text?.message)
          } else {
            toast.error("An unexpected error occurred")
          }
        } else {
          toast.error(text)
        }
        break

      default:
        break
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state?.schedularView !== null) {
      if (prevState.schedularView !== this.state.schedularView) {
        this.getSchedulerDetails(this.state.schedularView)
      }
    }

    if (this.props.campaign && this.props.campaign !== prevProps?.campaign) {
      this.getSchedulers(this.props?.campaign?._id)
    }

    if (!prevProps.successMsg && this.props.successMsg) {
      this.notify("success", this.props.successMsg)
    } else if (
      this.props.apiError &&
      typeof this.props.apiError === "string" &&
      !prevProps.apiError
    ) {
      this.notify("error", this.props.apiError)
    } else if (
      !prevProps.apiError &&
      this.props.apiError &&
      typeof this.props.apiError === "object"
    ) {
      if (this.props.apiError.response) {
        this.notify("error", this.props.apiError.response.data.error)
      } else {
        this.notify("error", this.props.apiError.message)
      }
    }
  }

  handleDataChange = (index, key, val) => {
    let tempArr = [...this.state.batches]
    tempArr[index][key] = val
    this.setState({ batches: tempArr })

    if (key == "totalProspectCount") {
      this.handleBatchTimeChange(index)
    }
  }

  handleBatchTimeChange = (index, prevBatchEndTime = null) => {
    let currentBatch = this.state.batches[index]
    const timeInMin = Number(
      (
        (currentBatch.totalProspectCount * +this.props.campaign.delay) /
        60
      ).toFixed(0)
    )
    const endTime = this.addMinutesToTime(
      prevBatchEndTime || currentBatch.startTime,
      timeInMin
    )
    currentBatch.endTime = endTime
    if (prevBatchEndTime) {
      currentBatch.startTime = prevBatchEndTime
    }
    let tempArr = [...this.state.batches]
    tempArr[index] = currentBatch
    this.setState({ batches: tempArr })
    if (index !== this.state.batches.length - 1) {
      this.handleBatchTimeChange(index + 1, endTime)
    }
  }

  addBatchHandler = () => {
    const totalBatches = this.state.batches?.length
    let batches = []
    if (!totalBatches) {
      batches.push({
        startTime: this.state?.addSchedularData?.startTime
          ? this.state?.addSchedularData?.startTime
          : null,
        endTime: null,
        totalProspectCount: 0,
        templateCat: null,
      })
    } else {
      batches = [...this.state.batches]
      batches.push({
        startTime: this.state.batches[totalBatches - 1].endTime,
        endTime: null,
        totalProspectCount: 0,
        templateCat: null,
      })
    }
    this.setState({ batches, activeBatchIndex: totalBatches })
  }

  removeBatchHandler = index => {
    let tempArr = [...this.state.batches]
    tempArr.splice(index, 1)
    this.setState(
      {
        batches: tempArr,
        activeBatchIndex:
          this.state.activeBatchIndex > 0
            ? this.state.activeBatchIndex - 1
            : null,
        // reloadKey: Math.random(),
      },
      () => {
        if (index == 0 && tempArr.length) {
          this.handleBatchTimeChange(0, this.state.addSchedularData.startTime)
        } else if (index < tempArr.length) {
          this.handleBatchTimeChange(index, tempArr[index - 1].endTime)
        }
      }
    )
  }

  getCategoryName = id => {
    return this.state.categoryList?.filter(cat => cat._id == id)?.[0]?.name
  }

  componentDidMount() {
    this.setState({ isLoading: true })
    // this.getCampaigns()
    this.getCategories()
    // this.getData()
    this.props.getCampaign(this.props.match.params.id)
    // this.props.getLeadStages()
    this.getSchedulers(this.props.match.params.id)
  }

  async getSchedulers(campaignId) {
    const authUser = localStorage.getItem("authUser")
    let token = false
    if (authUser) {
      let user = JSON.parse(authUser)
      token = user?.user?.token
    } else {
      token = false
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
    this.setState({
      isLoading: true,
    })
    try {
      const response = await axios.get(
        process.env.REACT_APP_PORT +
          `/api/scheduler/pullSchedulers/${campaignId}`,
        config
      )

      if (response.data && Array.isArray(response.data)) {
        this.setState({
          schedularData: response?.data,
          isLoading: false,
        })
      }
    } catch (err) {
      this.notify("error", err)
      this.setState({ isLoading: false })
    }
  }
  async getCategories() {
    const authUser = localStorage.getItem("authUser")
    let token = false
    if (authUser) {
      let user = JSON.parse(authUser)
      token = user?.user?.token
    } else {
      token = false
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
    this.setState({
      isLoading: true,
    })
    try {
      const response = await axios.get(
        process.env.REACT_APP_PORT + "/api/template/pullCategories",
        config
      )

      if (response.data) {
        this.setState({
          categoryList: response?.data?.data,
          isLoading: false,
        })
      }
    } catch (err) {
      this.notify("error", err)
      this.setState({ isLoading: false })
    }
  }
  async getSchedulerDetails(schedularId) {
    const authUser = localStorage.getItem("authUser")
    let token = false
    if (authUser) {
      let user = JSON.parse(authUser)
      token = user?.user?.token
    } else {
      token = false
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
    this.setState({
      isLoading: true,
    })
    try {
      const response = await axios.get(
        process.env.REACT_APP_PORT +
          `/api/scheduler/getSchedulerDetail/${schedularId}`,
        config
      )

      if (response.data) {
        this.setState({
          schedularDetail: response?.data,
          isLoading: false,
        })
      }
    } catch (err) {
      this.notify("error", err)
      this.setState({ isLoading: false })
    }
  }

  async handleDeleteSchedular(schedularId) {
    const authUser = localStorage.getItem("authUser")
    let token = false
    if (authUser) {
      let user = JSON.parse(authUser)
      token = user?.user?.token
    } else {
      token = false
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
    this.setState({
      isLoading: true,
    })
    try {
      const response = await axios.delete(
        process.env.REACT_APP_PORT +
          `/api/scheduler/deleteScheduler/${schedularId}`,
        config
      )

      if (response.data) {
        this.notify("success", "Scheduler deleted successfully")
        this.setState({
          isLoading: false,
          deleteSchedular: {
            isOpen: false,
            data: null,
          },
        })
        this.getSchedulers(this.props?.campaign?._id)
      }
    } catch (err) {
      this.notify("error", err.response.data.error)
      this.setState({ isLoading: false })
    }
  }

  // isFutureDateTime(startDate, startTime) {
  //   const startDateObj = new Date(startDate)
  //   const [hours, minutes] = startTime.split(":")
  //   startDateObj.setHours(Number(hours), Number(minutes), 0, 0)
  //   const currentDate = new Date()

  //   return startDateObj > currentDate
  // }

  getDate(currentDate) {
    const day = currentDate.getDate()
    const month = currentDate.getMonth() + 1 // Months are zero-based, so we add 1
    const year = currentDate.getFullYear()

    return `${year}-${month < 10 ? "0" + month : month}-${
      day < 10 ? "0" + day : day
    }`
  }

  async handleToggleSchedular(scheduler, action) {
    const authUser = localStorage.getItem("authUser")
    let token = false
    if (authUser) {
      let user = JSON.parse(authUser)
      token = user?.user?.token
    } else {
      token = false
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
    this.setState({
      isLoading: true,
    })

    const data = {
      scheduler: scheduler,
      action: action,
    }

    try {
      const response = await axios.post(
        process.env.REACT_APP_PORT + "/api/scheduler/toggle",
        data,
        config
      )
      if (response.data) {
        this.setState(prevState => ({
          isLoading: true,
          // schedularDetail: {
          //   ...prevState.schedularDetail,
          //   scheduler: {
          //     ...prevState.schedularDetail.scheduler,
          //     status: action,
          //   },
          // },
        }))
        this.notify("success", "Scheduler updated successfully")
        await this.getSchedulerDetails(scheduler)
      }
    } catch (err) {
      this.setState({
        isLoading: false,
      })

      this.notify("error", err.response?.data?.message)
    }
  }

  isDateEqual(inputDate) {
    const inputDateObj = new Date(inputDate)

    // Get current date
    const currentDate = new Date()

    // Extract year, month, and day from inputDate and currentDate
    const inputYear = inputDateObj.getFullYear()
    const inputMonth = inputDateObj.getMonth()
    const inputDay = inputDateObj.getDate()

    const currentYear = currentDate.getFullYear()
    const currentMonth = currentDate.getMonth()
    const currentDay = currentDate.getDate()

    return (
      inputYear === currentYear &&
      inputMonth === currentMonth &&
      inputDay === currentDay
    )
  }

  async handleCheckValidDate(startDate) {
    const authUser = localStorage.getItem("authUser")
    let token = false
    if (authUser) {
      let user = JSON.parse(authUser)
      token = user?.user?.token
    } else {
      token = false
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
    this.setState({
      isLoading: true,
    })

    const data = {
      startDate: startDate,
      campaign: this.props.match.params.id,
    }

    try {
      const response = await axios.post(
        process.env.REACT_APP_PORT + "/api/scheduler/checkDateForScheduler",
        data,
        config
      )
      if (response.data) {
        if (response.data) {
          this.setState({
            isLoading: false,
            isValidDate: true,
            availableProspectCount: response.data?.prospectCount,
          })
        }
      }
    } catch (err) {
      this.setState({
        isLoading: false,
        isValidDate: false,
      })

      // this.notify("error", err.response?.data?.message)
    }
  }

  secondsToTime = seconds => {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const remainingSeconds = Math.floor(seconds % 60)

    const formattedHours = String(hours).padStart(2, "0")
    const formattedMinutes = String(minutes).padStart(2, "0")
    const formattedSeconds = String(remainingSeconds).padStart(2, "0")

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
  }

  addMinutesToTime(time, minutesToAdd) {
    const [hoursStr, minutesStr] = time.split(":")

    // Convert hours and minutes to numbers
    let hours = parseInt(hoursStr)
    let minutes = parseInt(minutesStr)

    // Add the minutes
    minutes += minutesToAdd

    // Calculate new hours and minutes
    hours += Math.floor(minutes / 60)
    minutes %= 60

    // Ensure hours stay within 24-hour format
    hours %= 24

    // Format hours and minutes as strings with leading zeros
    const newHours = String(hours).padStart(2, "0")
    const newMinutes = String(minutes).padStart(2, "0")

    // Return the updated time
    return `${newHours}:${newMinutes}`
  }

  isTimeInRange(time, validPeriod = this.validPeriod) {
    const { startTime, endTime } = validPeriod

    // Convert time strings to minutes since midnight for easier comparison
    const timeInMinutes = this.convertToMinutesSinceMidnight(time)
    const startTimeInMinutes = this.convertToMinutesSinceMidnight(startTime)
    const endTimeInMinutes = this.convertToMinutesSinceMidnight(endTime)

    // Check if the time is within the range
    return (
      timeInMinutes >= startTimeInMinutes && timeInMinutes <= endTimeInMinutes
    )
  }

  convertToMinutesSinceMidnight(time) {
    const [hours, minutes] = time.split(":").map(Number)
    return hours * 60 + minutes
  }

  isBatchValid = index => {
    const batch = this.state.batches[index]
    let _totalProsCount = 0
    for (let i = 0; i < index + 1; i++) {
      const _batch = this.state.batches[i]
      _totalProsCount += _batch.totalProspectCount
    }

    return (
      batch &&
      batch?.templateCat &&
      batch?.startTime &&
      batch?.endTime &&
      this.isTimeInRange(batch.startTime) &&
      this.isTimeInRange(batch.endTime) &&
      batch.totalProspectCount &&
      _totalProsCount <= this.state.availableProspectCount
    )
  }
  isProsCountValid = index => {
    let _totalProsCount = 0
    for (let i = 0; i < index + 1; i++) {
      const _batch = this.state.batches[i]
      _totalProsCount += _batch.totalProspectCount
    }

    return _totalProsCount <= this.state.availableProspectCount
  }

  totalIncludedProspect = (index = this.state.batches.length - 1) => {
    let _totalProsCount = 0
    for (let i = 0; i < index + 1; i++) {
      const _batch = this.state.batches[i]
      _totalProsCount += _batch.totalProspectCount
    }

    return _totalProsCount
  }

  canCreateNewBatch = () => {
    return (
      this.state.isValidDate &&
      this.state.addSchedularData?.startTime &&
      (!this.state.batches?.length ||
        this.isBatchValid(this.state.batches.length - 1))
    )
  }
  canCreateNewScheduler = () => {
    let batchesValid = this.state.batches.length
    if (batchesValid) {
      for (let index = 0; index < this.state.batches.length; index++) {
        if (!this.isBatchValid(index)) {
          batchesValid = false
          break
        }
      }
    }
    return (
      this.state.isValidDate &&
      this.isFutureDateTime(
        this.state.addSchedularData?.date,
        this.state.addSchedularData?.startTime
      ) &&
      this.state.addSchedularData?.startTime &&
      this.state.addSchedularData?.schedularName &&
      // this.state.addSchedularData?.endTime &&
      batchesValid
    )
  }

  async handleAddSchedular() {
    const authUser = localStorage.getItem("authUser")
    let token = false
    if (authUser) {
      let user = JSON.parse(authUser)
      token = user?.user?.token
    } else {
      token = false
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }

    const data = {
      name: this.state.addSchedularData?.schedularName,
      campaign: this.props.campaign?._id,
      totalProspectCount: this.totalIncludedProspect(),
      startDate: this.state.addSchedularData?.date,
      startTime: this.state.batches[0].startTime,
      endTime: this.state.batches[this.state.batches?.length - 1].endTime,
      batches: this.state.batches,
    }

    try {
      const response = await axios.post(
        process.env.REACT_APP_PORT + "/api/scheduler/createScheduler",
        data,
        config
      )
      if (response.data) {
        this.setState({
          isLoading: false,
          activeTab: "list",

          activeBatch: {},
          addSchedularData: {},
          availableProspectCount: 0,
          batches: [],
          activeBatchIndex: null,
        })
        this.notify("success", "Scheduler Added successfully")
        this.getSchedulers(this.props.campaign._id)
      }
    } catch (err) {
      this.setState({
        isLoading: false,
      })

      this.notify("error", err.response?.data?.message)
    }
  }

  roundTimeToNearestTenMinutes(timeString) {
    const currentDate = this.state.addSchedularData.date
      ? new Date(this.state.addSchedularData.date)
      : new Date()

    // const currentDate = new Date(this.state.addSchedularData.date) || new Date();

    // Split the time string into hours and minutes
    const [hours, minutes] = timeString.split(":").map(Number)

    // Get current date and time
    const now = new Date()

    // Check if the provided date is today
    const isToday =
      currentDate.getDate() === now.getDate() &&
      currentDate.getMonth() === now.getMonth() &&
      currentDate.getFullYear() === now.getFullYear()

    // Calculate the total minutes
    const totalMinutes = hours * 60 + minutes

    // Round the total minutes to the nearest multiple of 10
    let roundedMinutes = Math.round(totalMinutes / 10) * 10

    // If the date is today and rounded time is less than the current time, set it to the current time
    if (isToday) {
      const currentTotalMinutes = now.getHours() * 60 + now.getMinutes()
      if (roundedMinutes < currentTotalMinutes) {
        roundedMinutes = Math.ceil(currentTotalMinutes / 10) * 10
      }
    }

    // Calculate the new hours and minutes
    const newHours = Math.floor(roundedMinutes / 60)
    const newMinutes = roundedMinutes % 60

    // Format the new time
    const formattedTime = `${newHours.toString().padStart(2, "0")}:${newMinutes
      .toString()
      .padStart(2, "0")}`

    return formattedTime
  }

  isFutureDateTime(_selectedDate, selectedTime) {
    console.log(
      "Selected Date:",
      _selectedDate,
      "        Selected Time:",
      selectedTime
    )
    if (!selectedTime) return true
    const today = new Date()
    const selectedDate = _selectedDate ? new Date(_selectedDate) : new Date()
    const selectedDateString = selectedDate.toISOString().slice(0, 10)
    const todayString = today.toISOString().slice(0, 10)

    if (selectedDateString === todayString) {
      const [hours, minutes] = selectedTime.split(":").map(Number)
      const currentTime = today
      const currentHours = currentTime.getHours()
      const currentMinutes = currentTime.getMinutes()
      if (
        hours > currentHours ||
        (hours === currentHours && minutes > currentMinutes)
      ) {
        return true // The given time is after the current time
      } else {
        return false // The given time is not after the current time
      }
    }

    return true
  }

  render() {
    const schedularColumns = [
      {
        name: "Name",
        selector: row => row.name,
        minWidth: "300px",
      },
      {
        name: "Total Prospects Count",
        selector: row => row.totalProspectCount,
        minWidth: "190px",
      },
      {
        name: "Prospect Sent",
        selector: row => row.prospectSend,
      },
      {
        name: "Interval",
        selector: row => row.interval,
      },
      {
        name: "Status",
        selector: row => row.status,
      },
      {
        name: "System Comment",
        selector: row => row.systemComment,
        minWidth: "210px",
      },
      {
        name: "Start Date",
        selector: row => row.startDate,
      },
      {
        name: "Start Time",
        selector: row => row.startTime,
      },
      {
        name: "View",
        selector: row => row.view,
      },
      {
        name: "Delete",
        selector: row => row.delete,
      },
    ]

    const batchesColumn = [
      {
        name: "Category Name",
        selector: row => row.categoryName,
      },
      {
        name: "Total Prospects Count",
        selector: row => row.totalProspectCount,
      },
      {
        name: "Prospect Sent",
        selector: row => row.prospectSend,
      },
      {
        name: "Status",
        selector: row => row.status,
      },
      {
        name: "System Comment",
        selector: row => row.systemComment,
      },
      {
        name: "Start Time",
        selector: row => row.startTime,
      },
      {
        name: "End Time",
        selector: row => row.endTime,
      },
    ]

    const schedularListingData = []
    this.state.schedularData?.length
      ? this.state?.schedularData?.map((item, index) => {
          schedularListingData.push({
            name: item?.name ? (
              <span style={{ wordBreak: "break-word" }}>{item?.name}</span>
            ) : (
              "-"
            ),
            totalProspectCount: item?.totalProspectCount
              ? item?.totalProspectCount
              : "-",
            prospectSend: item?.prospectSend >= 0 ? item?.prospectSend : "-",
            interval: item?.interval ? item?.interval : "-",
            startDate: item?.startDate
              ? moment.utc(item?.startDate).local().format("MM-DD-YYYY")
              : "-",
            startTime: item?.startTime ? item?.startTime : "-",
            status: item?.status ? (
              <span style={{ textTransform: "capitalize" }}>
                {item?.status}
              </span>
            ) : (
              "-"
            ),
            systemComment: item?.systemComment ? (
              <span className="reminder-table-note">{item.systemComment}</span>
            ) : (
              "-"
            ),

            view: (
              <Button
                className={
                  "has-icon me-2 d-flex justify-content-center  align-items-center"
                }
                color={"outline-primary"}
                onClick={() =>
                  this.setState({
                    activeTab: "view",
                    schedularView: item?._id,
                  })
                }
              >
                <i className="fas fa-eye"></i>
              </Button>
            ),

            delete: (
              <Button
                key={index}
                onClick={() => {
                  this.setState({
                    deleteSchedular: {
                      isOpen: true,
                      data: item,
                    },
                  })
                }}
                className={"has-icon"}
                color={"outline-danger"}
                // disabled={
                //   !this.isFutureDateTime(
                //     item?.startDate,
                //     item?.startTime ? item?.startTime : "00:00"
                //   ) && !item.status === "pending"
                // }
                disabled={item.status !== "pending"}
              >
                <i className="fas fa-trash-alt"></i>
              </Button>
            ),

            // response: `🔥 ${item?.received}`,
            // responseRate: (
            //   <div className="st-tag pending">
            //     <span>
            //       {+item?.received
            //         ? ((+item?.received / +item?.sent) * 100).toFixed(1) + "%"
            //         : "-"}
            //     </span>
            //   </div>
            // ),
            // lastSent: moment
            //   .utc(item?.createdAt)
            //   .local()
            //   .format("MM-DD-YYYY | hh:mm A"),
            // lastReceived: moment
            //   .utc(item?.date_end)
            //   .local()
            //   .format("MM-DD-YYYY | hh:mm A"),
          })
        })
      : null

    const batchesListing = []
    this.state.schedularDetail?.batches.map((item, index) => {
      batchesListing.push({
        categoryName: item?.templateCat.name ? item?.templateCat.name : "-",
        totalProspectCount: item?.totalProspectCount
          ? item?.totalProspectCount
          : "-",
        prospectSend: item?.prospectSend >= 0 ? item?.prospectSend : "-",
        startTime: item?.startTime ? item?.startTime : "-",
        endTime: item?.endTime ? item?.endTime : "-",
        status: item?.status ? (
          <span style={{ textTransform: "capitalize" }}>{item?.status}</span>
        ) : (
          "-"
        ),
        systemComment: item?.systemComment ? item?.systemComment : "-",
      })
    })

    const prospectsColumns = [
      {
        name: "Name",
        selector: "name",
      },
      {
        name: "Phone",
        selector: "phone",
      },
      {
        name: "Status",
        selector: "status",
      },
      {
        name: "Last Message",
        selector: "lastMessage",
      },
    ]

    const prospectsData =
      this.props?.campaign?.prospects &&
      this.props.campaign.prospects?.map((item, index) => ({
        name: item?.first_name + " " + item?.last_name,
        phone: item?.phone,
        status: item?.dnc ? (
          <div>
            <i className="fas fa-phone-slash text-danger" title="DNC" text />
            <span> DNC</span>
            {/* <img src={ic3} alt="" /> */}
          </div>
        ) : item?.isDripAdded ? (
          <div>
            <img src={dripIcon} alt="" title="Drip" />
            <span> Drip</span>
          </div>
        ) : item?.isPriority ? (
          <div>
            <i className="fas fa-bolt ps-2" title="Priority" />
            <span> Priority</span>
          </div>
        ) : (
          "No Status"
        ),
        lastMessage: moment
          .utc(item?.lastsmssend)
          .local()
          .format("MM-DD-YYYY | hh:mm A"),
      }))

    return (
      <React.Fragment>
        {this.state.deleteSchedular.isOpen && (
          <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmBtnText="Yes, remove it"
            confirmBtnBsStyle="primary"
            cancelBtnBsStyle="dark"
            onConfirm={() => {
              // this.setState({
              //   confirmProspectDelete: { show: false },

              // })
              this.handleDeleteSchedular(this.state.deleteSchedular?.data?._id)
              // this.props.deleteProspects({
              //   id: this.state.confirmProspectDelete.id ,
              // })
            }}
            onCancel={() => {
              this.setState({ deleteSchedular: { isOpen: false } })
            }}
          >
            You want to delete{" "}
            <strong> {this.state.deleteSchedular?.data?.name}</strong>
            <br /> You won't be able to revert this!
          </SweetAlert>
        )}
        <div className="page-content">
          <MetaTags>
            <title>Campaign | TextLaunchPad.com </title>
          </MetaTags>
          <div className="container-fluid">
            {this.state.isLoading && (
              <div className="spinner-wr">
                <Spinner style={{ color: "#5932ea" }} />
              </div>
            )}
            {this.props.campaign ? (
              <CampaignHeader
                campaign={this.props.campaign}
                leadStages={this.props.leads}
                campaignFilterProspects={this.props.campaignFilterProspects}
              />
            ) : null}

            <Row className={"pt-2"}>
              <Col lg={12}>
                {/* {this.props.apiError &&
                typeof this.props.apiError === "string" ? (
                  <Alert color="danger">{this.props.apiError}</Alert>
                ) : null} */}

                {/* {this.props.apiError &&
                typeof this.props.apiError === "object" ? (
                  this.props.apiError.response ? (
                    <Alert color="danger">
                      {this.props.apiError.response.data.error}
                    </Alert>
                  ) : (
                    <Alert color="danger">{this.props.apiError.message}</Alert>
                  )
                ) : null} */}

                {/* {this.props.successMsg ? (
                  <Alert color="success">{this.props.successMsg}</Alert>
                ) : null} */}
              </Col>

              <Toaster />
            </Row>

            <div className="c-card s-nav mb-3">
              <Row>
                <Col lg={12} className="">
                  <Nav tabs className="nav-tabs-custom cc-tabs">
                    <NavItem>
                      <Link
                        style={{ cursor: "pointer" }}
                        className={"nav-link"}
                        to={"/campaign/" + this.state.id}
                      >
                        <span className="d-sm-block">Metrics</span>
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link
                        style={{ cursor: "pointer" }}
                        className={"nav-link "}
                        to={"/campaign/" + this.state.id + "/send"}
                      >
                        <span className="d-sm-block">Send</span>
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link
                        style={{ cursor: "pointer" }}
                        className={"nav-link "}
                        to={"/campaign/" + this.state.id + "/upload_history"}
                      >
                        <span className="d-sm-block">Upload History</span>
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link
                        style={{ cursor: "pointer" }}
                        className={"nav-link "}
                        to={
                          "/campaign/" +
                          this.state.id +
                          "/prospects_messages_notes"
                        }
                      >
                        <span className="d-sm-block">Prospects & Messages</span>
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link
                        style={{ cursor: "pointer" }}
                        className={"nav-link active"}
                        to={"/campaign/" + this.state.id + "/scheduler"}
                      >
                        <span className="d-sm-block">Scheduler</span>
                      </Link>
                    </NavItem>
                  </Nav>
                </Col>
              </Row>
            </div>

            {this.state.activeTab === "add" ? (
              <AvForm
                //   onValidSubmit={(e, v) => this.handleValidSubmit(e, v)}
                className="needs-validation"
              >
                <ul className="d-aut-wrapper">
                  <li className="d-aut-el mb-2">
                    <div className="c-card">
                      <Row className="align-items-center justify-content-between pb-2">
                        <Col lg={6} md={6} sm={12}>
                          <div className="page-title-box pb-0 pt-1 d-flex gap-2 justify-content-flex-start align-items-flex-end ">
                            <button
                              onClick={() => {
                                this.setState({
                                  activeTab: "list",
                                  batches: [],
                                  activeBatchIndex: null,
                                  isValidDate: null,
                                  activeBatch: {},
                                  addSchedularData: {},
                                  availableProspectCount: 0,
                                })
                              }}
                              className="d-flex justify-content-center align-items-center"
                              style={{
                                display: "inline",
                                width: "24px",
                                height: "24px",
                                border: "1px solid #5932ea",
                                background: "transparent",
                                borderRadius: "50%",
                              }}
                              title="Back to list"
                            >
                              {/* <i className="fas fa-download text-indigo"></i> */}
                              <i
                                className="fas fa-arrow-left"
                                style={{ color: "#5932ea" }}
                              ></i>
                            </button>

                            <h4
                              className="font-size-18"
                              style={{ display: "inline" }}
                            >
                              Add Schedule
                            </h4>
                          </div>
                        </Col>

                        <Col md={12} className={"pt-2"}>
                          <span
                            className={
                              "border border-2 border-start-0 border-top-0 border-end-0 border-bottom-2 d-block"
                            }
                          ></span>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4} lg={4} sm={6}>
                          <FormGroup className="mb-3">
                            <AvField
                              type="text"
                              name="scheduler_name"
                              label="Scheduler Name"
                              onChange={e => {
                                //   this.setState(prevState => ({
                                //     activeBatch: { ...prevState.activeBatch, schedularName: e.target.value }
                                // }));

                                this.setState(prevState => ({
                                  addSchedularData: {
                                    ...prevState.addSchedularData,
                                    schedularName: e.target.value,
                                  },
                                }))
                              }}
                              validate={{
                                required: { value: true },
                                pattern: {
                                  value: /^[A-Za-z0-9()$@#%^&*! -_\\]+$/,
                                  errorMessage: "Enter valid name",
                                },
                              }}
                            ></AvField>
                          </FormGroup>
                        </Col>
                        <Col md={4} lg={4} sm={6}>
                          <FormGroup className="mb-3">
                            <AvField
                              type="date"
                              name="date"
                              label="Select Date"
                              validate={{
                                required: { value: true },
                                maxLength: { value: 320 },
                              }}
                              onChange={e => {
                                this.handleCheckValidDate(e.target.value)
                                this.setState(prevState => ({
                                  addSchedularData: {
                                    ...prevState.addSchedularData,
                                    date: e.target.value,
                                  },
                                }))
                                //   this.setState(prevState => ({
                                //     activeBatch: { ...prevState.activeBatch, date: e.target.value }
                                // }));
                              }}
                              min={new Date().toISOString().split("T")[0]}
                            ></AvField>
                            {this.state.isValidDate !== null &&
                            !this.state.isValidDate ? (
                              <span className="text-danger ">
                                <img
                                  src={Exclamation}
                                  style={{
                                    width: "18px",
                                    height: "18px",
                                  }}
                                  alt=""
                                />
                                <strong>
                                  Can not create scheduler for this date!
                                </strong>
                              </span>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md={4} lg={4} sm={6}>
                          <FormGroup className="mb-3">
                            <Label>Select Start Time</Label>
                            <input
                              key={this.state.addSchedularData?.reloadKey}
                              type="time"
                              name="time"
                              label="Select Start Time"
                              value={this.state?.addSchedularData?.startTime}
                              onChange={e => {
                                // const selectedTime =
                                //   this.roundTimeToNearestTenMinutes(
                                //     e.target.value
                                //   )
                                const selectedTime = e.target.value

                                if (this.state.batches.length) {
                                  this.setState(prevState => {
                                    const updatedBatch = [...prevState.batches]
                                    updatedBatch[0] = {
                                      ...updatedBatch[0],
                                      startTime: selectedTime,
                                    }
                                    return { batches: updatedBatch }
                                  })
                                  this.handleBatchTimeChange(0, selectedTime)
                                }

                                this.setState(prevState => ({
                                  addSchedularData: {
                                    ...prevState.addSchedularData,
                                    startTime: selectedTime,
                                  },
                                }))
                              }}
                              // validate={{
                              //   required: {
                              //     value: true,
                              //     message: "Time is required",
                              //   },
                              //   pattern: {
                              //     value: /^(?:2[0-3]|[01][0-9]):[0-5][0-9]$/,
                              //     message: "Invalid time format (HH:MM)",
                              //   },
                              // }}
                            />
                            {this.state?.addSchedularData?.startTime ? (
                              <span>
                                {!this.isTimeInRange(
                                  this.state?.addSchedularData?.startTime
                                ) ? (
                                  <span className="text-danger ">
                                    <img
                                      src={Exclamation}
                                      style={{
                                        width: "18px",
                                        height: "18px",
                                      }}
                                      alt=""
                                    />
                                    <strong>It'll take more than a day</strong>
                                  </span>
                                ) : !this.isFutureDateTime(
                                    this.state.addSchedularData?.date,
                                    this.state.addSchedularData?.startTime
                                  ) ? (
                                  <span className="text-danger ">
                                    <img
                                      src={Exclamation}
                                      style={{
                                        width: "18px",
                                        height: "18px",
                                      }}
                                      alt=""
                                    />
                                    <strong>
                                      Scheduler can not be created for past
                                      time!
                                    </strong>
                                  </span>
                                ) : null}
                              </span>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md={12} lg={12} sm={12}>
                          <div className="row" style={{ "row-gap": "20px" }}>
                            <div className="col-lg-3 col-md-6 col-12">
                              <div className="c-card ic-card">
                                <div className="det">
                                  <h3>{this.props.campaign?.delay}</h3>
                                  <p>Delay Interval</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-12">
                              <div className="c-card ic-card">
                                <div className="det">
                                  <h3>{`${this.totalIncludedProspect(
                                    this.state.batches.length - 1
                                  )}/${
                                    this.state?.availableProspectCount
                                  }`}</h3>
                                  <p>Total Available Prospect</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-12">
                              <div className="c-card ic-card">
                                <div className="det">
                                  <h3>
                                    {this.state?.availableProspectCount -
                                      this.totalIncludedProspect(
                                        this.state.batches.length - 1
                                      )}
                                  </h3>
                                  <p>Prospect Left</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-12">
                              <div className="c-card ic-card">
                                <div className="det">
                                  <h3>
                                    {this.state.batches[
                                      this.state.batches.length - 1
                                    ]?.endTime
                                      ? this.state.batches[
                                          this.state.batches.length - 1
                                        ]?.endTime
                                      : "-"}
                                  </h3>
                                  <p>End Time</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </li>
                  <span key={this.state.reloadKey}>
                    {this.state.batches &&
                      this.state.batches.map((item, index) => {
                        return (
                          <span key={index} style={{ zIndex: 100 - index }}>
                            <li key={index}>
                              {this.state.activeBatchIndex === index ? (
                                <div>
                                  <span className="d-num-in">
                                    #Batch {index + 1}
                                  </span>
                                  <div
                                    className={`d-aut-main mb-4 mt-0 ${
                                      !this.isBatchValid(index)
                                        ? "border-danger"
                                        : "border-info"
                                    }  border-start ps-2 border-2`}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <div className="d-aut-body mt-0 ">
                                      <Row>
                                        <Col md={11}>
                                          <Row>
                                            <Col md={6}>
                                              <div className="form-group style-2">
                                                <div className="inp-grp">
                                                  <select
                                                    onChange={e => {
                                                      this.handleDataChange(
                                                        index,
                                                        "templateCat",
                                                        e.target.value
                                                      )
                                                    }}
                                                    className="form-control cc-form-control"
                                                    value={item?.templateCat}
                                                    // style={{ background: "white" }}
                                                  >
                                                    <option value="">
                                                      Select Category
                                                    </option>
                                                    {this.state.categoryList
                                                      .length > 0 &&
                                                      this.getCategoryOptions(
                                                        index
                                                      )}
                                                  </select>
                                                </div>
                                              </div>
                                            </Col>
                                            <Col md={6}>
                                              <div className="form-group style-2">
                                                <div className="inp-grp">
                                                  <input
                                                    className="form-control cc-form-control inp-bg-gray"
                                                    type="number"
                                                    placeholder="Enter prospect count"
                                                    min={1}
                                                    value={
                                                      item?.totalProspectCount
                                                        ? item?.totalProspectCount
                                                        : null
                                                    }
                                                    onChange={e => {
                                                      this.handleDataChange(
                                                        index,
                                                        "totalProspectCount",
                                                        +e.target.value
                                                      )
                                                    }}
                                                  />
                                                </div>
                                                {!this.isProsCountValid(
                                                  index
                                                ) && (
                                                  <span className="text-danger ">
                                                    <img
                                                      src={Exclamation}
                                                      style={{
                                                        width: "18px",
                                                        height: "18px",
                                                      }}
                                                      alt=""
                                                    />
                                                    <strong>
                                                      The prospect count exceeds
                                                      the available prospects!
                                                    </strong>
                                                  </span>
                                                )}
                                              </div>
                                            </Col>
                                            <Col md={6}>
                                              <div className="form-group style-2">
                                                <div className="d-flex justify-content-flex-start align-items-flex-start gap-4">
                                                  <div>
                                                    <span>
                                                      Start time:{" "}
                                                      <strong>
                                                        {item?.startTime}
                                                      </strong>
                                                    </span>
                                                  </div>
                                                  <div className="d-flex flex-column gap-1">
                                                    <span>
                                                      End time:{" "}
                                                      <strong>
                                                        {item?.endTime}
                                                      </strong>
                                                    </span>
                                                    {item?.endTime ? (
                                                      <span>
                                                        {!this.isTimeInRange(
                                                          item?.endTime
                                                        ) ? (
                                                          <span className="text-danger ">
                                                            <img
                                                              src={Exclamation}
                                                              style={{
                                                                width: "18px",
                                                                height: "18px",
                                                              }}
                                                              alt=""
                                                            />
                                                            <strong>
                                                              It'll take more
                                                              than a day
                                                            </strong>
                                                          </span>
                                                        ) : null}
                                                        {/* if (!this.isTimeInRange(e.target.value)) {
                                  alert("Timeeeeeeeeeee")
                                } */}
                                                      </span>
                                                    ) : null}
                                                  </div>
                                                </div>
                                              </div>
                                            </Col>
                                          </Row>
                                        </Col>
                                        <Col
                                          md={1}
                                          className="d-flex justify-content-center align-items-center"
                                        >
                                          <button
                                            className="actions-btn"
                                            onClick={() => {
                                              if (
                                                this.isBatchValid(
                                                  this.state.activeBatchIndex
                                                )
                                              ) {
                                                this.setState({
                                                  activeBatchIndex: null,
                                                })
                                              }
                                            }}
                                          >
                                            <img src={applyIcon} alt="" />
                                          </button>
                                          <button
                                            className="actions-btn"
                                            onClick={e => {
                                              e.preventDefault()
                                              this.removeBatchHandler(index)
                                            }}
                                          >
                                            <img src={trashIcon} alt="" />
                                          </button>
                                        </Col>
                                      </Row>
                                    </div>
                                    {/* <div className="d-aut-head flex-column align-items-start gap-2">
                                    </div> */}
                                  </div>
                                </div>
                              ) : (
                                <div>
                                  <span className="d-num-in">
                                    #Batch {index + 1}
                                  </span>
                                  <div
                                    className={`d-aut-main mb-4 mt-0 ${
                                      !this.isBatchValid(index)
                                        ? "border-danger"
                                        : "border-info"
                                    }  border-start ps-2 border-2`}
                                    // onClick={() => setActiveIndex(index)}
                                  >
                                    <div className="d-aut-body mt-0 ">
                                      <Row>
                                        <Col md={11}>
                                          <Row
                                            onClick={() =>
                                              this.setState({
                                                activeBatchIndex: index,
                                              })
                                            }
                                            style={{ cursor: "pointer" }}
                                          >
                                            <Col md={3}>
                                              <div className="d-flex flex-column">
                                                <span className="batch-out-title">
                                                  Category
                                                </span>
                                                <span className="batch-out-data">
                                                  {item?.templateCat
                                                    ? this.getCategoryName(
                                                        item?.templateCat
                                                      )
                                                    : null}
                                                </span>
                                              </div>
                                            </Col>
                                            <Col md={3}>
                                              <div className="d-flex flex-column">
                                                <span className="batch-out-title">
                                                  Prospect count
                                                </span>
                                                <span className="batch-out-data">
                                                  {item?.totalProspectCount
                                                    ? item?.totalProspectCount
                                                    : null}
                                                </span>
                                              </div>
                                            </Col>
                                            <Col md={3}>
                                              <div className="d-flex flex-column">
                                                <span className="batch-out-title">
                                                  Start Time
                                                </span>
                                                <span className="batch-out-data">
                                                  {item?.startTime}
                                                </span>
                                              </div>
                                            </Col>
                                            <Col md={3}>
                                              <div className="d-flex flex-column">
                                                <span className="batch-out-title">
                                                  End Time
                                                </span>
                                                <span className="batch-out-data">
                                                  {item?.endTime
                                                    ? item?.endTime
                                                    : null}
                                                </span>
                                              </div>
                                            </Col>
                                          </Row>
                                        </Col>
                                        <Col
                                          md={1}
                                          className="d-flex justify-content-center align-items-center"
                                        >
                                          <button
                                            className="actions-btn"
                                            onClick={e => {
                                              e.preventDefault()
                                              this.removeBatchHandler(index)
                                            }}
                                          >
                                            <img src={trashIcon} alt="" />
                                          </button>
                                        </Col>
                                      </Row>
                                    </div>
                                    {/* <div className="d-aut-head flex-column align-items-start gap-2">
                                </div> */}
                                  </div>
                                </div>
                              )}
                            </li>
                          </span>
                        )
                      })}
                  </span>
                  <li className="d-aut-el mb-2" style={{ zIndex: 0 }}>
                    <div className="d-flex gap-3">
                      <button
                        className={`main-btn ${
                          !this.canCreateNewBatch() ? "disabled" : null
                        }`}
                        disabled={!this.canCreateNewBatch()}
                        onClick={() => {
                          this.addBatchHandler()
                        }}
                      >
                        <img src={whitePlus} alt="" />
                        <span>Add Batch</span>
                      </button>
                    </div>
                  </li>
                  <li className="d-aut-el" style={{ zIndex: 0 }}>
                    <div className="d-flex gap-3">
                      <button
                        className={`main-btn ${
                          !this.canCreateNewScheduler() ? "disabled" : null
                        }`}
                        disabled={!this.canCreateNewScheduler()}
                        onClick={() => {
                          this.handleAddSchedular()
                        }}
                      >
                        {/* <img src={whitePlus} alt="" /> */}
                        <span>Save</span>
                      </button>
                    </div>
                  </li>
                </ul>
              </AvForm>
            ) : null}

            {this.state.activeTab === "view" ? (
              <>
                <div className="c-card">
                  <Row className="align-items-center justify-content-between pb-2">
                    <Col lg={6} md={6} sm={12}>
                      <div className="page-title-box pb-0 pt-1 d-flex gap-2 justify-content-flex-start align-items-flex-start ">
                        <button
                          onClick={() => {
                            this.closeSchedulerHandler()
                          }}
                          className="d-flex justify-content-center align-items-center"
                          style={{
                            display: "inline",
                            width: "24px",
                            height: "24px",
                            border: "1px solid #5932ea",
                            background: "transparent",
                            borderRadius: "50%",
                          }}
                          title="Back to list"
                          // type="button"
                        >
                          {/* <i className="fas fa-download text-indigo"></i> */}
                          <i
                            className="fas fa-arrow-left"
                            style={{ color: "#5932ea" }}
                          ></i>
                        </button>

                        <h4
                          className="font-size-18"
                          style={{ display: "inline" }}
                        >
                          {this.state.schedularDetail?.scheduler?.name
                            ? this.state.schedularDetail?.scheduler?.name
                            : ""}{" "}
                          {this.state?.schedularDetail?.scheduler?.status ? (
                            <span style={{ textTransform: "capitalize" }}>
                              ({this.state?.schedularDetail?.scheduler?.status})
                            </span>
                          ) : null}
                          {/* {` (${this.state?.schedularDetail?.scheduler?.status?.toUpperCase()})`} */}
                        </h4>
                      </div>
                    </Col>

                    <Col md={12} className={"pt-2"}>
                      <span
                        className={
                          "border border-2 border-start-0 border-top-0 border-end-0 border-bottom-2 d-block"
                        }
                      ></span>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col md={6}>
                      <h2 className="send-text">
                        Send Count :{" "}
                        <span style={{ color: "#5932ea" }}>
                          {this.state.schedularDetail?.scheduler?.prospectSend}
                        </span>
                        /
                        {
                          this.state.schedularDetail?.scheduler
                            ?.totalProspectCount
                        }{" "}
                        (
                        {this.state.schedularDetail?.scheduler
                          ?.totalProspectCount -
                          this.state.schedularDetail?.scheduler
                            ?.prospectSend}{" "}
                        Left)
                      </h2>
                      <div>
                        {isNaN(
                          (this.state.schedularDetail?.scheduler?.prospectSend *
                            100) /
                            this.state.schedularDetail?.scheduler
                              ?.totalProspectCount
                        )
                          ? 0
                          : (
                              (this.state.schedularDetail?.scheduler
                                ?.prospectSend *
                                100) /
                              this.state.schedularDetail?.scheduler
                                ?.totalProspectCount
                            ).toFixed(2)}{" "}
                        %
                      </div>
                    </Col>
                    <Col sm={12}>
                      <Progress
                        style={{
                          height: "16px",
                          borderRadius: "20px",
                        }}
                        color="success"
                        value={(
                          (this.state.schedularDetail?.scheduler?.prospectSend *
                            100) /
                          this.state.schedularDetail?.scheduler
                            ?.totalProspectCount
                        ).toFixed(2)}
                      ></Progress>
                    </Col>
                  </Row>
                  <div className="row" style={{ rowGap: "25px" }}>
                    <div className="col-lg-3 col-12 col-md-3">
                      <div className="c-card ic-card style-2">
                        <img src={dateIcon} alt="" />
                        <div className="det">
                          <h3 className="purple">
                            {this.state.schedularDetail?.scheduler?.startDate
                              ? moment
                                  .utc(
                                    this.state.schedularDetail?.scheduler
                                      ?.startDate
                                  )
                                  .local()
                                  .format("MM-DD-YYYY")
                              : "-"}
                          </h3>
                          <p>Start Date</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12 col-md-3">
                      <div className="c-card ic-card style-2">
                        <img src={startTimeIcon} alt="" />
                        <div className="det">
                          <h3 className="purple">
                            {this.state.schedularDetail?.scheduler?.startTime
                              ? this.state.schedularDetail?.scheduler?.startTime
                              : "-"}
                          </h3>
                          <p>Start Time</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12 col-md-3">
                      <div className="c-card ic-card style-2">
                        <img src={endTimeIcon} alt="" />
                        <div className="det">
                          <h3 className="purple">
                            {this.state.schedularDetail?.scheduler?.endTime
                              ? this.state.schedularDetail?.scheduler?.endTime
                              : "-"}
                          </h3>
                          <p>End Time</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12 col-md-3">
                      <div className="c-card ic-card style-2">
                        <img src={intervalIcon} alt="" />
                        <div className="det">
                          <h3 className="purple">
                            {this.state.schedularDetail?.scheduler?.interval
                              ? this.state.schedularDetail?.scheduler?.interval
                              : "-"}
                          </h3>
                          <p>Interval</p>
                        </div>
                      </div>
                    </div>
                    {["running", "paused", "resumed"].includes(
                      this.state?.schedularDetail?.scheduler?.status
                    ) && (
                      <div className="col-lg-12 col-12 col-md-12">
                        <div className="d-flex gap-2 flex-wrap">
                          {["running", "resumed"].includes(
                            this.state?.schedularDetail?.scheduler?.status
                          ) && (
                            <button
                              className={"me-2 main-btn circle-btn"}
                              onClick={() => {
                                this.handleToggleSchedular(
                                  this.state?.schedularDetail?.scheduler?._id,
                                  "pause"
                                )
                              }}
                            >
                              <i className={"fas fa-pause text-white"}></i>

                              <span>Pause</span>
                            </button>
                          )}
                          {this.state?.schedularDetail?.scheduler?.status ==
                            "paused" && (
                            <button
                              className={"me-2 main-btn circle-btn"}
                              onClick={() => {
                                this.handleToggleSchedular(
                                  this.state?.schedularDetail?.scheduler?._id,
                                  "resume"
                                )
                              }}
                            >
                              <i className={"fas fa-play text-white"}></i>

                              <span>Resume</span>
                            </button>
                          )}
                          <button
                            className={"main-btn danger-border circle-btn"}
                            onClick={() => {
                              this.handleToggleSchedular(
                                this.state.schedularDetail?.scheduler?._id,
                                "stop"
                              )
                            }}
                          >
                            <img src={skipIcon} alt="" />
                            <span>Stop </span>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/* dont touch above it  */}
                <div className="c-card mt-3">
                  <Row className="align-items-center">
                    <DataTable
                      columns={batchesColumn}
                      data={batchesListing}
                      fixedHeader
                      pagination
                      className="c-table"
                    />
                  </Row>
                </div>
              </>
            ) : null}

            {this.state.activeTab === "list" ? (
              <div className="c-card">
                <Row className="align-items-center">
                  <Col xs={6}>
                    <div className="page-title-box">
                      <h4>Scheduler History</h4>
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="float-end">
                      <button
                        className="main-btn"
                        type="button"
                        onClick={() => {
                          this.setState({
                            activeTab: "add",
                          })
                        }}
                        role="button"
                      >
                        <img src={plus} alt="" />
                        <span>Add Scheduler</span>
                      </button>
                    </div>
                  </Col>
                  <Col md={12}>
                    <span
                      className={
                        "border border-2 border-start-0 border-top-0 border-end-0 border-bottom-2 d-block"
                      }
                    ></span>
                  </Col>
                </Row>

                <Row className="align-items-center">
                  <DataTable
                    columns={schedularColumns}
                    data={schedularListingData ? schedularListingData : []}
                    fixedHeader
                    pagination
                    className="c-table"
                  />
                </Row>
              </div>
            ) : null}
          </div>
        </div>
      </React.Fragment>
    )
  }
}
Metrics.propTypes = {
  apiError: PropTypes.any,
  successMsg: PropTypes.any,
  campaign: PropTypes.object,
  campaigns: PropTypes.array,
  getCampaign: PropTypes.func,
  getCountStats: PropTypes.func,
  downloadStats: PropTypes.func,
  getCampaignMetrics: PropTypes.func,
  campaign_metrics: PropTypes.array,
  countstatus: PropTypes.object,
  leads: PropTypes.array,
  getLeadStages: PropTypes.func,
  campaignFilterProspects: PropTypes.func,
}

const mapStatetoProps = state => {
  const {
    apiError,
    campaign,
    campaigns,
    leads,
    getLeadStages,
    getCampaign,
    successMsg,
    campaign_metrics,
    countstatus,
    getCountStats,
    downloadStats,
    getCampaignMetrics,
    campaignFilterProspects,
  } = state.Campaign
  return {
    apiError,
    campaign,
    campaigns,
    leads,
    getLeadStages,
    getCampaign,
    successMsg,
    campaign_metrics,
    countstatus,
    getCountStats,
    downloadStats,
    getCampaignMetrics,
    campaignFilterProspects,
  }
}

export default withRouter(
  connect(mapStatetoProps, {
    getCountStats,
    downloadStats,
    getCampaignMetrics,
    getCampaign,
    getLeadStages,
    campaignFilterProspects,
  })(Metrics)
)

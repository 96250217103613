import PropTypes, { func } from "prop-types"
import React, { useState, useEffect, useRef } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  Modal,
  DropdownItem,
  DropdownMenu,
  Alert,
  Spinner,
  BreadcrumbItem,
} from "reactstrap"
import { Link, Redirect } from "react-router-dom"
import axios from "axios"
import ReactQuill from "react-quill"

import applyIcon from "../../assets/images/apply-icon.svg"
import trashIcon from "../../assets/images/trash-icon.svg"
import purplePlus from "../../assets/images/purple-plus.svg"
import whitePlus from "../../assets/images/white-plus.svg"

import greyCheck from "../../assets/images/grey-check.svg"
import greenCheck from "../../assets/images/green-check.svg"
import toast, { Toaster } from "react-hot-toast"

import warningError from "../../assets/images/warning-error.svg"

import warningIcon from "../../assets/images/warning.svg"
import successIcon from "../../assets/images/success.svg"
import errorIcon from "../../assets/images/error.svg"
import Select from "react-select"
import "chartist/dist/scss/chartist.scss"

//i18n
import { withTranslation } from "react-i18next"

const EditTemplate = props => {
  const propsTemplateId = window.location.pathname.split("/").pop()
  const authUser = localStorage.getItem("authUser")
  let token = false
  if (authUser) {
    let user = JSON.parse(authUser)
    token = user?.user?.token
  } else {
    token = false
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }
  const defaultObjSchema = {
    day: 1,
    message: "",
    showTextSpinner: false,
    messageCursorPosition: 0,
    isOptOutOpen: false,
  }

  const tags = [
    "[@firstName]",
    "[@lastName]",
    "[@street]",
    "[@city]",
    "[@state]",
    "[@zip]",
    "[@custom]",
    "[@companyName]",
    "[@repNameAlias]",
  ]
  const [isLoading, setIsLoading] = useState(false)

  const [menu, setMenu] = useState(false)
  const [reloadKey, setReloadKey] = useState(Math.random())
  const [dataArr, setDataArr] = useState([])
  const [negativeKeywords, setNegativeKeywords] = useState([])
  const [optOuts, setOptOuts] = useState([])
  const [templateName, setTemplateName] = useState("")
  const [activeTemplateId, setActiveTemplateId] = useState(null)
  const [activeTemplateData, setActiveTemplateData] = useState(null)
  const [categories, setCategories] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [validation, setValidation] = useState(null)
  const [templateId, setTemplateId] = useState(propsTemplateId)
  const messageTextAreaRef = useRef(null)
  const sectionRef = useRef(null)
  const [shouldRedirect, setShouldRedirect] = useState({
    state: false,
    path: "",
  })
  const quillRef = useRef(null)

  const submitTemplate = async () => {
    const crrTemplate = dataArr.filter(item => item._id == activeTemplateId)
    setIsLoading(true)
    const payload = {
      name: templateName,
      category: selectedCategory._id,
      message: getCleanMessage(crrTemplate[0]?.message),
    }
    try {
      const response = await axios.post(
        process.env.REACT_APP_PORT +
          "/api/template/updateTemplate/" +
          activeTemplateId,
        payload,
        config
      )
      if (response.data) {
        setIsLoading(false)
        getTemplate(activeTemplateId)
        notify("success", "Template updated successfully!")
        // setTimeout(() => {
        //   setShouldRedirect({
        //     state: true,
        //     path: `/templates`,
        //   })
        // }, 1500)
      }
    } catch (error) {
      setIsLoading(false)
      notify("error", error?.response?.data?.message)
    }
  }

  const toggle = () => {
    setMenu(!menu)
  }
  const notify = (type, text) => {
    switch (type) {
      case "success":
        toast.success(text)
        break
      case "error":
        if (typeof text !== "string") {
          if (text?.message && typeof text?.message === "string") {
            toast.error(text?.message)
          } else {
            toast.error("An unexpected error occurred")
          }
        } else {
          toast.error(text)
        }
        break

      default:
        break
    }
  }
  const getNegativeKeywords = async () => {
    setIsLoading(true)
    const response = await axios.get(
      process.env.REACT_APP_PORT + "/api/template/listNegativeKeywords/",
      config
    )
    if (response.data) {
      setNegativeKeywords(response.data.data)
      setIsLoading(false)
    }
  }

  const getOptOuts = async () => {
    setIsLoading(true)
    const response = await axios.get(
      process.env.REACT_APP_PORT + "/api/template/pullOptOut",
      config
    )
    if (response.data) {
      setOptOuts(response.data.data)
      setIsLoading(false)
    }
  }

  const getCategories = async () => {
    setIsLoading(true)
    const response = await axios.get(
      process.env.REACT_APP_PORT + "/api/template/pullCategories",
      config
    )
    if (response) {
      setCategories(response.data.data)
      setIsLoading(false)
    }
  }

  const getTemplate = async (_templateId = templateId) => {
    setIsLoading(true)
    const response = await axios.get(
      process.env.REACT_APP_PORT + "/api/template/pullTemplate/" + _templateId,
      config
    )
    if (response.data) {
      setTemplateName(response.data.data.name)
      setActiveTemplateId(response.data.data._id)
      setActiveTemplateData({ ...response.data.data })
      getTemplatesByCat(response.data.data.category)
      setReloadKey(Math.random())
      // setDataArr(response.data.data.data)
    }
  }

  const getTemplatesByCat = async categoryId => {
    setIsLoading(true)
    const query = new URLSearchParams({ categoryId })
    const response = await axios.get(
      process.env.REACT_APP_PORT + "/api/template/pullTemplates?" + query,
      config
    )
    if (response.data) {
      setIsLoading(false)
      setDataArr(response.data.data)
      const offset = -100
      const section = sectionRef.current
      if (section) {
        const offsetTop =
          section.getBoundingClientRect().top + window.scrollY + offset

        window.scrollTo({
          top: offsetTop,
          behavior: "smooth",
        })
      }

      // setTemplateName(response.data.data.name)
      // setDataArr(response.data.data.data)
    }
  }

  const addTextSpinnerHandler = index => {
    const tempArr = [...dataArr]
    tempArr[index].showTextSpinner = true
    setDataArr([...tempArr])
    setReloadKey(Math.random())
  }

  function addSpinnerTextHandler(arr, index) {
    let tempArr = [...dataArr]
    let str = `[${arr.join("/")}]`
    // let obj = tempArr[index]

    // str =
    //   obj.message.slice(0, obj.messageCursorPosition) +
    //   str +
    //   obj.message.slice(obj.messageCursorPosition)

    // tempArr[index].message = str
    tempArr[index].showTextSpinner = false
    // tempArr[index].messageCursorPosition = str.length
    setDataArr([...tempArr])
    setReloadKey(Math.random())
    if (quillRef.current) {
      const editor = quillRef.current.getEditor()
      const range = editor.getSelection()
      const position = range
        ? range.index
        : editor.getText().replaceAll("\n", "").length
      const positionEnd = range ? range.length : null
      console.log(
        position,
        "first---------------------------",
        editor.getText()
      )

      if (!isNaN(position) && !isNaN(positionEnd)) {
        editor.deleteText(position, positionEnd)
      }
      editor.insertText(position, str, "user")
      editor.setSelection(position + str.length)
    }
  }
  function getCleanMessage(_mssg = "") {
    const tempElement = document.createElement("div")
    tempElement.innerHTML = _mssg
    return tempElement.innerText
  }
  function closeSpinnerTextHandler(index) {
    let tempArr = [...dataArr]
    tempArr[index].showTextSpinner = false
    setDataArr([...tempArr])
    setReloadKey(Math.random())
  }

  function handleDataChange(index, key, val) {
    let tempArr = [...dataArr]
    tempArr[index][key] = val
    setDataArr([...tempArr])
    if (!quillRef.current) {
      setReloadKey(Math.random())
    }
    // setReloadKey()
  }

  function updateMessageCursorPosition(index, position) {
    let tempArr = [...dataArr]
    tempArr[index].messageCursorPosition = position
    setDataArr([...tempArr])
    // setReloadKey(Math.random())
  }

  function addTag(tag, index) {
    const editor = quillRef.current.getEditor()
    const range = editor.getSelection()
    const position = range
      ? range.index
      : getMessageCharCount(dataArr[index].message)
    const positionEnd = range ? range.length : null
    if (!isNaN(position) && !isNaN(positionEnd)) {
      editor.deleteText(position, positionEnd)
    }
    editor.insertText(position, tag, "user")
    editor.setSelection(position + tag.length)
  }

  function getMessageCharCount(_mssg = "") {
    const tempElement = document.createElement("div")
    tempElement.innerHTML = _mssg
    let mssg = tempElement.innerText
    const textSpinners = []
    const mssgWithoutTextSpinner = mssg.replace(
      /\[[^\]]*\/[^\]]*(?:\/[^\]]*)*\]/g,
      match => {
        textSpinners.push(match)
        return ""
      }
    )
    const textSpinnerCharCounts = sumArray(
      textSpinners.map(item => {
        let arr = item.replace("[", "").replace("]", "").split("/")
        let maxLength = 0
        for (let i = 0; i < arr.length; i++) {
          const str = arr[i]
          const length = str.length
          if (length > maxLength) {
            maxLength = length
          }
        }
        return maxLength
      })
    )
    return mssgWithoutTextSpinner?.length + textSpinnerCharCounts
  }

  function sumArray(arr) {
    let sum = 0
    for (let i = 0; i < arr.length; i++) {
      const num = arr[i]
      if (typeof num === "number") {
        sum += num
      }
    }
    return sum
  }

  function getTextSpinnerCount(str = "") {
    const pattern = /\[[^\]]*\/[^\]]*(?:\/[^\]]*)*\]/g
    const matches = str.match(pattern)
    if (matches) {
      return matches?.length
    } else {
      return 0
    }
  }

  function isTextSpinnerHasMinElement(mssg = "", min = 2) {
    if (!mssg.length) {
      return false
    }
    const pattern = /\[[^\]]*\/[^\]]*(?:\/[^\]]*)*\]/g
    const matches = mssg.match(pattern)
    let flag = true
    if (matches) {
      matches.forEach(item => {
        if (item.split("/").length < min) flag = false
      })
    }
    return flag
  }
  function findAllIndices(text, nw) {
    const indices = []
    const regex = new RegExp(`\\b${nw}\\b`, "gi") // 'gi' makes it case-insensitive
    let match

    // Use regex to find all exact matches of the word
    while ((match = regex.exec(text)) !== null) {
      indices.push(match.index)
    }

    return indices
  }
  function checkNegativeKeywords(_val = "") {
    const found = []
    const val = getCleanMessage(_val)
    const valArr = val?.split(" ")?.map(item => item.toLowerCase())
    for (const word of negativeKeywords) {
      if (valArr.includes(word?.toLowerCase())) {
        found.push(word)
      }
    }
    if (found && found.length) {
      // this.setState({
      //   negativeKeywords: found,
      //   messageError: `Negative Kewords Found: ${found.join(", ")}`,
      // })
      return false
    } else {
      // this.setState({
      //   negativeKeywords: found,
      //   messageError: "",
      // })
      return true
    }
  }

  function getMergeFieldCount(str = "") {
    const pattern = /\[\@[A-Za-z0-9]+\]/g
    const matches = str.match(pattern)
    if (matches) {
      return matches?.length
    } else {
      return 0
    }
  }

  function isMergeFieldValid(mssg = "") {
    const pattern = /\[\@[A-Za-z0-9]+\]/g
    const matches = mssg.match(pattern)
    if (matches) {
      return matches.every(item => tags.includes(item))
    } else {
      return true
    }
  }

  function isMessageComplete(mssg = "") {
    if (
      validation?.messageCharCount &&
      getMessageCharCount(mssg) < validation?.messageCharCount
    )
      return false
    if (
      validation?.minTextSpinner &&
      getTextSpinnerCount(mssg) < validation?.minTextSpinner
    )
      return false
    if (validation?.isNoNegativeKeywordRequired && !checkNegativeKeywords(mssg))
      return false
    if (validation?.isMergeFieldRequired && !getMergeFieldCount(mssg))
      return false
    if (validation?.isMergeFieldRequired && !getMergeFieldCount(mssg))
      return false
    if (validation?.validMergeFieldRequired && !isMergeFieldValid(mssg))
      return false
    return true
  }

  function checkValidSubmit() {
    let isValid = true
    if (!(templateName && templateName.trim().length) || !selectedCategory) {
      return false
    }
    dataArr.forEach(item => {
      if (!isMessageComplete(item.message)) {
        isValid = false
      }
    })
    return isValid
  }

  function minimizeAll() {
    setActiveTemplateId(null)
  }
  function isPresent(word, string) {
    const val = getCleanMessage(string)
    const lowerStr = val.toLowerCase()
    const lowerWord = word.toLowerCase()
    const pattern = new RegExp(`\\b${lowerWord}\\b`)
    return pattern.test(lowerStr)
  }

  function findVariations(_str = "") {
    const str = getCleanMessage(_str)
    const regex = /\[([^\]]+)\]/g
    const matches = Array.from(str.matchAll(regex))
    let totalVariations = 1
    matches.forEach(match => {
      const options = match[1].split("/")
      totalVariations *= options?.length
    })
    return totalVariations
  }

  function getDataFromActiveId(activeTempId) {
    const crrTemplate = dataArr.filter(item => item._id == activeTempId)
    return crrTemplate[0]
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (categories && categories.length && activeTemplateData) {
      const selectedCatData = categories.filter(
        item => item._id == activeTemplateData.category
      )
      if (selectedCatData && selectedCatData.length) {
        setSelectedCategory({
          ...selectedCatData[0],
          value: selectedCatData[0]._id,
          label: selectedCatData[0].name,
        })
        setValidation(selectedCatData[0]?.validations)
      }
    }
  }, [categories, activeTemplateData])
  useEffect(() => {
    console.log("INnnnnnnnnnnnnnnnnnnnnnnnn", quillRef.current, "000")
    if (quillRef.current) {
      const editor = quillRef.current.getEditor()
      editor.on("text-change", function (delta, oldDelta, source) {
        console.log(source, "______________________________")

        if (source === "user") {
          const text = editor.getText()
          console.log(text, "TTTTTTTTTTTTTTTTTTTTTTT")
          editor.formatText(0, text.length, {
            color: "black",
            background: false,
          })

          // For merge field
          let regex = /\{\{[^{}]+\}\}|\[@[^\[\]]+\]/g
          let match
          // Apply formatting to matched patterns
          while ((match = regex.exec(text)) !== null) {
            editor.formatText(match.index, match[0].length, {
              color: "#5932EA",
              // background: "#E6F3FF",
            })
          }
          // For text spinner
          regex = /\[[^\]]*\/[^\]]*(?:\/[^\]]*)*\]/g
          while ((match = regex.exec(text)) !== null) {
            editor.formatText(match.index, match[0].length, {
              color: "#008000",
              // background: "#E6F3FF",
            })
          }
          negativeKeywords.forEach(nw => {
            const indexs = findAllIndices(text.toLowerCase(), nw.toLowerCase())
            if (indexs.length) {
              indexs.forEach(index => {
                editor.formatText(index, nw.length, {
                  color: "#D80415",
                  // background: "#E6F3FF",
                })
              })
            }
          })
        }
      })
    }
  }, [reloadKey, negativeKeywords, activeTemplateData, activeTemplateId])

  useEffect(() => {
    if (quillRef.current) {
      handleTextEditorChange()
    }
  }, [quillRef.current, activeTemplateId, activeTemplateData])
  const getData = async () => {
    await getNegativeKeywords()
    await getOptOuts()
    await getCategories()
    await getTemplate()
  }
  const handleTextEditorChange = () => {
    const editor = quillRef.current.getEditor()

    const text = editor.getText()

    console.log(text, "_------------------------")
    editor.formatText(0, text.length, {
      color: "black",
      background: false,
    })

    // For merge field
    let regex = /\{\{[^{}]+\}\}|\[@[^\[\]]+\]/g
    let match
    // Apply formatting to matched patterns
    while ((match = regex.exec(text)) !== null) {
      editor.formatText(match.index, match[0].length, {
        color: "#5932EA",
        // background: "#E6F3FF",
      })
    }
    // For text spinner
    regex = /\[[^\]]*\/[^\]]*(?:\/[^\]]*)*\]/g
    while ((match = regex.exec(text)) !== null) {
      editor.formatText(match.index, match[0].length, {
        color: "#008000",
        // background: "#E6F3FF",
      })
    }
    negativeKeywords.forEach(nw => {
      const indexs = findAllIndices(text.toLowerCase(), nw.toLowerCase())
      if (indexs.length) {
        indexs.forEach(index => {
          editor.formatText(index, nw.length, {
            color: "#D80415",
            // background: "#E6F3FF",
          })
        })
      }
    })
  }
  const [modal_center, set_modal_center] = useState(true)

  const tog_center = () => {
    set_modal_center(state => !state)
  }
  const customModules = {
    toolbar: false,
  }
  const customFormats = ["bold", "italic", "color"]
  return (
    <React.Fragment>
      <Toaster />

      {/* succes alert popup */}
      {/* <Modal
        isOpen={modal_center}
        toggle={() => tog_center}
        backdrop="static"
        centered={true}
        className="alert-modal"
      >
          <div className="modal-body">
              <div className="alert-wrapper">
                <div className="alert-img">
                  <img src={warningIcon} alt="" />
                  <img src={errorIcon} alt="" />
                  <img src={successIcon} alt="" />
                </div>
                <div className="alert-con">
                  <h3>Heading Goes Here</h3>
                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                </div>
                <div className="btn-flex">
                  <button className="main-btn" onClick={() => {set_modal_center(false);}}>
                    <span>Cancel</span>
                  </button>
                  <button className="main-btn secondary-btn">
                    <span>Ok</span>
                  </button>
                </div>
              </div>
          </div>
      </Modal> */}
      {shouldRedirect && shouldRedirect.state && (
        <Redirect to={shouldRedirect.path} />
      )}
      <div className="page-content">
        <MetaTags>
          <title>Edit Template | TextLaunchPad.com</title>
        </MetaTags>
        <Container fluid>
          {isLoading ? (
            <div className="spinner-wr">
              <Spinner style={{ color: "#5932ea" }} />
            </div>
          ) : null}
          <Row className="align-items-center justify-content-between">
            <Col lg={6} md={6} sm={12}>
              <div className="page-title-box pb-0">
                <h4 className="font-size-18" style={{ display: "inline" }}>
                  Edit Template
                </h4>
                <ol className="breadcrumb mb-0">
                  <BreadcrumbItem>
                    <Link to="/">TextLaunchPad</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>Templates</BreadcrumbItem>
                </ol>
              </div>
            </Col>
            <Col md={12} className={"pt-2"}>
              <span
                className={
                  "border border-2 border-start-0 border-top-0 border-end-0 border-bottom-2 d-block"
                }
              ></span>
            </Col>
          </Row>
          <div className="drip-aut-main">
            <div className="row">
              <div className="col-lg-8">
                <ul className="d-aut-wrapper">
                  {/* <li className="d-aut-el">
                    <div className="form-group style-2">
                      <div className="inp-grp">
                        <div className="row gy-2">
                          <div className="col-lg-6 col-sm-12">
                            <input
                              type="text"
                              placeholder="Template Name"
                              value={templateName}
                              onChange={e => setTemplateName(e.target.value)}
                            />
                          </div>
                          <div className="col-lg-6 col-sm-12">
                            <Select
                              value={selectedCategory}
                              onChange={e => {
                                const category = categories.filter(
                                  item => item._id === e.value
                                )
                                const categoryOption =
                                  category && category.length
                                    ? {
                                        label: category[0]?.name,
                                        value: category[0]?._id,
                                        ...category[0],
                                      }
                                    : null
                                setSelectedCategory(categoryOption)
                                console.log(category[0].validations)
                                setValidation(category[0]?.validations)
                              }}
                              options={categories?.map(item => ({
                                label: item?.name,
                                value: item?._id,
                              }))}
                              placeholder="Select Category"
                              menuPortalTarget={document.body}
                              styles={{
                                control: base => ({
                                  ...base,
                                  borderRadius: "10px",
                                  paddingLeft: "10px",
                                  border: "1.5px solid #5932EA",
                                  color: "#5932EA",
                                }),
                                menuPortal: base => ({ ...base, zIndex: 9999 }),
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </li> */}
                  <span key={reloadKey}>
                    {dataArr &&
                      dataArr.map((item, index) => {
                        return (
                          <span
                            key={index}
                            className={
                              activeTemplateId === item._id
                                ? "active-template"
                                : ""
                            }
                          >
                            <li key={index}>
                              {activeTemplateId === item._id ? (
                                <div
                                  className="d-aut-main"
                                  id={"active-template-id"}
                                  ref={sectionRef}
                                >
                                  <span className="d-num-in">
                                    Variations:{" "}
                                    <span className="color-primary">
                                      {findVariations(item.message)}
                                    </span>
                                  </span>
                                  <div className="d-aut-body">
                                    {/* <div className="form-group style-2">
                                      <div className="inp-grp"> */}
                                    {/* <div className="template-box-head">
                                      <p>
                                        Variations:{" "}
                                        {findVariations(item.message)}
                                      </p>
                                      <div className="actions">
                                        <button
                                          className="actions-btn"
                                          onClick={() => {
                                            minimizeAll()
                                          }}
                                        >
                                          <img src={applyIcon} alt="" />
                                        </button>
                                      </div>
                                    </div> */}
                                    <div className="row">
                                      <div className="col-lg-6 col-sm-12">
                                        <div className="form-group">
                                          <div className="inp-grp">
                                            <input
                                              type="text"
                                              placeholder="Template Name"
                                              value={templateName}
                                              onChange={e =>
                                                setTemplateName(e.target.value)
                                              }
                                              className="primary-border bg-white"
                                              style={{
                                                border:
                                                  "1.5px solid #5932EA !important",
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-sm-12">
                                        <Select
                                          value={selectedCategory}
                                          onChange={e => {
                                            const category = categories.filter(
                                              item => item._id === e.value
                                            )
                                            const categoryOption =
                                              category && category.length
                                                ? {
                                                    label: category[0]?.name,
                                                    value: category[0]?._id,
                                                    ...category[0],
                                                  }
                                                : null
                                            setSelectedCategory(categoryOption)
                                            console.log(category[0].validations)
                                            setValidation(
                                              category[0]?.validations
                                            )
                                          }}
                                          options={categories?.map(item => ({
                                            label: item?.name,
                                            value: item?._id,
                                          }))}
                                          placeholder="Select Category"
                                          menuPortalTarget={document.body}
                                          styles={{
                                            control: base => ({
                                              ...base,
                                              borderRadius: "10px",
                                              paddingLeft: "10px",
                                              border: "1.5px solid #5932EA",
                                              color: "#5932EA",
                                              height: "50px",
                                            }),
                                            menuPortal: base => ({
                                              ...base,
                                              zIndex: 9999,
                                            }),
                                          }}
                                        />
                                      </div>
                                    </div>
                                    {/* </div>
                                    </div> */}
                                    {item.showTextSpinner ? (
                                      <TextSpinner
                                        addSpinnerTextHandler={
                                          addSpinnerTextHandler
                                        }
                                        closeSpinnerTextHandler={
                                          closeSpinnerTextHandler
                                        }
                                        negativeKeywords={negativeKeywords}
                                        mssgIndex={index}
                                        validation={validation}
                                      />
                                    ) : null}
                                    <>
                                      {/* <textarea
                                          className="mt-2"
                                          ref={messageTextAreaRef}
                                          name=""
                                          id=""
                                          rows="5"
                                          defaultValue={item.message}
                                          onChange={e => {
                                            handleDataChange(
                                              index,
                                              "message",
                                              e.target.value
                                            )
                                            updateMessageCursorPosition(
                                              index,
                                              e.target.selectionStart
                                            )
                                          }}
                                          onClick={e => {
                                            updateMessageCursorPosition(
                                              index,
                                              e.target.selectionStart
                                            )
                                          }}
                                        ></textarea> */}
                                      <ReactQuill
                                        placeholder="Write message..."
                                        ref={quillRef}
                                        value={item.message}
                                        className="message-text-editor"
                                        onChange={value => {
                                          handleDataChange(
                                            index,
                                            "message",
                                            value
                                          )
                                        }}
                                        modules={customModules}
                                        formats={customFormats}
                                        theme="snow"
                                      />
                                      <span
                                        style={{
                                          float: "right",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {getMessageCharCount(item.message) <=
                                        160 ? (
                                          <i
                                            className="fas fa-check-circle fa-sm me-1"
                                            style={{ color: "Green" }}
                                          ></i>
                                        ) : null}
                                        {getMessageCharCount(item.message) <=
                                          320 &&
                                        getMessageCharCount(item.message) >
                                          160 ? (
                                          <i
                                            className="fas fa-exclamation-circle fa-sm me-1"
                                            style={{ color: "orange" }}
                                          ></i>
                                        ) : null}
                                        {getMessageCharCount(item.message) >
                                        320 ? (
                                          <i
                                            className="fas fa-times-circle fa-sm me-1"
                                            style={{ color: "red" }}
                                          ></i>
                                        ) : null}
                                        {getMessageCharCount(item.message)
                                          ? getMessageCharCount(item.message)
                                          : 0}
                                        /320
                                      </span>
                                      <div className="d-aut-ac-wraps ">
                                        <Dropdown
                                          isOpen={false}
                                          toggle={() => {
                                            handleDataChange(
                                              index,
                                              "isMergeFieldOpen",
                                              !item.isMergeFieldOpen
                                            )
                                          }}
                                        >
                                          <DropdownToggle
                                            className="btn "
                                            caret
                                            color={"outline-primary"}
                                          >
                                            Merge Fields
                                            <i className="mdi mdi-chevron-down" />
                                          </DropdownToggle>
                                          <DropdownMenu
                                            className={
                                              item.isMergeFieldOpen
                                                ? "d-flex flex-column gap-1 p-2"
                                                : ""
                                            }
                                          >
                                            {tags?.length > 0 &&
                                              tags.map((item, tagIndex) => (
                                                <DropdownItem
                                                  key={tagIndex}
                                                  type={"button"}
                                                  className="drp-dwn-item"
                                                  onClick={() => {
                                                    addTag(item, index)
                                                  }}
                                                >
                                                  {item}
                                                </DropdownItem>
                                              ))}
                                          </DropdownMenu>
                                        </Dropdown>

                                        <Dropdown
                                          isOpen={item.isOptOutOpen}
                                          toggle={() => {
                                            handleDataChange(
                                              index,
                                              "isOptOutOpen",
                                              !item.isOptOutOpen
                                            )
                                          }}
                                        >
                                          <DropdownToggle
                                            className="btn "
                                            caret
                                            color={"outline-primary"}
                                          >
                                            OptOut Languages{" "}
                                            <i className="mdi mdi-chevron-down" />
                                          </DropdownToggle>
                                          <DropdownMenu
                                            className={
                                              item.isOptOutOpen
                                                ? "d-flex flex-column gap-1 p-2"
                                                : ""
                                            }
                                          >
                                            {optOuts?.length > 0 &&
                                              optOuts.map(item => (
                                                <DropdownItem
                                                  type={"button"}
                                                  className="drp-dwn-item"
                                                  onClick={e => {
                                                    addTag(
                                                      "[@" + item.title + "]",
                                                      index
                                                    )
                                                  }}
                                                  key={item._id}
                                                >
                                                  {item.title}
                                                </DropdownItem>
                                              ))}
                                          </DropdownMenu>
                                        </Dropdown>

                                        <button
                                          onClick={() =>
                                            addTextSpinnerHandler(index)
                                          }
                                        >
                                          <img src={purplePlus} alt="" />
                                          <span>Add Text Spinner</span>
                                        </button>
                                        <button
                                          onClick={() =>
                                            handleDataChange(
                                              index,
                                              "showNegativeKeyword",
                                              !item.showNegativeKeyword
                                            )
                                          }
                                        >
                                          {item.showNegativeKeyword ? (
                                            <i className="fas fa-eye-slash color-primary"></i>
                                          ) : (
                                            <i className="fas fa-eye color-primary"></i>
                                          )}
                                          <span>
                                            {item.showNegativeKeyword
                                              ? "Hide"
                                              : "Show"}{" "}
                                            negative keywords
                                          </span>
                                        </button>
                                      </div>
                                      {item.showNegativeKeyword && (
                                        <table className="d-flex flex-wrap p-0 ">
                                          <tbody>
                                            <tr className="d-flex flex-wrap py-3 gap-2">
                                              <th>Negative Keywords:</th>
                                              {negativeKeywords.map(
                                                (nk, nkIndex) => (
                                                  <td
                                                    className={`px-1 bg-light rounded-1 ${
                                                      isPresent(
                                                        nk,
                                                        item.message
                                                      )
                                                        ? "badge-soft-danger"
                                                        : "bg-light "
                                                    }`}
                                                    value={nkIndex}
                                                    key={nkIndex}
                                                  >
                                                    {nk}
                                                  </td>
                                                )
                                              )}
                                            </tr>
                                          </tbody>
                                        </table>
                                      )}

                                      <div className="d-flex gap-3 justify-content-end">
                                        <button
                                          className={`main-btn mt-3 ${
                                            !checkValidSubmit()
                                              ? "opacity-50"
                                              : ""
                                          }`}
                                          onClick={() => {
                                            submitTemplate()
                                          }}
                                          disabled={!checkValidSubmit()}
                                        >
                                          {/* <img src={whitePlus} alt="" /> */}
                                          <span>Submit</span>
                                        </button>
                                        <Link
                                          className="main-btn mt-3 secondary-btn"
                                          to="/templates"
                                        >
                                          {/* <img src={whitePlus} alt="" /> */}
                                          <span>Cancel</span>
                                        </Link>
                                      </div>
                                    </>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className={`d-aut-main mb-4 ${
                                    !isMessageComplete(item.message)
                                      ? "border-danger"
                                      : "border-info"
                                  }  border-start ps-2 border-2`}
                                  onClick={() => {
                                    const oldData = { ...activeTemplateData }
                                    const crrIndex = dataArr.findIndex(
                                      item => item._id == activeTemplateId
                                    )
                                    const newDataArr = [...dataArr]
                                    newDataArr[crrIndex] = oldData
                                    setDataArr(newDataArr)
                                    setActiveTemplateId(item._id)
                                    setActiveTemplateData({ ...item })
                                    setTemplateName(item.name)
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <div className="d-aut-head flex-column align-items-start gap-2">
                                    <span className="text-black small ">
                                      {item.name}
                                    </span>
                                    {!isMessageComplete(item.message) ? (
                                      <p className="text-danger d-flex gap-2 align-items-center">
                                        <img
                                          src={warningError}
                                          width={"20px"}
                                          alt=""
                                        />
                                        Message not completed
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                    <p style={{ wordWrap: "anywhere" }}>
                                      {item.message}
                                    </p>
                                    <p className={"text-info"}>
                                      Variations: {findVariations(item.message)}
                                    </p>
                                  </div>
                                  {/* <div className="d-aut-body">
                                  
                                  </div> */}
                                </div>
                              )}
                            </li>
                          </span>
                        )
                      })}
                  </span>
                  <li className="">
                    <button className="main-btn transparent">
                      <span className="text-black">
                        Total Template Count:
                        <strong className="text-info"> {dataArr.length}</strong>
                      </span>
                    </button>
                  </li>

                  <li className="position-static d-none">
                    <div className="d-flex gap-3">
                      <button
                        className={`main-btn mt-3 ${
                          !checkValidSubmit() ? "opacity-50" : ""
                        }`}
                        onClick={() => {
                          submitTemplate()
                        }}
                        disabled={!checkValidSubmit()}
                      >
                        {/* <img src={whitePlus} alt="" /> */}
                        <span>Submit</span>
                      </button>
                      <Link
                        className="main-btn mt-3 secondary-btn"
                        to="/templates"
                      >
                        {/* <img src={whitePlus} alt="" /> */}
                        <span>Cancel</span>
                      </Link>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 col-12">
                <div className="c-card t-sticky">
                  <div
                    className="d-flex mb-1 pb-1"
                    style={{ borderBottom: "1px solid #00000059" }}
                  >
                    <span className="text-black">
                      {activeTemplateData?.name}
                    </span>
                  </div>
                  {!selectedCategory ? (
                    <div className="text-warning text-center">
                      <i
                        className="fa fa-exclamation-triangle"
                        aria-hidden="true"
                      ></i>{" "}
                      Select Category to see validations
                    </div>
                  ) : selectedCategory?.validations ? (
                    <>
                      <ul className="check-list">
                        {validation?.minChar && (
                          <li
                            className={
                              getMessageCharCount(
                                getDataFromActiveId(activeTemplateId)?.message
                              ) >= validation?.minChar
                                ? "active"
                                : ""
                            }
                          >
                            <span>
                              Minimum of {validation?.minChar} characters
                            </span>
                          </li>
                        )}
                        {validation.minTextSpinner && (
                          <li
                            className={
                              getTextSpinnerCount(
                                getDataFromActiveId(activeTemplateId)?.message
                              ) >= validation?.minTextSpinner
                                ? "active"
                                : ""
                            }
                          >
                            <span>
                              At least {validation?.minTextSpinner} text
                              spinners
                            </span>
                          </li>
                        )}
                        {validation.minElementInTextSpinner && (
                          <li
                            className={
                              isTextSpinnerHasMinElement(
                                getDataFromActiveId(activeTemplateId)?.message,
                                validation?.minElementInTextSpinner
                              )
                                ? "active"
                                : ""
                            }
                          >
                            <span>
                              Each text spinner must have at least{" "}
                              {validation?.minElementInTextSpinner} elements
                            </span>
                          </li>
                        )}
                        {validation.isNoNegativeKeywordRequired && (
                          <li
                            className={
                              checkNegativeKeywords(
                                getDataFromActiveId(activeTemplateId)?.message
                              )
                                ? "active"
                                : false
                            }
                          >
                            <span>
                              Must have no negative/restricted keyword
                            </span>
                          </li>
                        )}
                        {validation?.isMergeFieldRequired && (
                          <li
                            className={
                              getMergeFieldCount(
                                getDataFromActiveId(activeTemplateId)?.message
                              )
                                ? "active"
                                : ""
                            }
                          >
                            <span> Must have Merge Field</span>
                          </li>
                        )}

                        {validation?.validMergeFieldRequired && (
                          <li
                            className={
                              isMergeFieldValid(
                                getDataFromActiveId(activeTemplateId)?.message
                              )
                                ? "active"
                                : ""
                            }
                          >
                            <span> All Merge Fields must be valid</span>
                          </li>
                        )}
                      </ul>
                    </>
                  ) : (
                    <div className="d-flex text-danger align-items-center mt-2 flex-wrap gap-2">
                      <span className="d-flex gap-2 align-items-center">
                        <i
                          className="fa fa-exclamation-triangle"
                          aria-hidden="true"
                        ></i>
                        <span>
                          To see validations, update validation of this category
                          first.
                        </span>
                      </span>
                      <Link
                        to={{
                          pathname: "/templates-setting",
                        }}
                      >
                        Update now{" "}
                        <i className="fa fa-link" aria-hidden="true"></i>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

const TextSpinner = ({
  negativeKeywords,
  addSpinnerTextHandler,
  closeSpinnerTextHandler,
  mssgIndex,
  validation,
}) => {
  const [inputs, setInputs] = useState(["", "", ""])
  const [error, setError] = useState("")

  const inputChangeHandler = (index, val) => {
    const inputArr = [...inputs]
    inputArr[index] = val
    setInputs(inputArr)
  }

  const toggleInput = (operation, index = null) => {
    switch (operation) {
      case "add": {
        setInputs([...inputs, ""])
        break
      }
      case "remove": {
        const inputArr = [...inputs]
        inputArr.splice(index, 1)
        setInputs(inputArr)
        break
      }
      case "delete": {
        setInputs([])
        closeSpinnerTextHandler(mssgIndex)
        break
      }
      case "save": {
        const inputArr = inputs.filter(i => i.trim().length > 0)
        if (
          validation?.minElementInTextSpinner &&
          inputArr.length <= validation?.minElementInTextSpinner
        ) {
          setError(
            `Must have at least ${validation?.minElementInTextSpinner} elements`
          )
        } else if (inputArr.length !== new Set(inputArr).size) {
          setError("Elements must be unique")
        } else {
          const val = inputArr.join(" ")
          const negativeKeywordsFound = negativeKeywords.filter(word =>
            val?.toLowerCase().includes(word?.toLowerCase())
          )
          if (negativeKeywordsFound.length) {
            setError(
              `Negative keywords found: ${negativeKeywordsFound.join(", ")}`
            )
          } else {
            setInputs([])
            addSpinnerTextHandler(inputArr, mssgIndex)
          }
        }
        // addSpinnerTextHandler(inputArr)

        break
      }
      default:
        break
    }
  }

  return (
    <div className="text-spinner-wr py-4 px-2">
      <div className="d-flex flex-wrap gap-2">
        {inputs.map((input, index) => (
          <div key={index} className="d-flex gap-1">
            <input
              value={input}
              placeholder="word or phrase"
              onChange={e => inputChangeHandler(index, e.target.value)}
              className="border-start-0 border-end-0 border-top-0 border-top-1 border me-2 small"
              onKeyDown={e => {
                if (e.keyCode === 13) e.preventDefault()
              }}
            />
            {index >= 3 ? (
              <Button
                className={"has-icon me-1 small text-danger bg-transparent"}
                color={"danger"}
                title="Remove"
                onClick={() => toggleInput("remove", index)}
              >
                <i className="fas fa-times"></i>
              </Button>
            ) : null}
            /
          </div>
        ))}
      </div>
      {inputs && inputs.length ? (
        <div className="d-flex gap-2 p-3">
          <Button
            className={"has-icon me-1 btn-theme-color"}
            color={"success"}
            title="Add"
            onClick={() => {
              toggleInput("add")
            }}
          >
            <i className="fas fa-plus"></i>
          </Button>

          <Button
            className={"has-icon me-1"}
            color={"danger"}
            title="Delete"
            onClick={() => {
              toggleInput("delete")
            }}
          >
            <i className="fas fa-trash-alt"></i>
          </Button>
          <Button
            className={"has-icon me-1 btn-theme-color"}
            color={"success"}
            title="Save"
            onClick={() => {
              toggleInput("save")
            }}
          >
            <i className="fas fa-save"></i>
          </Button>
        </div>
      ) : null}
      {inputs && inputs.length && error ? (
        <div className="text-danger">{error}</div>
      ) : null}
    </div>
  )
}

// Dashboard.propTypes = {
//   t: PropTypes.any
// }

export default withTranslation()(EditTemplate)

import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import axios from "axios"
import toast, { Toaster } from "react-hot-toast"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Modal,
  Label,
  FormGroup,
  Alert,
  CardHeader,
  BreadcrumbItem,
  Nav,
  NavItem,
  NavLink,
  Button,
  Spinner,
} from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import _ from "lodash"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import SweetAlert from "react-bootstrap-sweetalert"
import dripIconBorder from "../../assets/images/drip-icon-border.svg"
import dripIconFilled from "../../assets/images/drip-icon-filled.svg"
import close from "../../assets/images/close.svg"
import InfiniteScroll from "react-infinite-scroll-component"
import {
  getCampaign,
  getLeadStages,
  exportCampProsData,
  getQuickReplies,
  sendConversation,
  existsProspect,
} from "../../store/campaigns/actions"

import {
  updateProspectDNS,
  updateProspectVerified,
  removeProspectVerified,
  updateProspectWrongNumber,
  removeProspectWrongNumber,
  updateProspectQualified,
  removeProspectQualified,
  updateProspectPriority,
  removeProspectPriority,
  createNotes,
  deleteProspects,
} from "../../store/prospects/actions"

import { AvForm, AvField } from "availity-reactstrap-validation"
import io from "socket.io-client"
import Select from "react-select"
import Conversations from "./Conversations"
import CampaignHeader from "./CampaignHeader"

import exportIcon from "../../assets/images/exporticon.svg"
import crossIcon from "../../assets/images/crossIcon2.svg"
import phoneCut from "../../assets/images/phoneCut.svg"
import dripIconActive from "../../assets/images/drip-icon-filled.svg"
import dripIcon from "../../assets/images/drip-icon-border.svg"
import checkIcon from "../../assets/images/check.svg"
import starIcon from "../../assets/images/star.svg"
import boltIcon from "../../assets/images/boltIcon.svg"
import crossIconActive from "../../assets/images/crossIcon2-active.svg"
import phoneCutActive from "../../assets/images/phoneCut-active.svg"
import checkIconActive from "../../assets/images/check-active.svg"
import starIconActive from "../../assets/images/star-active.svg"
import boltIconActive from "../../assets/images/boltIcon-active.svg"
import threeDot from "../../assets/images/threedotverticle.svg"
import illus from "../../assets/images/chat-illustration.svg"
import moment from "moment"

class CampaignProspectsMessagesNotes extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      id: this.props.match.params.id ? this.props.match.params.id : null,
      message: null,
      contact: [],
      trash: [],
      selected_lead: null,
      search: "",
      leads: [],
      contactLength: 0,
      selectList: [],
      selectedProspectIndex: null,
      selectedProspectId: null,
      isLoading: false,
      modal_center: false,
      show: false,
      token: false,
      prevScrollPos: 0,
      editProspectModel: {
        show: false,
      },
      activeTab: null,
      convReloadKey: Math.random(),
      confirmProspectDelete: {
        show: false,
      },
      isLeadChanged: false,
      changedLead: null,
      tagsData: { tags: [], tagsOptions: [] },
      reminder: null,
      drip_modal: false,

      token: false,
      dripReloadKey: Math.random(),
      dripTemplateDetail: {
        isOpen: false,
        id: null,
        data: {},
      },
      dripProspectId: null,
      dripRemoveConfirm: {
        isOpen: false,
      },
      dripTemplates: null,
      user: null,
      pagination: { page: 1, limit: 20 },
      prospectData: null,
      prospectDataIsLoading: false,
      activeConvIndex: 0,
      sendText: "",
    }
    let authUser = localStorage.getItem("authUser")
    this.config = null
    if (authUser) {
      let user = JSON.parse(authUser)
      let token = user?.user?.token
      this.config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }

      this.socket = io(process?.env.REACT_APP_SOCKET_URL, {
        // process?.env.REACT_APP_PORT
        // "http://192.168.29.95:5001"
        query: {
          email: user.user.name,
        },
      })

      this.socket.on("newMessage", data => {
        try {
          let updateLitigatorTrue = this.search(["stop", "end"], data?.body)
          let updateLitigatorFalse = this.search(["start", "begin"], data?.body)

          const prospectExistIndex = this.state.contact?.findIndex(
            item => item.prospect._id === data.prospect
          )
          if (prospectExistIndex !== -1) {
            let prospect = this.state.contact[prospectExistIndex]
            const convIndex = prospect.conversations?.findIndex(
              item => item._id === data.convId
            )

            if (convIndex !== -1) {
              let conversation = prospect.conversations[convIndex]
              if (conversation?.messages && conversation?.messages?.length) {
                conversation?.messages?.push(data)
              } else {
                conversation["messages"] = [data]
              }

              conversation.unreadCount = conversation.unreadCount
                ? conversation.unreadCount + 1
                : 1
              const newConversations = [...prospect?.conversations]
              newConversations[convIndex] = conversation
              prospect.conversations = newConversations

              const allProspects = [...this.state.contact]
              allProspects[prospectExistIndex] = prospect

              if (updateLitigatorTrue) {
                const updatedProspectData = {
                  ...prospect?.prospect,
                  litigator: true,
                }
                prospect.prospect = updatedProspectData
              }
              if (updateLitigatorFalse) {
                const updatedProspectData = {
                  ...prospect?.prospect,
                  litigator: false,
                }
                prospect.prospect = updatedProspectData
              }

              this.setState({
                contact: allProspects,
                message: prospect,
                convReloadKey: Math.random(),
              })
            }
          }
          // return prevProspectData
        } catch (error) {
          console.log(error, "error")
        }
      })
    }

    this.resetMessage = this.resetMessage.bind(this)
    this.openUpdateProspectModel = this.openUpdateProspectModel.bind(this)
    this.openDeleteProspectModel = this.openDeleteProspectModel.bind(this)
    this.toggleLoader = this.toggleLoader.bind(this)
    this.refreshData = this.refreshData.bind(this)
    this.updateProspectTags = this.updateProspectTags.bind(this)
    this.updateListByLead = this.updateListByLead.bind(this)
    this.handleDeleteReminder = this.handleDeleteReminder.bind(this)
    this.handleCreateReminder = this.handleCreateReminder.bind(this)
    this.tog_drip = this.tog_drip.bind(this)
    this.togRemoveDrip = this.togRemoveDrip.bind(this)
    this.verifiedNumber = this.verifiedNumber.bind(this)
    this.setDNC = this.setDNC.bind(this)
    this.wrongNumber = this.wrongNumber.bind(this)
    this.qualifiedNumber = this.qualifiedNumber.bind(this)
    this.priorityNumber = this.priorityNumber.bind(this)
    this.getProspectDataById = this.getProspectDataById.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
    this.leadChangeHandler = this.leadChangeHandler.bind(this)
    this.setActiveConvIndex = this.setActiveConvIndex.bind(this)
    this.updateSendText = this.updateSendText.bind(this)
  }

  search = (arr, targetString) => {
    return arr.some(
      str => targetString.trim().toLowerCase() === str.toLowerCase()
    )
  }

  updateSendText(val) {
    this.setState({ sendText: val })
  }
  setActiveConvIndex(index) {
    this.setState({ activeConvIndex: index })
  }

  setOptions() {
    ///count will show only when 1st option has been selected

    const changeSelect = () => {
      this.setState({
        selected_lead: {
          label: "Unread/Priority (" + (this.state.contact.length || 0) + ")",
          // label: "Unread/Priority",
          value: "",
        },
      })
    }

    let a1 = []
    this.props.leads.map(item => {
      if (item.lead_Stage !== "Initial Follow Up") {
        a1.push({ label: item.lead_Stage, value: item._id })
      }
    })
    let b1 = { label: "Unread/Priority ", value: "" }
    if (
      this.state.selected_lead == null ||
      this.state.selected_lead.value == ""
    ) {
      b1 = {
        label: "Unread/Priority (" + (this.state.contact.length || 0) + ")",
        value: "",
      }
      // b1 = { label: "Unread/Priority", value: "" }
      changeSelect()
    }
    a1.unshift(b1)
    this.setState({
      leads: a1,
    })
  }

  getLeadOptions() {
    const options = [
      {
        label: "Unread/Priority",
        // label: "Unread/Priority (" + (this.state.contact.length || 0) + ")",
        value: "",
      },
    ]
    this.state.leads.forEach(element => {
      options.push({ label: element.lead_Stage, value: element._id })
    })

    return options
  }

  resetMessage(id) {
    let msg = _.find(this.state.contact, { _id: id })
    this.setState({
      message: msg,
    })
  }

  updateListByLead(lead, selectedProspectId, isLeadChanged = false) {
    if (isLeadChanged) {
      const leadData = this.props.leads?.filter(item => item._id === lead)
      this.setState({
        selectedProspectId,
        isLoading: true,
        selected_lead: {
          label: leadData[0]?.lead_Stage,
          value: leadData[0]?.id,
        },
      })
      this.onChange({ value: lead })
    } else {
      this.setState({
        selectedProspectId,
        isLoading: true,
      })
      this.onChange(this.state.selected_lead)
    }
  }

  notify(type, text) {
    switch (type) {
      case "success":
        toast.success(text)
        break
      case "error":
        if (typeof text !== "string") {
          if (text?.message && typeof text?.message === "string") {
            toast.error(text?.message)
          } else {
            toast.error("An unexpected error occurred")
          }
        } else {
          toast.error(text)
        }
        break

      default:
        break
    }
  }

  async getTags() {
    const authUser = localStorage.getItem("authUser")
    let token = false
    if (authUser) {
      let user = JSON.parse(authUser)
      token = user?.user?.token
    } else {
      token = false
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
    try {
      const response = await axios.get(
        process.env.REACT_APP_PORT + "/api/tags/pullTags",
        config
      )

      if (response.data) {
        this.setState({
          tagsData: {
            tags: response.data.data,
            tagsOptions: response.data.data.map(item => ({
              value: item._id,
              label: item.name,
              data: { ...item },
            })),
          },
        })
      }
    } catch (err) {
      this.notify("error", err)
    }
  }

  async getProspects(lead) {
    this.setState({ isLoading: true })
    const authUser = localStorage.getItem("authUser")
    let token = false
    if (authUser) {
      let user = JSON.parse(authUser)
      token = user?.user?.token
    } else {
      token = false
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
    const { limit } = this.state.pagination
    let query = { page: 1, limit }
    if (lead) {
      query.leadStage = lead
    } else {
      query.unreadOrPriority = true
    }
    query = new URLSearchParams(query)
    try {
      const response = await axios.get(
        process.env.REACT_APP_PORT +
          `/api/conversation/getAllConversations/${this.props.match.params.id}?` +
          query,
        config
      )
      if (response.data) {
        this.setState({
          contact: [...response.data.prospects],
          isLoading: false,
          totalProspectsCount: response.data.totalProspectsCount,
        })
        if (this.state.selectedProspectId) {
          const selectedProspect = response.data.prospects.filter(
            item => item._id === this.state.selectedProspectId
          )
          if (selectedProspect && selectedProspect[0]) {
            this.setState({
              message: selectedProspect[0],
            })
          }
        }
      }
    } catch (err) {
      this.notify("error", err)
      this.setState({
        isLoading: false,
      })
    }
  }

  async getMoreProspects({ page, limit }) {
    this.setState({ isLoading: true })
    const authUser = localStorage.getItem("authUser")
    let token = false
    if (authUser) {
      let user = JSON.parse(authUser)
      token = user?.user?.token
    } else {
      token = false
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
    let query = { page, limit }
    if (this.state.search) {
      query.leadStage = this.state.search
    } else {
      query.unreadOrPriority = true
    }
    query = new URLSearchParams(query)
    try {
      const response = await axios.get(
        process.env.REACT_APP_PORT +
          `/api/conversation/getAllConversations/${this.props.match.params.id}?` +
          query,
        config
      )
      if (response.data) {
        this.setState({
          contact: [...this.state.contact, ...response.data.prospects],
          isLoading: false,
          totalProspectsCount: response.data.totalProspectsCount,
        })
      }
    } catch (err) {
      this.notify("error", err)
      this.setState({
        isLoading: false,
      })
    }
  }

  async updateProspectTags(selectedOption = [], prospectId) {
    if (selectedOption === null) {
      selectedOption = []
    }
    // this.setState({ selectedOption })
    const tagsArr = selectedOption.map(item => {
      return { ...item.data }
    })
    const authUser = localStorage.getItem("authUser")
    let token = false
    if (authUser) {
      let user = JSON.parse(authUser)
      token = user?.user?.token
    } else {
      token = false
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
    try {
      const response = await axios.put(
        process.env.REACT_APP_PORT + "/api/prospect/updateTags/" + prospectId,
        { tags: tagsArr },
        config
      )

      if (response.data) {
        const data = {
          ...this.state.message,
        }
        data.prospect.tags = selectedOption.map(item => {
          return item.value
        })
        this.setState({
          activeTab: 1,
          message: data,
          convReloadKey: Math.random(),
        })
      }
    } catch (err) {
      this.notify("error", err.response.data.message)
    }
  }

  async componentDidMount() {
    this.getProspects()
    this.getDripTemplates()
    let id = this.props.match.params.id
    await this.props.getLeadStages()
    await this.getTags()
    await this.props.getCampaign(id)
    await this.props.getLeadStages()
    let authUser = localStorage.getItem("authUser")
    let user = JSON.parse(authUser)
    await this.socket.emit("getmessages", user.user.name, id)
    const token = user?.user?.token
    this.setState({ token: token, user })
  }
  handleScroll() {
    setTimeout(() => {
      this.getMoreProspects({
        page: this.state.pagination.page + 1,
        limit: this.state.pagination.limit,
      })
      this.setState({
        pagination: {
          page: this.state.pagination.page + 1,
          limit: this.state.pagination.limit,
        },
      })
    }, 500)
  }
  ///changes below
  async componentDidUpdate(prevProps, prevState, snapshot) {
    // this.setOptions();
    if (prevProps.leads.length <= 0 && this.props.leads.length > 0) {
      // this.setOptions()
      this.setState({ leads: this.props.leads })
    }
    if (
      this.props.leads.length > 0 &&
      this.state.contact.length &&
      !this.state.selected_lead
    ) {
      // this.setOptions()
      this.setState({
        selected_lead: {
          label: "Unread/Priority",
          // label: "Unread/Priority (" + (this.state.contact.length || 0) + ")",
          value: "",
        },
      })
    }

    if (!prevProps.successMsg && this.props.successMsg) {
      this.notify("success", this.props.successMsg)
      this.setState({ isLoading: false })

      if (
        this.props.successMsg.toLowerCase().includes("note") ||
        this.props.successMsg.toLowerCase().includes("delete")
      ) {
        let authUser = localStorage.getItem("authUser")
        let user = JSON.parse(authUser)
        // setTimeout(() => {
        if (this.state.search == "") {
          this.socket.emit("getmessages", user.user.name, this.state.id)
        } else {
          this.socket.emit(
            "searchmessage",
            user.user.name,
            this.state.search,
            this.state.id
          )
        }

        if (this.props.successMsg.toLowerCase().includes("note")) {
          this.setState({
            isLoading: true,
            convReloadKey: Math.random(),
            activeTab: 2,
          })
        } else {
          this.setState({
            isLoading: true,
            convReloadKey: Math.random(),
            activeTab: null,
          })
        }
        // }, 1000)
      } else if (this.state.search == "" && !this.state.isLeadChanged) {
        let authUser = localStorage.getItem("authUser")
        let user = JSON.parse(authUser)
        this.socket.emit("getmessages", user.user.name, this.state.id)
      } else if (this.state.changedLead) {
        let authUser = localStorage.getItem("authUser")
        let user = JSON.parse(authUser)
        this.socket.emit(
          "getmessagebypros",
          user.user.name,
          this.state.message.prospect._id
        )
        this.socket.emit(
          "searchmessage",
          user.user.name,
          this.state.changedLead,
          this.state.id
        )

        const lead = this.props.leads?.filter(
          item => item._id === this.state.changedLead
        )
        this.setState({
          selected_lead: { label: lead[0]?.lead_Stage, value: lead[0]?.id },
        })
      } else if (this.state.search) {
        let authUser = localStorage.getItem("authUser")
        let user = JSON.parse(authUser)
        this.setState({ isLoading: true })
        this.socket.emit(
          "searchmessage",
          user.user.name,
          this.state.search,
          this.state.id
        )
      }
    }

    if (!prevState.contact && this.state.contact) {
      this.setOptions()
    }
  }
  tog_center() {
    this.removeBodyCss()
    this.setState({
      modal_center: !this.state.modal_center,
    })
  }
  onChange(lead) {
    this.setState({
      search: lead.value,
      isLoading: true,
      pagination: {
        page: 1,
        limit: this.state.pagination.limit,
      },
    })
    this.getProspects(lead.value)

    // let authUser = localStorage.getItem("authUser")
    // let user = JSON.parse(authUser)

    // if (lead.value == "") {
    //   this.socket.emit("getmessages", user.user.name, this.state.id)
    // } else {
    //   this.socket.emit(
    //     "searchmessage",
    //     user.user.name,
    //     lead.value,
    //     this.state.id
    //   )
    // }
  }

  handleUpdateProspect(event, values) {
    this.setState({
      editProspectModel: { ...this.state.editProspectModel, show: false },
    })

    this.updateProspect(
      {
        first_name: values.first_name,
        last_name: values.last_name,
      },
      this.state.editProspectModel.prospectId
    )
  }

  async getReminder(id) {
    const authUser = localStorage.getItem("authUser")
    let token = false
    if (authUser) {
      let user = JSON.parse(authUser)
      token = user?.user?.token
    } else {
      token = false
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
    try {
      const response = await axios.get(
        process.env.REACT_APP_PORT +
          `/api/reminders/pullProspectReminder/${id}`,
        config
      )

      if (response) {
        this.setState({
          reminder: response.data.data,
          convReloadKey: Math.random(),
          isLoading: false,
        })
      }
    } catch (err) {
      this.notify("error", err)
    }
  }
  async handleDeleteProspect(data) {
    this.setState({
      isLoading: true,
    })

    try {
      const response = await axios.post(
        process.env.REACT_APP_PORT + "/api/prospect/deleteprospects",
        data,
        this.config
      )
      if (response) {
        this.notify("success", "Prospect deleted successfully")
        this.setState({
          isLoading: false,
          confirmProspectDelete: { show: false },
        })
        this.getProspects()
        this.setState({
          message: null,
          selectedProspectId: null,
        })
      }
    } catch (err) {
      this.notify("error", err?.response?.data?.message)
      this.setState({
        isLoading: false,
      })
    }
  }
  async handleCreateReminder(data) {
    this.setState({
      isLoading: true,
    })

    // const data = {
    //   date:value.calendar ,
    //   prospectId: this.props.data._id,
    //   note:value.note
    // }
    try {
      const response = await axios.post(
        process.env.REACT_APP_PORT + "/api/reminders/createReminder/",
        data,
        this.config
      )
      if (response) {
        this.notify("success", "Reminder Created Successfully")
        this.getReminder(data.prospectId)
        this.setState({ activeTab: 1, isLoading: false })
      }
    } catch (err) {
      this.notify("error", err)
      this.setState({
        isOpen: false,
        isLoading: false,
      })
    }
  }
  async handleDeleteReminder(id) {
    const authUser = localStorage.getItem("authUser")
    let token = false
    if (authUser) {
      let user = JSON.parse(authUser)
      token = user?.user?.token
    } else {
      token = false
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
    this.setState({ isLoading: true })
    try {
      const response = await axios.delete(
        process.env.REACT_APP_PORT + `/api/reminders/deleteReminder/${id}`,
        config
      )
      if (response.data) {
        this.notify("success", "Reminder marked as completed successfully!")
        this.getReminder(id)
        this.setState({ activeTab: 1 })
      }
    } catch (err) {
      this.notify("error", err)
    }
  }

  async updateProspect(data, id) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.token}`,
      },
    }
    try {
      const response = await axios.put(
        process.env.REACT_APP_PORT + "/api/prospect/updateProspect/" + id,
        data,
        config
      )

      if (response.status === 200) {
        let authUser = localStorage.getItem("authUser")
        let user = JSON.parse(authUser)
        this.updateProspectObj(id, "first_name", data?.first_name)
        this.updateProspectObj(id, "last_name", data?.last_name)

        this.notify("success", "Prospect updated successfully!")
        setTimeout(() => {
          if (this.state.search == "") {
            this.socket.emit("getmessages", user.user.name, this.state.id)
          } else {
            this.socket.emit(
              "searchmessage",
              user.user.name,
              this.state.search,
              this.state.id
            )
          }
          this.setState({ isLoading: false })
        }, 1000)
      } else {
        this.notify("error", "Some error occurred, please try again later!")
        setTimeout(() => {
          if (this.state.search == "") {
            this.socket.emit("getmessages", user.user.name, this.state.id)
          } else {
            this.socket.emit(
              "searchmessage",
              user.user.name,
              this.state.search,
              this.state.id
            )
          }
          this.setState({ isLoading: false })
        }, 1000)
      }
    } catch (error) {
      this.notify("error", error.response.data.error)
      setTimeout(() => {
        if (this.state.search == "") {
          this.socket.emit("getmessages", user.user.name, this.state.id)
        } else {
          this.socket.emit(
            "searchmessage",
            user.user.name,
            this.state.search,
            this.state.id
          )
        }
        this.setState({ isLoading: false })
      }, 1000)
    }
  }

  openUpdateProspectModel(data) {
    this.setState({ editProspectModel: { ...data } })
  }

  toggleLoader() {
    this.setState({ isLoading: !this.state.isLoading })
  }

  refreshData(isLeadChanged, changedLead = null, selectedProspectId = null) {
    this.setState({
      isLeadChanged,
      changedLead,
      isLoading: true,
      selectedProspectId,
      search: changedLead ? changedLead : this.state.search,
    })
  }

  openDeleteProspectModel(data) {
    this.setState({
      confirmProspectDelete: {
        ...data,
        // index: this.state.selectedProspectIndex,
      },
      activeTab: null,
      convReloadKey: Math.random(),
    })
  }

  async leadChangeHandler(body) {
    try {
      const response = await axios.post(
        process.env.REACT_APP_PORT + "/api/prospect/existProspects/",
        body,
        this.config
      )
      if (response.data) {
        this.notify("success", "Lead stage updated successfully!")
        const selectedLead = this.props.leads.filter(
          item => item._id === body.value
        )
        if (selectedLead && selectedLead[0]) {
          this.setState({
            selected_lead: {
              label: selectedLead[0].lead_Stage,
              value: selectedLead[0]._id,
            },
            // selectedProspectId: body._id
          })
        }
        this.getProspects(body.value)
      }
    } catch (error) {
      this.notify("error", error?.response?.data?.message)
      this.setState({
        isLoading: false,
      })
    }
  }

  populateProspectLead(leadId) {
    let lead = this.props.leads.filter(item => item._id === leadId)
    return lead[0]?.lead_Stage || null
  }

  tog_drip(prospectId) {
    this.setState({
      drip_modal: !this.state.drip_modal,
      dripProspectId: prospectId,
    })
  }

  togRemoveDrip(dripProspectId, dripData, dripTemplateData) {
    this.setState({
      dripProspectId: dripProspectId,
      dripRemoveConfirm: {
        isOpen: true,
        dripData: dripData,
        dripTemplateData: dripTemplateData,
      },
    })
  }

  async getDripTemplates() {
    const authUser = localStorage.getItem("authUser")
    let token = false
    if (authUser) {
      let user = JSON.parse(authUser)
      token = user?.user?.token
    } else {
      token = false
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
    try {
      const response = await axios.get(
        process.env.REACT_APP_PORT + "/api/dripTemplate/pullDripTemplates",
        config
      )
      if (response?.data) {
        this.setState({
          dripTemplates: response?.data?.data,
        })
      }
    } catch (error) {
      this.notify("error", error?.response?.data?.message)
    }
  }
  async getDripTemplateDetail(id) {
    const authUser = localStorage.getItem("authUser")
    let token = false
    if (authUser) {
      let user = JSON.parse(authUser)
      token = user?.user?.token
    } else {
      token = false
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await axios.get(
      process.env.REACT_APP_PORT + "/api/dripTemplate/pullDripTemplate/" + id,
      config
    )
    if (response.data) {
      this.setState({
        // dripReloadKey: Math.random(),
        dripTemplateDetail: {
          isOpen: true,
          id: id,
          data: response.data.data,
        },
      })
    }
  }

  updateDripTemplateDetail(data) {
    this.setState({
      // dripReloadKey: Math.random(),
      dripTemplateDetail: {
        isOpen: true,
        id: id,
        data: response.data.data,
      },
    })
  }
  async addDrip(dripTemplateId = this.state.dripTemplateDetail.id) {
    this.setState({
      isLoading: true,
    })

    const body = {
      prospectId: this.state.dripProspectId,
      dripTemplateId,
    }
    try {
      const response = await axios.post(
        process.env.REACT_APP_PORT + "/api/dripTemplate/addDrip",
        body,
        this.config
      )
      if (response.data) {
        this.updateProspectObj(this.state.dripProspectId, "isDripAdded", true)
        this.tog_drip()
        // if (this.state.search == "") {
        //   this.socket.emit("getmessages", user.user.name, this.state.id)
        // } else {
        //   this.socket.emit(
        //     "searchmessage",
        //     user.user.name,
        //     this.state.search,
        //     this.state.id
        //   )
        // }
        this.setState({
          dripTemplateDetail: {
            isOpen: false,
            id: null,
            data: {},
          },
          dripProspectId: null,
          // reload: !this.state.reload,
          convReloadKey: Math.random(),
          isLoading: false,
        })
        this.notify("success", "Drip added successfully")

        // this.props.refreshData(true, e.target.value, this.props.data.prospect._id)
      }
    } catch (error) {
      this.notify("error", error?.response?.data?.message)
      this.setState({
        isLoading: false,
      })
    }
  }

  async removeDrip(prospectId) {
    this.setState({
      isLoading: true,
    })
    const body = {
      prospectId,
    }
    try {
      const response = await axios.post(
        process.env.REACT_APP_PORT + "/api/dripTemplate/removeDrip",
        body,
        this.config
      )
      if (response.data) {
        this.notify("success", "Drip removed successfully")
        this.updateProspectObj(prospectId, "isDripAdded", false)
        this.getProspectDataById(prospectId)

        // if (this.state.search == "") {
        //   this.socket.emit("getmessages", user.user.name, this.state.id)
        // } else {
        //   this.socket.emit(
        //     "searchmessage",
        //     user.user.name,
        //     this.state.search,
        //     this.state.id
        //   )
        // }
        this.setState({
          // reload: !this.state.reload,
          dripProspectId: null,
          dripRemoveConfirm: {
            isOpen: false,
          },
          convReloadKey: Math.random(),
          isLoading: false,
        })
      }
    } catch (error) {
      this.notify("error", error?.response?.data?.message)
      this.setState({
        isLoading: false,
      })
    }
  }

  updateProspectObj = (prospectId, key, value) => {
    const prospectIndex = this.state.contact.findIndex(
      item => item.prospect._id === prospectId
    )
    let prospectData = this.state.contact[prospectIndex]
    prospectData.prospect[key] = value
    const newProspects = [...this.state.contact]
    newProspects[prospectIndex] = prospectData
    this.setState({
      contact: newProspects,
      isLoading: false,
      convReloadKey: Math.random(),
    })
  }

  async verifiedNumber(id, currentVal) {
    this.setState({ isLoading: true })

    const body = {
      id: id,
      list: true,
    }

    try {
      const response = await axios.put(
        process.env.REACT_APP_PORT +
          `/api/prospect/${
            !currentVal ? "verifiednumber" : "removeverifiednumber"
          }/${id}`,
        body,
        this.config
      )
      if (response.data) {
        this.notify("success", "Prospect Updated Successfully")
        this.updateProspectObj(id, "verified", !currentVal)
        this.getProspectDataById(id)
        // this.setState({ isLoading: false })
      }
    } catch (error) {
      this.notify("error", error?.response?.data?.message)
      this.setState({ isLoading: false })
    }
  }

  setDNC = async (id, dnc) => {
    this.setState({ isLoading: true })

    const body = {
      dnc: dnc,
      id: id,
      list: true,
    }

    try {
      const response = await axios.put(
        process.env.REACT_APP_PORT + `/api/prospect/prospectDNC/${id}`,
        body,
        this.config
      )
      if (response.data) {
        this.notify("success", "Prospect Updated Successfully")
        this.updateProspectObj(id, "dnc", dnc)
        this.getProspectDataById(id)
      }
    } catch (error) {
      this.notify("error", error?.response?.data?.message)
      this.setState({ isLoading: false })
    }
  }

  wrongNumber = async (id, wrongNumber) => {
    // setIsLoading(true)
    this.setState({ isLoading: true })
    const body = {
      id: id,
      list: true,
    }
    try {
      const response = await axios.put(
        process.env.REACT_APP_PORT +
          `/api/prospect/${
            !wrongNumber ? "wrongnumber" : "removewrongnumber"
          }/${id}`,
        body,
        this.config
      )
      if (response.data) {
        this.notify("success", "Prospect Updated Successfully")
        this.updateProspectObj(id, "Wrong_Number", !wrongNumber)
        this.getProspectDataById(id)
      }
    } catch (error) {
      // setIsLoading(false)
      this.notify("error", error?.response?.data?.message)
      this.setState({ isLoading: false })
    }
  }

  qualifiedNumber = async (id, qualifiedNumber) => {
    // setIsLoading(true)
    this.setState({ isLoading: true })

    const body = {
      id: id,
      list: true,
    }
    try {
      const response = await axios.put(
        process.env.REACT_APP_PORT +
          `/api/prospect/${
            !qualifiedNumber ? "isqualified" : "wasqualified"
          }/${id}`,
        body,
        this.config
      )
      if (response.data) {
        this.notify("success", "Prospect Updated Successfully")
        this.updateProspectObj(id, "isLead", !qualifiedNumber)
        this.getProspectDataById(id)
      }
    } catch (error) {
      // setIsLoading(false)
      this.notify("error", error?.response?.data?.message)
      this.setState({ isLoading: false })
    }
  }

  priorityNumber = async (id, priorityNumber) => {
    // setIsLoading(true)
    this.setState({ isLoading: true })

    const body = {
      id: id,
      list: true,
    }

    try {
      const response = await axios.put(
        process.env.REACT_APP_PORT +
          `/api/prospect/${
            !priorityNumber ? "isPriority" : "wasPriority"
          }/${id}`,
        body,
        this.config
      )
      if (response.data) {
        this.notify("success", "Prospect Updated Successfully")
        this.setState({ isLoading: false })
        this.updateProspectObj(id, "isPriority", !priorityNumber)
        this.getProspectDataById(id)
      }
    } catch (error) {
      // setIsLoading(false)
      this.notify("error", error?.response?.data?.message)
      this.setState({ isLoading: false })
    }
  }

  getProspectDataById = async id => {
    this.setState({ prospectDataIsLoading: true })
    try {
      const response = await axios.get(
        process.env.REACT_APP_PORT + `/api/conversation/getProspectData/${id}`,
        this.config
      )
      if (response) {
        this.setState({
          prospectDataIsLoading: false,
          prospectData: response.data,
        })
      }
    } catch (error) {
      this.setState({ prospectDataIsLoading: false })
      this.notify("error", error.response?.data?.message)
    }
  }

  render() {
    return (
      <React.Fragment>
        <Toaster />

        {this.state.confirmProspectDelete.show && (
          <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmBtnText="Yes, remove it"
            confirmBtnBsStyle="primary"
            cancelBtnBsStyle="dark"
            onConfirm={() => {
              // this.setState({
              //   confirmProspectDelete: { show: false },

              // })
              this.handleDeleteProspect({
                id: [this.state.confirmProspectDelete.id],
              })
              // this.props.deleteProspects({
              //   id: this.state.confirmProspectDelete.id ,
              // })
            }}
            onCancel={() => {
              this.setState({ confirmProspectDelete: { show: false } })
            }}
          >
            You want to delete{" "}
            <strong>
              {" "}
              {this.state.confirmProspectDelete.first_name +
                " " +
                this.state.confirmProspectDelete.last_name}
            </strong>
            , <br /> You won't be able to revert this!
          </SweetAlert>
        )}

        {/* edit modal */}
        {/* <Modal
          isOpen={this.state.editProspectModel.show}
          toggle={() => {
            this.tog_center()
          }}
          backdrop="static"
          centered={true}
        >
          <AvForm
            className="needs-validation"
            onValidSubmit={(e, v) => this.handleUpdateProspect(e, v)}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0">Edit Prospect</h5>
              <button
                type="button"
                onClick={() => {
                  this.setState({
                    editProspectModel: { show: false },
                  })
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Row>
                <Col>
                  <FormGroup className="mb-3">
                    <Label htmlFor="validationCustom01">First Name</Label>
                    <AvField
                      name="first_name"
                      placeholder="First Name"
                      type="text"
                      errorMessage="Enter First Name"
                      className="form-control"
                      validate={{
                        required: { value: true },
                        pattern: {
                          value: "^[A-Za-z0-9 ]+$",
                          errorMessage: "Enter valid first name",
                        },
                      }}
                      id="validationCustom01"
                      defaultValue={this.state.editProspectModel.first_name}
                    />
                    <Label htmlFor="validationCustom01">Last Name</Label>
                    <AvField
                      name="last_name"
                      placeholder="Last Name"
                      type="text"
                      errorMessage="Enter Last Name"
                      className="form-control"
                      validate={{
                        required: { value: true },
                        pattern: {
                          value: "^[A-Za-z0-9 ]+$",
                          errorMessage: "Enter valid last name",
                        },
                      }}
                      id="validationCustom01"
                      defaultValue={this.state.editProspectModel.last_name}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row className={"text-end"}>
                <Col>
                  <button
                    type="button"
                    className="btn btn-dark me-2"
                    onClick={() => {
                      this.setState({
                        editProspectModel: { show: false },
                      })
                    }}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Update
                  </button>
                </Col>
              </Row>
            </div>
          </AvForm>
        </Modal> */}

        {/* // */}

        <Modal
          isOpen={this.state.editProspectModel.show}
          toggle={() => {
            this.tog_center()
          }}
          className="c-modal"
          // backdrop="static"
          centered={true}
        >
          <div className="modal-body ">
            <h5 className="modal-title mt-0 mb-3">Edit Prospect</h5>
            <button
              type="button"
              onClick={() => {
                this.setState({
                  editProspectModel: { show: false },
                })
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <img src={close} alt="" />
            </button>

            {
              <AvForm
                onValidSubmit={(e, v) => this.handleUpdateProspect(e, v)}
                className="needs-validation"
              >
                <Row>
                  <Col xl="12">
                    <FormGroup className="mb-3">
                      <Label htmlFor="validationCustom01">First Name</Label>
                      <AvField
                        name="first_name"
                        placeholder="First Name"
                        type="text"
                        errorMessage="Enter First Name"
                        className="form-control cc-form-control"
                        validate={{
                          required: { value: true },
                          pattern: {
                            value: "^[A-Za-z0-9 ]+$",
                            errorMessage: "Enter valid first name",
                          },
                        }}
                        id="validationCustom01"
                        defaultValue={this.state.editProspectModel.first_name}
                      />
                    </FormGroup>

                    {/* <Label htmlFor="validationCustom01">First Name</Label> */}
                  </Col>
                </Row>
                <Row>
                  <Col xl="12">
                    <FormGroup className="mb-3">
                      <Label htmlFor="validationCustom01">Last Name</Label>
                      <AvField
                        name="last_name"
                        placeholder="Last Name"
                        type="text"
                        errorMessage="Enter Last Name"
                        className="form-control cc-form-control"
                        validate={{
                          required: { value: true },
                          pattern: {
                            value: "^[A-Za-z0-9 ]+$",
                            errorMessage: "Enter valid last name",
                          },
                        }}
                        id="validationCustom01"
                        defaultValue={this.state.editProspectModel.last_name}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <div className="text-end py-2 d-flex justify-content-end align-items-center gap-2">
                  <Button
                    className="main-btn secondary-btn"
                    onClick={() => {
                      this.setState({
                        editProspectModel: { show: false },
                      })
                    }}
                    role="button"
                  >
                    <span>Cancel</span>
                  </Button>
                  <Button type="submit" color="primary" className="main-btn">
                    <span>Update</span>
                  </Button>{" "}
                </div>
              </AvForm>
            }
          </div>
        </Modal>

        <div className="page-content">
          <MetaTags>
            <title>Campaign | TextLaunchPad.com </title>
          </MetaTags>
          <div className="container-fluid">
            <CampaignHeader
              campaign={this.props.campaign}
              leadStages={this.props.leads}
              campaignFilterProspects={this.props.campaignFilterProspects}
            />

            <Row className={"pt-2"}>
              <Col lg={12}>
                {this.props.apiError &&
                typeof this.props.apiError === "string" ? (
                  <Alert color="danger">{this.props.apiError}</Alert>
                ) : null}

                {this.props.apiError &&
                typeof this.props.apiError === "object" ? (
                  // this.props.apiError.response.data.error
                  this.props.apiError.response ? (
                    <Alert color="danger">
                      {"Internal Communication occurred"}
                    </Alert>
                  ) : (
                    // this.props.apiError.message
                    <Alert color="danger">
                      {"Internal Communication occurred"}
                    </Alert>
                  )
                ) : null}

                {/* {this.props.successMsg ? (
                  <Alert color="success">{this.props.successMsg}</Alert>
                ) : null} */}
              </Col>
            </Row>
            <div className="c-card s-nav">
              <Row>
                <Col lg={12} className={""}>
                  <Nav tabs className="nav-tabs-custom cc-tabs">
                    <NavItem>
                      <Link
                        className={"nav-link "}
                        to={"/campaign/" + this.state.id}
                      >
                        <span className="d-sm-block">Metrics</span>
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link
                        className={"nav-link"}
                        to={"/campaign/" + this.state.id + "/send"}
                      >
                        <span className="d-sm-block">Send</span>
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link
                        className={"nav-link "}
                        to={"/campaign/" + this.state.id + "/upload_history"}
                      >
                        <span className="d-sm-block">Upload History</span>
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link
                        className={"nav-link active"}
                        to={
                          "/campaign/" +
                          this.state.id +
                          "/prospects_messages_notes"
                        }
                      >
                        <span className="d-sm-block">Prospects & Messages</span>
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link
                        className={"nav-link "}
                        to={"/campaign/" + this.state.id + "/scheduler"}
                      >
                        <span className="d-sm-block">Scheduler</span>
                      </Link>
                    </NavItem>
                  </Nav>
                </Col>
              </Row>
            </div>
            <div className="c-card mt-3" style={{ overflow: "hidden" }}>
              {this.state.isLoading && (
                <div className="spinner-wr">
                  <Spinner style={{ color: "#5932ea" }} />
                </div>
              )}
              <Row className={""}>
                <Col lg={5} md={5} sm={12}>
                  <AvForm className="needs-validation chat-selection-main-wrap">
                    <div className="d-flex align-items-center justify-content-between">
                      <h3 className="heading">Select Lead Stage</h3>
                      <button
                        onClick={() => {
                          this.setState({ isLoading: true })
                          this.props.exportCampProsData({
                            data: this.state.search,
                            id: this.state.id,
                          })
                        }}
                        className="main-btn dark sm"
                      >
                        <img src={exportIcon} alt="" />
                        <span>Export</span>
                      </button>
                    </div>
                    <FormGroup
                      className={"mb-3 mt-2 form-group select-r"}
                      onClick={() => {}}
                    >
                      {this.state.contact && (
                        <Select
                          validate={{
                            required: true,
                          }}
                          placeholder={"Select Lead Stage"}
                          onChange={lead => {
                            this.setState({
                              selected_lead: lead,
                              selectedProspectIndex: null,
                              message: null,
                            })
                            this.onChange(lead)
                          }}
                          value={this.state.selected_lead}
                          name="category"
                          options={this.getLeadOptions()}
                          classNamePrefix="select2-selection"
                        />
                      )}
                    </FormGroup>
                    <InfiniteScroll
                      dataLength={this.state.contact.length}
                      next={this.handleScroll}
                      hasMore={
                        this.state.totalProspectsCount !=
                        this.state.contact.length
                      }
                      loader={<h4>Loading...</h4>}
                      height={400}
                      endMessage={
                        <p style={{ textAlign: "center" }}>
                          {this.state.totalProspectsCount ? (
                            <b>Yay! You have seen it all</b>
                          ) : null}
                        </p>
                      }
                      className="contact-lists cus-scroll"
                    >
                      {/* <div
                        className={
                          this.state.contact.length > 0
                            ? "contact-lists cus-scroll "
                            : ""
                        }
                      > */}
                      {this.state.contact.length > 0 ? (
                        <>
                          {this.state.contact.map((item, index) => {
                            // this.setState({
                            //     contactLength:this.state.contact.length
                            // })
                            return (
                              // <Card
                              //
                              //   className={"mb-0 mt-4 shadow-none"}
                              // >
                              //   <CardHeader>
                              <div
                                key={item._id}
                                onClick={() => {
                                  this.getReminder(item?.prospect?._id)

                                  this.setState({
                                    message: item,
                                    selectedProspectId: item?._id,
                                    activeTab: null,
                                    activeConvIndex: 0,
                                    // selectedProspectIndex: index,
                                  })
                                }}
                                className={`p-mes-wrap ${
                                  this.state.selectedProspectId === item?._id
                                    ? "active"
                                    : ""
                                }`}
                              >
                                <div className="top-area">
                                  <h3>
                                    {item.prospect?.first_name}{" "}
                                    {item.prospect?.last_name}
                                  </h3>
                                  <div className="r-part">
                                    <span>
                                      {/* {this.state?.selected_lead?.label} */}
                                      {this.populateProspectLead(
                                        item.prospect?.lead
                                      )}
                                    </span>

                                    <ul>
                                      <li>
                                        {item.prospect?.dnc ? (
                                          <img src={phoneCutActive} alt="" />
                                        ) : (
                                          <img src={phoneCut} alt="" />
                                        )}
                                      </li>
                                      <li>
                                        {item.prospect.verified ? (
                                          <img src={checkIconActive} alt="" />
                                        ) : (
                                          <img src={checkIcon} alt="" />
                                        )}
                                      </li>
                                      <li>
                                        {item.prospect.Wrong_Number ? (
                                          <img src={crossIconActive} alt="" />
                                        ) : (
                                          <img src={crossIcon} alt="" />
                                        )}
                                      </li>
                                      <li>
                                        {item.prospect.isLead ? (
                                          <img src={starIconActive} alt="" />
                                        ) : (
                                          <img src={starIcon} alt="" />
                                        )}
                                      </li>
                                      <li>
                                        {item.prospect.isPriority ? (
                                          <img src={boltIconActive} alt="" />
                                        ) : (
                                          <img src={boltIcon} alt="" />
                                        )}
                                      </li>
                                      <li>
                                        {item.prospect.isDripAdded ? (
                                          <img src={dripIconActive} alt="" />
                                        ) : (
                                          <img src={dripIcon} alt="" />
                                        )}
                                      </li>
                                    </ul>
                                    <span>
                                      {moment
                                        .utc(item.prospect.lastsmssend)
                                        .local()
                                        .format("MM-DD-YYYY | hh-mm A")}
                                    </span>
                                    <button className="has-drop">
                                      <img src={threeDot} alt="" />
                                      <div className="drop-wrapper max-h-drop">
                                        <ul>
                                          <li>
                                            <a
                                              onClick={() =>
                                                this.setState({
                                                  activeTab: null,
                                                  convReloadKey: Math.random(),
                                                  editProspectModel: {
                                                    show: true,
                                                    first_name:
                                                      item.prospect.first_name,
                                                    last_name:
                                                      item.prospect.last_name,
                                                    prospectId:
                                                      item.prospect._id,
                                                  },
                                                })
                                              }
                                            >
                                              <span>Edit</span>
                                            </a>
                                          </li>
                                          <li
                                            onClick={() => {
                                              this.openDeleteProspectModel({
                                                show: true,
                                                id: item?.prospect?._id,
                                                first_name:
                                                  item.prospect?.first_name,
                                                last_name:
                                                  item.prospect?.last_name,
                                              })
                                              // this.setState({
                                              //   confirmProspectDelete: {
                                              //     show: true,
                                              //     index,
                                              //   },
                                              // })
                                            }}
                                          >
                                            <a>
                                              <span>Delete</span>
                                            </a>
                                          </li>
                                          <li
                                            onClick={e => {
                                              e.stopPropagation()
                                              this.setState({
                                                activeTab: 2,
                                                convReloadKey: Math.random(),
                                                message: item,
                                                selectedProspectId: item?._id,
                                                activeConvIndex: 0,
                                              })
                                              this.getReminder(
                                                item?.prospect?._id
                                              )
                                            }}
                                          >
                                            <a>
                                              <span>Notes</span>
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </button>
                                  </div>
                                </div>

                                <p className="msg">
                                  {item?.conversations?.length > 0 &&
                                  item?.conversations[0]?.messages?.length > 0
                                    ? item?.conversations[0]?.messages[
                                        item?.conversations[0]?.messages
                                          .length - 1
                                      ].body
                                    : null}
                                </p>
                              </div>
                              //   </CardHeader>
                              // </Card>
                            )
                          })}
                          {/* <span className="d-flex justify-content-center">
                          <button className="main-btn" type="button">
                            <span> Load more</span>
                          </button>
                        </span> */}
                        </>
                      ) : !this.state.isLoading ? (
                        <span className="d-flex justify-content-center">
                          There are no records to display
                        </span>
                      ) : (
                        ""
                      )}
                      {/* </div> */}
                    </InfiniteScroll>
                  </AvForm>
                </Col>
                <Col lg={7} md={7} sm={12}>
                  {this.state.message ? (
                    <Conversations
                      key={this.state.convReloadKey}
                      sendText={this.state.sendText}
                      config={this.config}
                      id={this.state.id}
                      data={this.state.message}
                      leads={this.props.leads}
                      tagsData={this.state.tagsData}
                      updateSendText={this.updateSendText}
                      resetMessage={this.resetMessage}
                      getQuickReplies={this.props.getQuickReplies}
                      sendConversation={this.props.sendConversation}
                      leadChangeHandler={this.leadChangeHandler}
                      quickReplies={this.props.quickReplies}
                      updateProspectDNS={this.props.updateProspectDNS}
                      updateProspectVerified={this.props.updateProspectVerified}
                      removeProspectVerified={this.props.removeProspectVerified}
                      setActiveConvIndex={this.setActiveConvIndex}
                      activeConvIndex={this.state.activeConvIndex}
                      createNotes={this.props.createNotes}
                      updateProspectWrongNumber={
                        this.props.updateProspectWrongNumber
                      }
                      removeProspectWrongNumber={
                        this.props.removeProspectWrongNumber
                      }
                      updateProspectQualified={
                        this.props.updateProspectQualified
                      }
                      removeProspectQualified={
                        this.props.removeProspectQualified
                      }
                      updateProspectPriority={this.props.updateProspectPriority}
                      removeProspectPriority={this.props.removeProspectPriority}
                      updateProspectHandler={this.openUpdateProspectModel}
                      deleteProspectHandler={this.openDeleteProspectModel}
                      socket={this.socket}
                      activeTab={this.state.activeTab}
                      toggleLoader={this.toggleLoader}
                      refreshData={this.refreshData}
                      updateProspectTags={this.updateProspectTags}
                      updateListByLead={this.updateListByLead}
                      reminder={this.state.reminder}
                      createReminder={this.handleCreateReminder}
                      deleteReminder={this.handleDeleteReminder}
                      campaignData={this.props.campaign}
                      tog_drip={this.tog_drip}
                      togRemoveDrip={this.togRemoveDrip}
                      priorityNumber={this.priorityNumber}
                      qualifiedNumber={this.qualifiedNumber}
                      wrongNumber={this.wrongNumber}
                      setDNC={this.setDNC}
                      verifiedNumber={this.verifiedNumber}
                      getProspectDataById={this.getProspectDataById}
                      prospectDataIsLoading={this.state.prospectDataIsLoading}
                      prospectData={this.state.prospectData}
                    ></Conversations>
                  ) : (
                    <div className="chat-illus illus-empty-state">
                      <img src={illus} alt="" />
                      <h3>Select Prospect</h3>
                      <p>Select prospect for detail view!</p>
                    </div>
                  )}
                </Col>
              </Row>
            </div>

            {this.state.dripRemoveConfirm?.isOpen ? (
              <SweetAlert
                title="Are you sure?"
                info
                showCancel
                confirmBtnText="Yes, remove it"
                confirmBtnBsStyle="primary"
                cancelBtnBsStyle="dark"
                onConfirm={() => {
                  this.removeDrip(this.state.dripProspectId)
                }}
                onCancel={() => {
                  this.setState({
                    dripRemoveConfirm: { isOpen: false },
                  })
                }}
              >
                <div className="d-flex flex-column align-items-start gap-1 p-2 border-bottom mb-2">
                  {/* <div>
                    <strong>Drip Name: </strong>{" "}
                    {this?.state?.dripRemoveConfirm?.dripTemplateData?.name}{" "}
                  </div> */}
                  <div>
                    <strong>Drip Start Date: </strong>{" "}
                    {moment
                      .utc(
                        this?.state?.dripRemoveConfirm?.dripData?.dripStartDate
                      )
                      .local()
                      .format("YYYY-MM-DD hh:mm:ss A")}
                  </div>
                </div>
                You want to remove drip? You won't be able to revert this!
              </SweetAlert>
            ) : null}

            <Modal
              isOpen={this.state.drip_modal}
              toggle={() => {
                this.tog_drip()
              }}
              backdrop="static"
              centered={true}
              className="drip-select-modal"
              key={this.state.dripReloadKey}
            >
              <div className="modal-header">
                <h5 className="modal-title mt-0">Select A Drip Automations</h5>
                <button
                  type="button"
                  onClick={() => {
                    this.setState({
                      drip_modal: false,
                      dripTemplateDetail: {
                        isOpen: false,
                        id: null,
                        data: {},
                      },
                    })
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="drip-select-container">
                  <div key={this.state.dripReloadKey}>
                    {!this.state?.dripTemplateDetail?.isOpen ? (
                      <div className="drip-aut-list">
                        <table className="c-table table">
                          <thead>
                            <tr>
                              <th>Drip Name</th>
                              <th></th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.dripTemplates &&
                              this.state.dripTemplates.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{item.name}</td>
                                    <td>
                                      <button
                                        className="main-btn sm secondary-btn min-btn"
                                        onClick={() => {
                                          this.getDripTemplateDetail(item._id)
                                        }}
                                      >
                                        <span>View Messages</span>
                                      </button>
                                    </td>
                                    <td>
                                      <button
                                        className="main-btn sm white-icon min-btn"
                                        onClick={() => {
                                          this.addDrip(item._id)
                                        }}
                                      >
                                        <img src={dripIconBorder} alt="" />
                                        <span>Select</span>
                                      </button>
                                    </td>
                                  </tr>
                                )
                              })}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div className="drip-messages-container">
                        <h3 className="head">
                          {this.state.dripTemplateDetail?.data?.name}
                        </h3>
                        <div className="drip-message-main-container">
                          {this.state.dripTemplateDetail?.data?.data.map(
                            (item, index) => {
                              return (
                                <div className="drip-message-wrap" key={index}>
                                  <div className="top">
                                    <h3 className="head">
                                      Message {index + 1}
                                    </h3>
                                    <span>Day {item.day}</span>
                                  </div>
                                  <p className="para">{item.message}</p>
                                </div>
                              )
                            }
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  {/* drip automations END*** */}

                  {/* drip automation messages */}

                  {/* drip automation messages END*** */}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={() => {
                    if (this.state.dripTemplateDetail?.isOpen) {
                      this.setState({
                        dripTemplateDetail: {
                          isOpen: false,
                          id: null,
                          data: {},
                        },
                      })
                    } else {
                      this.setState({
                        drip_modal: false,
                        dripTemplateDetail: {
                          isOpen: false,
                          id: null,
                          data: {},
                        },
                      })
                    }
                  }}
                >
                  {this.state.dripTemplateDetail?.isOpen ? "Back" : "Cancel"}
                </button>
                {this.state.dripTemplateDetail?.isOpen ? (
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={() => {
                      this.addDrip()
                    }}
                  >
                    Select
                  </button>
                ) : null}
              </div>
            </Modal>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
CampaignProspectsMessagesNotes.propTypes = {
  apiError: PropTypes.any,
  successMsg: PropTypes.any,
  campaign: PropTypes.object,
  leads: PropTypes.array,
  quickReplies: PropTypes.array,
  getCampaign: PropTypes.func,
  getLeadStages: PropTypes.func,
  exportCampProsData: PropTypes.func,
  getQuickReplies: PropTypes.func,
  sendConversation: PropTypes.func,
  existsProspect: PropTypes.func,

  updateProspectDNS: PropTypes.func,
  updateProspectVerified: PropTypes.func,
  removeProspectVerified: PropTypes.func,
  updateProspectWrongNumber: PropTypes.func,
  removeProspectWrongNumber: PropTypes.func,
  updateProspectQualified: PropTypes.func,
  removeProspectQualified: PropTypes.func,
  updateProspectPriority: PropTypes.func,
  removeProspectPriority: PropTypes.func,
  createNotes: PropTypes.func,
  deleteProspects: PropTypes.func,
}

const mapStatetoProps = state => {
  const {
    apiError,
    campaign,
    successMsg,
    getCampaign,
    leads,
    getLeadStages,
    exportCampProsData,
    quickReplies,
    getQuickReplies,
    sendConversation,
    existProspects,
    updateProspectDNS,
    updateProspectVerified,
    removeProspectVerified,
    updateProspectWrongNumber,
    removeProspectWrongNumber,
    updateProspectQualified,
    removeProspectQualified,
    updateProspectPriority,
    removeProspectPriority,
    createNotes,
    deleteProspects,
  } = state.Campaign
  return {
    apiError,
    campaign,
    successMsg,
    getCampaign,
    leads,
    getLeadStages,
    exportCampProsData,
    quickReplies,
    getQuickReplies,
    sendConversation,
    existsProspect,
    updateProspectDNS,
    updateProspectVerified,
    removeProspectVerified,
    updateProspectWrongNumber,
    removeProspectWrongNumber,
    updateProspectQualified,
    removeProspectQualified,
    updateProspectPriority,
    removeProspectPriority,
    createNotes,
    deleteProspects,
  }
}

export default withRouter(
  connect(mapStatetoProps, {
    getCampaign,
    getLeadStages,
    exportCampProsData,
    getQuickReplies,
    sendConversation,
    existsProspect,
    updateProspectDNS,
    updateProspectVerified,
    removeProspectVerified,
    updateProspectWrongNumber,
    removeProspectWrongNumber,
    updateProspectQualified,
    removeProspectQualified,
    updateProspectPriority,
    removeProspectPriority,
    createNotes,
    deleteProspects,
  })(CampaignProspectsMessagesNotes)
)

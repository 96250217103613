import React, { useEffect, useState } from "react"
import {
  Container,
  BreadcrumbItem,
  Row,
  Col,
  Button,
  Input,
  Label,
  TabContent,
  NavItem,
  NavLink,
  TabPane,
  Card,
  CardBody,
  FormGroup,
  Tooltip,
  Popover,
  PopoverHeader,
  PopoverBody,
  Alert,
  Spinner,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  ButtonToggle,
} from "reactstrap"
import MetaTags from "react-meta-tags"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import axios from "axios"
import toast, { Toaster } from "react-hot-toast"
import plus from "../../assets/images/white-plus.svg"
import plusPurple from "../../assets/images/plus-purple.svg"
import NotesIcon from "../../assets/images/ProspectNotes.svg"
import dripIconBorder from "../../assets/images/drip-icon-border.svg"
import dripIconFilled from "../../assets/images/drip-icon-filled.svg"
import SweetAlert from "react-bootstrap-sweetalert"
import moment from "moment"
import Select from "react-select"
import debounce from "lodash.debounce"
import dropDownArrow from "../../assets/images/drop-down.svg"
import DripVaccent from "../../assets/images/DripVaccent.svg"
import SimpleBar from "simplebar-react"
import { Link, Redirect, withRouter, useHistory } from "react-router-dom"
import { AvField, AvForm } from "availity-reactstrap-validation"
import astrixIcon from "../../assets/images/astrix.svg"
import sendIcon from "../../assets/images/send.svg"
import threeDot from "../../assets/images/threedotverticle.svg"
import illus from "../../assets/images/chat-illustration.svg"
import plusIcon from "../../assets/images/plus-icon.svg"
import sidebarCloseIcon from "../../assets/images/sidebar-close.svg"
import notesIcon from "../../assets/images/notes.svg"
import DeleteProspect from "../../assets/images/DeleteProspect.svg"
import EditProspect from "../../assets/images/EditProspect.svg"
import activityIcon from "../../assets/images/activity.svg"
import detailsIcon from "../../assets/images/details.svg"
import io from "socket.io-client"
import { formValueSelector } from "redux-form"
import calendar from "../../assets/images/calendar.svg"
import purpleCalendar from "../../assets/images/calendar-purple.svg"
import arrowRight from "../../assets/images/arrowRight.svg"
import arrowLeft from "../../assets/images/arrowLeft.svg"
import dripActivity from "../../assets/images/dripActivity.svg"
import dncActivity from "../../assets/images/dncActivity.svg"
import wrongNumberActivity from "../../assets/images/wrongNumber.svg"
import leadStageActivity from "../../assets/images/leadStageActitvity.svg"
import priorityActivity from "../../assets/images/priorityActivity.svg"
import followUpActivity from "../../assets/images/followUpActivity.svg"
import verifiedActivity from "../../assets/images/verifiedActivity.svg"
import qualifiedActivity from "../../assets/images/qualifiedActivity.svg"
import tagsActivity from "../../assets/images/tagsActivity.svg"
import DataTable from "react-data-table-component"
import close from "../../assets/images/close.svg"
import chatLoading from "../../assets/images/chatLoading.svg"
import Exclamation from "../../assets/images/triangleExclamation.svg"
import Switch from "react-switch"

const DetailProspect = () => {
  // getting Prospect Id and Index
  const pathArray = window.location.pathname.split("/")
  const ProspectId = pathArray.pop()
  const queryParams = new URLSearchParams(window.location.search)
  const ProspectIndex = +queryParams.get("sno")
  const totalProspects = +queryParams.get("total")
  const prospectIds = queryParams.get("prospectIds")?.split(",")

  let filterData = {}
  for (var value of queryParams.keys()) {
    filterData[value] = queryParams.get(value)
  }

  // Notify for notification
  const notify = (type, text) => {
    switch (type) {
      case "success":
        toast.success(text)
        break
      case "error":
        if (typeof text !== "string") {
          if (text?.message && typeof text?.message === "string") {
            toast.error(text?.message)
          } else {
            toast.error("An unexpected error occurred")
          }
        } else {
          toast.error(text)
        }
        break

      default:
        break
    }
  }

  let authUser = localStorage.getItem("authUser")
  let token = false
  let user
  if (authUser) {
    user = JSON.parse(authUser)
    token = user?.user?.token
  } else {
    token = false
  }
  //config header for axios
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }

  const [isLoading, setIsLoading] = useState(false) // for loading and spinner
  const [masterData, setMasterData] = useState({}) // master data for options and other
  const [confirmAction, setConfirmAction] = useState({ isOpen: false })
  const [prospectData, setProspectData] = useState({ prospectId: ProspectId }) // prospect data
  const [sidebar, setSideBar] = useState(false) // for sidebar opening and closing
  const [activeTab, setActiveTab] = useState(1) // for sidebar active tab like notes and activity
  const [noteMsg, setNoteMsg] = useState() // for accepting notes message from textarea
  const [addDripModal, setAddDripModal] = useState(false) // for opening drip model for add drip
  const [dripReloadKey, setDripReloadKey] = useState(() => Math.random()) // for drip modal reload key
  const [dripRemoveConfirm, setDripRemoveConfirm] = useState({ isOpen: false }) // for opening sweet alert for removing drip
  const [podioLoding, setPodioLoding] = useState(false) // for podio loading
  const [count, setCount] = useState(60)
  const [reminderModal, setReminderModal] = useState({ isOpen: false }) // for opening add reminder modal
  const [editProspectModal, setEditProspectModal] = useState({ isOpen: false }) // for opening prospect edit modal
  const [deleteProspect, setDeleteProspect] = useState({ isOpen: false }) // for opening delete prospect delete modal
  const [message, setMessage] = useState({}) // for message of the prospect
  const [send, setSend] = useState() // fro message send in conversation
  const [editNoteModal, setEditNoteModal] = useState({ isOpen: false })
  const [selectedTags, setSelectedTags] = useState([]) // for selected tag value
  const [currentProspectId, setCurrentProspectId] = useState(ProspectId) // for getting current prospect Id
  const [sendReloadKey, setSendReloadKey] = useState(Math.random()) // for getting current prospect Id
  const [isSending, setIsSending] = useState(false) // for getting current prospect Id
  const [convIndex, setConvIndex] = useState(0) // for getting current prospect Id
  const [callCheckNumApi, setCallCheckNumApi] = useState(true) // for getting current prospect Id
  const [shouldRedirect, setShouldRedirect] = useState({
    // for page redirect when prospect deleted
    state: false,
    path: "",
  })
  const [popoverOpen, setPopoverOpen] = useState({ isOpen: false })
  const [prospectReloadKey, setProspectReloadKeyopoverOpen] = useState(
    Math.random()
  )
  const [currentProspectIndex, setCurrentProspectIndex] =
    useState(ProspectIndex) // for setting current prospect
  const [dripTemplateDetail, setDripTemplateDetail] = useState({
    // for setting drip template details
    isOpen: false,
    id: null,
    data: {},
  })
  const [ConversionIsLoading, setConversationIsLoading] = useState(false)
  const [checkedNumber, setCheckedNumber] = useState([])
  const [checkNumberLoading, setCheckNumberLoading] = useState(false)
  const [anotherActiveNumber, setAnotherActiveNumber] = useState(null)
  const [createNewConv, setCreateNewConv] = useState(false)

  function search(arr, targetString) {
    return arr.some(
      str => targetString.trim().toLowerCase() === str.toLowerCase()
    )
  }

  useEffect(() => {
    const socket = io(process?.env.REACT_APP_SOCKET_URL, {
      // process?.env.REACT_APP_PORT
      // "http://192.168.29.95:5001"
      //"http://192.168.29.95:5001"
      query: {
        email: user?.user?.name?.toString(),
      },
    })
    socket.on("newMessage", data => {
      try {
        const updateLitigator =
          search(["stop", "end"], data?.body) ||
          search(["start", "begin"], data?.body)
        if (updateLitigator) {
          getProspectById()
        }

        setProspectData(prevProspectData => {
          const convExist = prevProspectData?.conversations?.findIndex(
            conv => conv._id === data.convId
          )
          if (convExist !== -1) {
            const conversation = prevProspectData?.conversations?.[convExist]
            if (conversation) {
              if (conversation?.messages && conversation?.messages?.length) {
                conversation?.messages?.push(data)
              } else {
                conversation["messages"] = [data]
              }

              conversation.unreadCount = conversation.unreadCount
                ? conversation.unreadCount + 1
                : 1
              const newConversations = [...prevProspectData?.conversations]
              newConversations[convExist] = conversation
              return {
                ...prevProspectData,
                conversations: newConversations,
              }
            }
          }
          return prevProspectData // Return unchanged state if conversation not found
        })
      } catch (error) {
        console.log(error, "error")
      }
    })
  }, [])
  useEffect(() => {
    getMasterData()
    getProspectById()
    // setConversationIsLoading(true)
  }, [])

  useEffect(() => {
    let authUser = localStorage.getItem("authUser")
    let token = false
    let user
    if (authUser) {
      user = JSON.parse(authUser)
      token = user?.user?.token
    } else {
      token = false
    }

    if (prospectData?.conversations?.[convIndex]?.systemNumber) {
      const systemNumber = prospectData?.conversations?.map(
        item => item.systemNumber
      )
      if (callCheckNumApi) {
        checkSystemNumber(systemNumber)
      }
    }

    // socket.emit("getmessagebypros", user.user.name, currentProspectId)
  }, [prospectData])

  function getConvNumber(type, data = message) {
    if (type == "from") {
      return (
        data?.number ||
        (data?.messages?.[0]?.direction == "outbound-api"
          ? data?.messages?.[0]?.from
          : data?.messages?.[0]?.to)
      )
    } else if (type == "to") {
      return data?.sender || data?.messages?.[0]?.direction == "outbound-api"
        ? data?.messages?.[0]?.to
        : data?.messages?.[0]?.from
    }
    return null
  }
  // useEffect(() => {
  //   let authUser = localStorage.getItem("authUser")
  //   let token = false
  //   let user
  //   if (authUser) {
  //     user = JSON.parse(authUser)
  //     token = user?.user?.token
  //   } else {
  //     token = false
  //   }

  //   const socket = io(process?.env.REACT_APP_PORT, {
  //     // process?.env.REACT_APP_PORT
  //     // "http://192.168.29.95:5001"
  //     query: {
  //       email: user?.user?.name,
  //     },
  //   })
  // }, [])

  const changeStatus = async () => {
    setIsLoading(true)
    try {
      const updatedConversation = await axios.post(
        process.env.REACT_APP_PORT + "/api/conversation/markAsRead",
        { convId: prospectData.conversations?.[convIndex]?._id },
        config
      )
      if (updatedConversation?.data) {
        const newConversations = [...prospectData.conversations]
        newConversations[convIndex].unreadCount = 0
        let newProspectData = { ...prospectData }
        newProspectData["conversations"] = newConversations
        setProspectData(newProspectData)
        // setProspectReloadKeyopoverOpen(Math.random())
        notify("success", "Conversation status updated successfully!")
      }
    } catch (error) {
      notify(
        "error",
        error.response?.data?.error ||
          error.response?.data?.message ||
          error?.data?.message
      )
    }
    setIsLoading(false)
  }

  const activityIcons = {
    drip: dripActivity,
    wrongNumber: wrongNumberActivity,
    dnc: dncActivity,
    leadStage: leadStageActivity,
    verified: verifiedActivity,
    followUp: followUpActivity,
    priority: priorityActivity,
    qualified: qualifiedActivity,
    tag: tagsActivity,
  }

  const getMasterData = async () => {
    // Tags
    try {
      const Tags = await axios.get(
        process.env.REACT_APP_PORT + `/api/tags/pullTags`,
        config
      )
      if (Tags?.data) {
        setMasterData(prevMasterData => ({
          ...prevMasterData,
          tags: Tags?.data?.data || prevMasterData?.tags, // Use previous tags if Tags?.data?.data is falsy
        }))

        if (prospectData && prospectData?.prospect?.tags) {
          const tempArr = []
          Tags?.data?.data?.map(item => {
            const temp =
              prospectData?.prospect?.tags?.filter(tag => tag == item._id) || []
            if (temp && temp?.length) {
              tempArr?.push({
                label: item?.name,
                value: item?._id,
                data: item,
              })
            }
          })
          setSelectedTags(tempArr)
        }
      }
    } catch (error) {
      notify("error", error?.data?.message)
    }
    // Lead Stage
    try {
      const LeadStage = await axios.get(
        process.env.REACT_APP_PORT + `/api/setting/getLeadStage`,
        config
      )
      if (LeadStage?.data) {
        setMasterData(prevMasterData => ({
          ...prevMasterData,
          leadStage: LeadStage?.data?.data || prevMasterData?.leadStage, // Use previous tags if Tags?.data?.data is falsy
        }))
      }
    } catch (error) {
      notify("error", error?.data?.message)
    }
    // Drip Templates
    try {
      const DripTemplate = await axios.get(
        process.env.REACT_APP_PORT + "/api/dripTemplate/pullDripTemplates",
        config
      )
      if (DripTemplate?.data) {
        setMasterData(prevMasterData => ({
          ...prevMasterData,
          dripTemplate:
            DripTemplate?.data?.data || prevMasterData?.dripTemplate, // Use previous tags if Tags?.data?.data is falsy
        }))
      }
    } catch (error) {
      notify("error", error?.data?.message)
    }

    // Quick Replies
    try {
      const QuickReplies = await axios.get(
        process.env.REACT_APP_PORT + `/api/template/pullQRS`,
        config
      )
      if (QuickReplies?.data) {
        setMasterData(prevMasterData => ({
          ...prevMasterData,
          quickReplies:
            QuickReplies?.data?.data || prevMasterData?.quickReplies, // Use previous tags if Tags?.data?.data is falsy
        }))
      }
    } catch (error) {
      notify("error", error?.data?.message)
    }
  }

  const getProspectById = async () => {
    setIsLoading(true)
    try {
      const response = await axios.get(
        process.env.REACT_APP_PORT +
          `/api/prospect/getProspect/${prospectData?.prospectId}`,
        config
      )

      if (response?.data) {
        const prospectDataById = response?.data
        const newData = { ...prospectData, ...prospectDataById }
        setProspectData(newData)
        setCurrentProspectId(prospectDataById?.prospect?._id)
        const tempArr = []
        masterData?.tags?.map(item => {
          const temp =
            response.data?.prospect?.tags?.filter(tag => tag === item._id) || []
          if (temp && temp?.length) {
            // tempArr?.push(item)
            tempArr?.push({
              label: item?.name,
              value: item?._id,
              data: item,
            })
          }
        })
        setSelectedTags(tempArr)
        setIsLoading(false)
      }
    } catch (error) {
      notify("error", error?.data?.message)
      setIsLoading(false)
    }
  }

  const getSelectedTags = () => {
    const arr = []
    if (masterData?.tags && prospectData?.prospect?.tags) {
      masterData?.tags?.map(item => {
        const temp =
          prospectData?.prospect?.tags?.filter(tag => tag === item._id) || []
        if (temp && temp?.length) {
          arr?.push({
            label: item?.name,
            value: item?._id,
            data: item,
          })
        }
      })
    }
    return arr
  }

  // for deleting note

  const handleDeleteNote = async NoteId => {
    setIsLoading(true)
    try {
      // setSideBar(false)
      const response = await axios.delete(
        process.env.REACT_APP_PORT + `/api/notes/deleteNote/${NoteId}`,
        config
      )
      if (response.data) {
        notify("success", "Note Deleted Successfully!")
        getProspectById()
        setIsLoading(false)
        // setSideBar(true)
      }
    } catch (err) {
      this.notify("error", err?.response?.data?.message)
      setIsLoading(false)
    }
  }
  // for deleting note

  const handleDeleteConversation = async NoteId => {
    setIsLoading(true)
    try {
      // setSideBar(false)
      const response = await axios.delete(
        process.env.REACT_APP_PORT + `/api/notes/deleteNote/${NoteId}`,
        config
      )
      if (response.data) {
        notify("success", "Note Deleted Successfully!")
        getProspectById()
        setIsLoading(false)
        // setSideBar(true)
      }
    } catch (err) {
      this.notify("error", err?.response?.data?.message)
      setIsLoading(false)
    }
  }
  // for creating note
  const createNotesHandler = async () => {
    setIsLoading(true)
    // setSideBar(false)
    const data = {
      notes: noteMsg.trim(),
      id: prospectData?.prospectId,
    }

    try {
      if (noteMsg && noteMsg.trim().length) {
        const response = await axios.post(
          process.env.REACT_APP_PORT + "/api/notes/createNote/",
          data,
          config
        )
        if (response) {
          notify("success", "Note Added Successfully")
          getProspectById()
          setIsLoading(false)
          setNoteMsg("")
        }
      }
    } catch (error) {
      setIsLoading(false)
      notify("error", error.response?.data?.message)
    }
  }
  // for edit note
  const handleEditNote = async (event, values) => {
    const body = {
      notes: values.note.trim(),
      id: editNoteModal?.note_id,
      prospect_id: prospectData?.prospect?._id,
    }
    try {
      const response = await axios.put(
        process.env.REACT_APP_PORT +
          `/api/notes/updateNote/${editNoteModal?.note_id}`,
        body,
        config
      )
      if (response.data) {
        notify("success", "Note Updated Successfully")
        setEditNoteModal({ isOpen: false, note: "", note_id: "" })
        getProspectById()
        setIsLoading(false)
        setSideBar(true)
      }
    } catch (error) {
      setIsLoading(false)
      notify("error", error?.response?.data?.message)
    }
  }

  // for getting each drip template details when the user click on message
  const getDripTemplateDetail = async id => {
    setIsLoading(true)
    try {
      const response = await axios.get(
        process.env.REACT_APP_PORT + "/api/dripTemplate/pullDripTemplate/" + id,
        config
      )

      if (response.data) {
        setDripTemplateDetail({
          isOpen: true,
          id: id,
          data: response.data.data,
        })
        setIsLoading(false)
      }
    } catch (error) {
      notify("error", error?.data?.message)
      setIsLoading(false)
    }
  }
  // toggle for add drip
  const toggleDrip = () => {
    setAddDripModal(!addDripModal)
  }
  // adding new drip to prospect
  const addDrip = async (dripTemplateId = dripTemplateDetail?.id) => {
    setIsLoading(true)

    const body = {
      prospectId: prospectData?.prospect?._id,
      dripTemplateId,
    }
    try {
      const response = await axios.post(
        process.env.REACT_APP_PORT + "/api/dripTemplate/addDrip",
        body,
        config
      )
      if (response.data) {
        notify("success", "Drip Added Successfully")
        setIsLoading(false)
        toggleDrip()
        setDripTemplateDetail({ isOpen: false, id: null, data: {} })
        getProspectById()
      }
    } catch (error) {
      notify("error", error?.response?.data?.message)
      setIsLoading(false)
    }
  }
  // removing drip from prospect
  const removeDrip = async () => {
    setIsLoading(true)
    const body = {
      prospectId: prospectData?.prospect?._id,
    }
    try {
      const response = await axios.post(
        process.env.REACT_APP_PORT + "/api/dripTemplate/removeDrip",
        body,
        config
      )
      if (response.data) {
        setDripRemoveConfirm({ isOpen: false })
        notify("success", "Drip Removed Successfully")
        getProspectById()
        setIsLoading(false)
      }
    } catch (error) {
      notify("error", error?.response?.data?.message)
      setIsLoading(false)
    }
  }
  // for count down before push to podio the prospect
  const pushToWHT = () => {
    setPodioLoding(true)
    const interval = setInterval(() => {
      setCount(prevCount => {
        if (prevCount <= 0) {
          clearInterval(interval) // Clear interval when count reaches 0
          return 0
        } else {
          return prevCount - 1
        }
      })
    }, 1000)
    // return () => clearInterval(interval);

    setTimeout(() => {
      // this.props.pushToWebhook({ id: this.props.data.prospect._id })
      pushToPodio()
      setPodioLoding(false)
      setCount(60)

      clearInterval(interval)
    }, 60000)
  }

  // for push to podio the propsect
  const pushToPodio = async () => {
    setIsLoading(true)
    const body = {
      id: currentProspectId,
    }
    try {
      const response = await axios.post(
        process.env.REACT_APP_PORT +
          "/api/prospect/pushToWH/" +
          currentProspectId,
        body,
        config
      )
      if (response?.data) {
        notify("success", "Prospect Pushed to Podio Successfully")
        setIsLoading(false)
        getProspectById()
      }
    } catch (error) {
      notify("error", error?.response?.data?.message)
      setIsLoading(false)
    }
  }
  // for creating reminder
  const handleCreateReminder = async (e, value) => {
    const data = {
      date: value.calendar,
      prospectId: prospectData?.prospect?._id,
      note: value.note.trim(),
    }
    setIsLoading(true)
    try {
      const response = await axios.post(
        process.env.REACT_APP_PORT + "/api/reminders/createReminder/",
        data,
        config
      )
      if (response) {
        setReminderModal({ isOpen: false })
        getProspectById()
        setIsLoading(false)
        notify("success", "Reminder Created Successfully")
      }
    } catch (err) {
      notify("error", err?.response?.data?.message)
      setIsLoading(false)
    }
  }
  // for deleting existing reminder
  const handleDeleteReminder = async reminderId => {
    setIsLoading(true)
    try {
      const response = await axios.delete(
        process.env.REACT_APP_PORT +
          `/api/reminders/deleteReminder/${reminderId}`,
        config
      )
      if (response.data) {
        notify("success", "Reminder marked as completed successfully!")
        getProspectById()
        setIsLoading(false)
      }
    } catch (err) {
      notify("error", err?.response?.data?.message)
      setIsLoading(false)
    }
  }
  // for editing prospect
  const handleEditProspect = async (e, value) => {
    setIsLoading(true)
    const body = {
      first_name: value?.first_name.trim(),
      id: prospectData?.prospect?._id,
      last_name: value?.last_name.trim(),
    }
    try {
      const response = await axios.put(
        process.env.REACT_APP_PORT +
          `/api/prospect/updateProspect/${prospectData?.prospect?._id}`,
        body,
        config
      )
      if (response.data) {
        notify("success", "Prospect Updated Successfully")
        setEditProspectModal({ isOpen: false })
        getProspectById()
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      notify("error", error?.response?.data?.message)
    }
  }
  // for delete prospect
  const handleDeleteProspect = async () => {
    setIsLoading(true)
    const body = { id: [prospectData?.prospect?._id] }

    try {
      const response = await axios.post(
        process.env.REACT_APP_PORT + "/api/prospect/deleteprospects",
        body,
        config
      )
      if (response.data) {
        setDeleteProspect({ isOpen: false })
        notify("success", "Prospect Deleted  Successfully!")
        setShouldRedirect({
          state: true,
          path: `/prospects`,
        })
        setIsLoading(false)
      }
    } catch (err) {
      notify("error", err?.response?.data?.message)
      setIsLoading(false)
    }
  }

  const handleValidSubmit = async (event, values) => {
    setIsLoading(true)
    if (isSending) {
      return
    }

    setIsSending(true)
    if (values?.message?.length > 320) {
      return
    }

    if (values.message?.trim() === "") {
      setSend("")

      return
    }

    let data = {}
    if (createNewConv) {
      data = {
        // from: prospectData?.conversations[convIndex]?.systemNumber,
        // to: prospectData?.conversations[convIndex]?.prospectNumber,
        body: send?.trim(),
        // camp: prospectData?.prospect?.campaign?._id,
        prospectId: currentProspectId,
        conversationId: prospectData?.conversations[convIndex]?._id,
      }
    } else {
      data = {
        from: prospectData?.conversations[convIndex]?.systemNumber,
        to: prospectData?.conversations[convIndex]?.prospectNumber,
        body: send?.trim(),
        camp: prospectData?.prospect?.campaign?._id,
        prospectId: currentProspectId,
        conversationId: prospectData?.conversations[convIndex]?._id,
      }
    }
    try {
      const response = await axios.post(
        process.env.REACT_APP_PORT + "/api/sendconv",
        data,
        config
      )
      if (response.data) {
        setIsSending(false)
        setCreateNewConv(false)
        setSendReloadKey(Math.random())
        setSend("")
        const currentConvIndex = prospectData.conversations.findIndex(
          item => item._id === response.data.convId
        )
        if (currentConvIndex !== -1) {
          const newMessages =
            prospectData.conversations[currentConvIndex].messages
          newMessages.push(response.data)

          let prosData = { ...prospectData }

          let newConv = prosData.conversations[currentConvIndex]
          newConv.unreadCount = newConv.unreadCount + 1
          newConv.messages = newMessages
          prosData.conversations[currentConvIndex] = newConv
          setProspectData(prosData)
        } else {
          getProspectById()
        }
      }
    } catch (error) {
      setIsSending(false)
      notify("error", error?.response?.data?.message)
    }
    setIsLoading(false)
  }

  const getProspectBySNum = async action => {
    setIsLoading(true)
    let requiredProspectIndex
    switch (action) {
      case "next": {
        requiredProspectIndex = currentProspectIndex + 1
        break
      }
      case "prev": {
        requiredProspectIndex = currentProspectIndex - 1
        break
      }
      default: {
        break
      }
    }
    const requiredProspectId =
      prospectIds?.length > requiredProspectIndex - 1
        ? prospectIds[requiredProspectIndex - 1]
        : null

    try {
      const rawQuery = {
        ...filterData,
        requiredProspectIndex,
      }
      if (requiredProspectId) {
        rawQuery.requiredProspectId = requiredProspectId
      }
      const queryParams = new URLSearchParams(rawQuery)
      const response = await axios.get(
        process.env.REACT_APP_PORT +
          `/api/prospect/getProspect/${prospectData?.prospect?._id}?${queryParams}`,
        config
      )

      if (response?.data) {
        setCurrentProspectIndex(requiredProspectIndex)
        setCurrentProspectId(response?.data?.prospect._id)
        const prospectDataById = response?.data
        setConvIndex(0)
        setProspectData({
          ...prospectDataById,
          prospectId: response?.data?.prospect._id,
        })
        setIsLoading(false)
      }
    } catch (error) {
      notify("error", error?.data?.message)
      setIsLoading(false)
    }
  }

  const setDNC = async (id, dnc) => {
    setIsLoading(true)

    const body = {
      dnc: dnc,
      id: prospectData?.prospect?._id,
      list: true,
    }
    try {
      const response = await axios.put(
        process.env.REACT_APP_PORT +
          `/api/prospect/prospectDNC/${prospectData?.prospect?._id}`,
        body,
        config
      )
      if (response.data) {
        if (dnc) {
          notify("success", "Prospect Updated Successfully")
        } else {
          notify("success", "Prospect Updated Successfully")
        }

        getProspectById()
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      notify("error", error?.response?.data?.message)
    }
  }
  const verifiedNumber = async (id, verified) => {
    setIsLoading(true)

    const body = {
      id: prospectData?.prospect?._id,
      list: true,
    }

    if (verified) {
      try {
        const response = await axios.put(
          process.env.REACT_APP_PORT +
            `/api/prospect/verifiednumber/${prospectData?.prospect?._id}`,
          body,
          config
        )
        if (response.data) {
          notify("success", "Prospect Updated Successfully")
          getProspectById()
          setIsLoading(false)
        }
      } catch (error) {
        setIsLoading(false)
        notify("error", error?.response?.data?.message)
      }
    } else {
      try {
        const response = await axios.put(
          process.env.REACT_APP_PORT +
            `/api/prospect/removeverifiednumber/${prospectData?.prospect?._id}`,
          body,
          config
        )
        if (response.data) {
          notify("success", "Prospect Updated Successfully")
          getProspectById()
          setIsLoading(false)
        }
      } catch (error) {
        notify("error", error?.response?.data?.message)
      }
    }
  }
  const wrongNumber = async (id, wrongNumber) => {
    setIsLoading(true)

    const body = {
      id: prospectData?.prospect?._id,
      list: true,
    }

    if (wrongNumber) {
      try {
        const response = await axios.put(
          process.env.REACT_APP_PORT +
            `/api/prospect/wrongnumber/${prospectData?.prospect?._id}`,
          body,
          config
        )
        if (response.data) {
          notify("success", "Prospect Updated Successfully")
          getProspectById()
          setIsLoading(false)
        }
      } catch (error) {
        setIsLoading(false)
        notify("error", error?.response?.data?.message)
      }
    } else {
      try {
        const response = await axios.put(
          process.env.REACT_APP_PORT +
            `/api/prospect/removewrongnumber/${prospectData?.prospect?._id}`,
          body,
          config
        )
        if (response.data) {
          notify("success", "Prospect Updated Successfully")
          getProspectById()
          setIsLoading(false)
        }
      } catch (error) {
        notify("error", error?.response?.data?.message)
        setIsLoading(false)
      }
    }
  }
  const qualifiedNumber = async (id, qualifiedNumber) => {
    setIsLoading(true)

    const body = {
      id: prospectData?.prospect?._id,
      list: true,
    }

    if (qualifiedNumber) {
      try {
        const response = await axios.put(
          process.env.REACT_APP_PORT +
            `/api/prospect/isqualified/${prospectData?.prospect?._id}`,
          body,
          config
        )
        if (response.data) {
          notify("success", "Prospect Updated Successfully")
          getProspectById()
          setIsLoading(false)
        }
      } catch (error) {
        setIsLoading(false)
        notify("error", error?.response?.data?.message)
      }
    } else {
      try {
        const response = await axios.put(
          process.env.REACT_APP_PORT +
            `/api/prospect/wasqualified/${prospectData?.prospect?._id}`,
          body,
          config
        )
        if (response.data) {
          notify("success", "Prospect Updated Successfully")
          getProspectById()
          setIsLoading(false)
        }
      } catch (error) {
        notify("error", error?.response?.data?.message)
        setIsLoading(false)
      }
    }
  }
  const priorityNumber = async (id, priorityNumber) => {
    setIsLoading(true)

    const body = {
      id: prospectData?.prospect?._id,
      list: true,
    }

    if (priorityNumber) {
      try {
        const response = await axios.put(
          process.env.REACT_APP_PORT +
            `/api/prospect/isPriority/${prospectData?.prospect?._id}`,
          body,
          config
        )
        if (response.data) {
          notify("success", "Prospect Updated Successfully")
          getProspectById()
          setIsLoading(false)
        }
      } catch (error) {
        setIsLoading(false)
        notify("error", error?.response?.data?.message)
      }
    } else {
      try {
        const response = await axios.put(
          process.env.REACT_APP_PORT +
            `/api/prospect/wasPriority/${prospectData?.prospect?._id}`,
          body,
          config
        )
        if (response.data) {
          notify("success", "Prospect Updated Successfully")
          getProspectById()
          setIsLoading(false)
        }
      } catch (error) {
        notify("error", error?.response?.data?.message)
        setIsLoading(false)
      }
    }
  }

  const handleUpdateLeadStage = async selectedValue => {
    setIsLoading(true)
    try {
      const data = {
        value: selectedValue,
        _id: prospectData?.prospect?._id,
      }
      const response = await axios.post(
        process.env.REACT_APP_PORT + "/api/prospect/existProspects/",
        data,
        config
      )
      notify("success", "Lead Stage Updated Successfully")
      setIsLoading(false)
      getProspectById()
    } catch (error) {
      notify("error", error?.data?.message)
      setIsLoading(false)
    }
  }
  const handleUpdateTags = async _selectedValue => {
    const selectedValue = _selectedValue || []
    setIsLoading(true)

    const tagsData = selectedValue.map(item => item?.data)
    const body = { tags: tagsData }
    try {
      const response = await axios.put(
        process.env.REACT_APP_PORT +
          `/api/prospect/updateTags/${prospectData?.prospect?._id}`,
        body,
        config
      )

      if (response.data) {
        notify("success", "Tags Updated Successfully")
        getProspectById()
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      notify("error", error?.response?.data?.message)
    }
  }

  function validMessage(message) {
    let isValidMessage = true
    if (message?.length) {
      const regExp = /\[@([^}]+)\]/g
      const matches = message.match(regExp)
      if (matches?.length) {
        isValidMessage = false
      }
    }

    return isValidMessage
  }

  const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {},
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: "#efefef",
      }
    },
    multiValueLabel: (styles, state) => {
      return {
        ...styles,
        backgroundColor: state?.data?.data?.color?.color + " !important",
      }
    },
    multiValueRemove: (styles, { data }) => ({
      ...styles,
    }),
  }

  const phoneNumberColumns = [
    {
      name: "Title",
      selector: row => row.title,
      sortable: true,
      cell: row => {
        return (
          <div
            key={row.id}
            className="border border-primary rounded w-100 p-2 m-2 cursor-pointer"
            onClick={() => {
              const regExp = /\[@([^}]+)\]/g
              const matches = row?.message?.match(regExp)

              if (matches?.length) {
                MapQuickReply(row?.message, prospectData?.prospect)
              }
              setTextBody(row?.message)
              setPopoverOpen(prev => ({ isOpen: !prev.isOpen }))
            }}
          >
            <h6>{row.title}</h6>
            <p className="mb-0">{row?.message}</p>
          </div>
        )
      },
      minWidth: "200px",
    },
  ]

  const setTextBody = message => {
    let body = message
    body = body.replace(/\[@firstName+\]/g, prospectData.prospect?.first_name)
    body = body.replace(/\[@email+\]/g, prospectData.prospect?.email)
    body = body.replace(/\[@lastName+\]/g, prospectData.prospect?.last_name)
    body = body.replace(
      /\[@address+\]/g,
      prospectData.prospect?.property_address
    )
    body = body.replace(/\[@city+\]/g, prospectData.prospect?.property_city)
    body = body.replace(/\[@zip+\]/g, prospectData.prospect?.property_zip)
    body = body.replace(/\[@state+\]/g, prospectData.prospect?.property_state)

    setSend(body)
  }

  const MapQuickReply = async (message, prospect) => {
    setIsLoading(true)

    try {
      const response = await axios.post(
        process.env.REACT_APP_PORT + `/api/conversation/mapQuickReply`,
        { message: message, prospect: prospect },
        config
      )
      if (response?.data) {
        setSend(response.data?.message)
        setIsLoading(false)
        if (response?.data?.invalid?.length) {
          notify("error", "No data available for replacement with merge field")
        }
      }
    } catch (error) {
      // setCheckNumberLoading(false)
      // setCheckedNumber([])
      setIsLoading(false)

      notify("error", error.response?.data?.error)
    }
  }

  const colorStyleForLead = {
    control: styles => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {},
  }

  const checkSystemNumber = async array => {
    setCallCheckNumApi(false)
    const numbers = {
      numbers: array,
    }
    setIsLoading(true)
    setCheckNumberLoading(true)
    try {
      const response = await axios.post(
        process.env.REACT_APP_PORT + `/api/conversation/checkNumber`,
        numbers,
        config
        // ${number}
      )
      if (response) {
        setCheckNumberLoading(false)
        setCheckedNumber(response?.data)

        let index = prospectData?.conversations.findIndex(obj =>
          response?.data.some(
            item => item.number == obj.systemNumber && item.status == "Active"
          )
        )

        if (index !== -1) {
          setAnotherActiveNumber(index)
        }
        setIsLoading(false)
      }
    } catch (error) {
      setCheckNumberLoading(false)
      setCheckedNumber([])
      setIsLoading(false)

      notify("error", error.response?.data?.error)
    }
  }

  return (
    <React.Fragment>
      <Toaster />

      {shouldRedirect && shouldRedirect.state && (
        <Redirect to={shouldRedirect.path} />
      )}
      <div className="page-content">
        <MetaTags>
          <title>Prospects | TextLaunchPad.com </title>
        </MetaTags>
        <Container fluid key={prospectReloadKey}>
          <div className={`chat-sidebar-wrapper ${sidebar && "active"}`}>
            <button
              onClick={() => setSideBar(false)}
              className="chat-sidebar-close"
            >
              <img src={sidebarCloseIcon} alt="" />
            </button>
            <div className="chat-sidebar-container">
              <div className="chat-sidebar-header">
                <ul>
                  <li>
                    <button
                      onClick={() => setActiveTab(1)}
                      className={`${activeTab == 1 ? "active" : ""}`}
                    >
                      <img src={notesIcon} alt="" />
                      <span>Notes</span>
                    </button>
                  </li>

                  <li>
                    <button
                      onClick={() => setActiveTab(2)}
                      className={`${activeTab == 2 ? "active" : ""}`}
                    >
                      <img src={activityIcon} alt="" />
                      <span>Activity</span>
                    </button>
                  </li>
                </ul>
              </div>
              <div className="chat-sidebar-body">
                {activeTab == 1 && (
                  <div className="notes-tab">
                    <div className="notes-container">
                      <ul className="notes-list">
                        {prospectData?.notes && prospectData?.notes?.length ? (
                          prospectData?.notes?.map((item, index) => {
                            return (
                              <li key={index}>
                                <div className="d-flex flex-row justify-content-between align-items-center pl-0 !important">
                                  <div className="border-0 !important p-0 !important">
                                    {item.notes}
                                  </div>
                                  <div className="d-flex gap-2 pb-2 border-0">
                                    <Button
                                      className={
                                        "has-icon small me-1 mb-1 dripIconModal"
                                      }
                                      onClick={() => {
                                        handleDeleteNote(item?._id)
                                      }}
                                    >
                                      <img
                                        className="note-action-icon"
                                        src={DeleteProspect}
                                        style={{
                                          width: "30px",
                                          margin: "5px",
                                        }}
                                        alt=""
                                      />
                                    </Button>

                                    <Button
                                      className={
                                        "has-icon small me-1 mb-1 dripIconModal"
                                      }
                                      onClick={() => {
                                        // handleDeleteNote(item?._id)
                                        setSideBar(false)
                                        setEditNoteModal({
                                          isOpen: true,
                                          note: item?.notes,
                                          note_id: item?._id,
                                        })
                                      }}
                                    >
                                      <img
                                        src={EditProspect}
                                        style={{
                                          width: "30px",
                                          margin: "5px",
                                        }}
                                        alt=""
                                      />
                                    </Button>
                                  </div>
                                </div>
                                <p>
                                  {moment
                                    .utc(item.createdAt)
                                    .local()
                                    .format("MM-DD-YYYY | hh-mm A")}
                                </p>
                              </li>
                            )
                          })
                        ) : (
                          <div className="chat-illus illus-empty-state">
                            <img src={illus} alt="" />
                            <h3>No Notes Added</h3>
                          </div>
                        )}
                      </ul>
                      <div>
                        <div className="seperator"></div>
                        <div className="notes-add-input-wrap">
                          <div className="form-group">
                            <div className="inp-grp">
                              <textarea
                                placeholder="Type message"
                                value={noteMsg || ""}
                                onChange={e => {
                                  setNoteMsg(e.target.value)
                                }}
                              ></textarea>
                            </div>
                          </div>
                          <button
                            // className="main-btn success-btn"
                            className={`main-btn success-btn ${
                              isLoading || !noteMsg?.trim()?.length
                                ? "disabled"
                                : null
                            }`}
                            onClick={() => {
                              createNotesHandler()
                            }}
                            disabled={isLoading || !noteMsg?.trim()?.length}
                          >
                            <img src={plusIcon} alt="" />
                            <span>Add</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {activeTab == 2 && (
                  <div className="activity-tab">
                    <div className="activity-container">
                      <ul className="activity-list">
                        {prospectData?.activity &&
                        prospectData?.activity?.length ? (
                          prospectData?.activity.map((item, index) => {
                            return (
                              <li key={index}>
                                <div className="activity-icon">
                                  <img
                                    src={activityIcons[item.event]}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>

                                <div className="body">
                                  <span></span>
                                  <span className="date-time">
                                    {moment
                                      .utc(item?.createdAt)
                                      .local()
                                      .format("MM-DD-YYYY | hh:mm A")}
                                  </span>
                                  <p className="comment">{item?.comment}</p>
                                </div>
                              </li>
                            )
                          })
                        ) : (
                          <div className="chat-illus illus-empty-state">
                            <img src={illus} alt="" />
                            <h3>No Activity Found</h3>
                          </div>
                        )}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="form-horizontal form-wizard-wrapper wizard clearfix">
            <div className="c-card mt-3">
              {isLoading ? (
                <div className="spinner-wr">
                  <Spinner style={{ color: "#5932ea" }} />
                </div>
              ) : null}
              <Row>
                <Col sm={12} xs={12} md={6} lg={6} xl={5}>
                  <div className="c-card mt-3 prospect-detail">
                    <div className="chat-sidebar-body">
                      <Row>
                        <Col sm={12} xs={12} md={9} xl={9}>
                          <div className="chat-sidebar-top">
                            <div className="chat-sidebar-image">
                              <span
                                className={`prospect-nav-button prev ${
                                  currentProspectIndex <= 1 ? "disabled" : ""
                                }`}
                                disabled={currentProspectIndex <= 1}
                                onClick={() => {
                                  if (currentProspectIndex > 1) {
                                    getProspectBySNum("prev")
                                    setMessage({})
                                    setCallCheckNumApi(true)
                                  }
                                }}
                                title="Previous prospect"
                              >
                                <img src={arrowLeft} alt="" />
                              </span>
                              <img
                                className={"img-fluid pb-3"}
                                src={`https://maps.googleapis.com/maps/api/streetview?size=500x500&location=${encodeURIComponent(
                                  prospectData?.prospect?.property_address +
                                    " " +
                                    prospectData?.prospect?.property_state +
                                    "," +
                                    prospectData?.prospect?.property_zip
                                )}&fov=70&key=AIzaSyAF7bA-6V-gMdD-QFeIq8WYx_66RDMLK8Y`}
                                alt=""
                              />

                              <span
                                className={`prospect-nav-button next ${
                                  currentProspectIndex === totalProspects
                                    ? "disabled"
                                    : ""
                                }`}
                                disabled={
                                  currentProspectIndex === totalProspects
                                }
                                onClick={() => {
                                  if (currentProspectIndex !== totalProspects) {
                                    getProspectBySNum("next")
                                    setMessage({})
                                    setCallCheckNumApi(true)
                                  }
                                }}
                                title="Next prospect"
                              >
                                <img src={arrowRight} alt="" />
                              </span>
                            </div>
                            {/* <Link
                                className={"prospect-nav-button next"}
                                onClick={() => {
                                  getProspectBySNum("next")
                                }}
                                to={`/prospect/${prospectData?.prospect?._id}`}
                              >
                                <img src={arrowRight} alt="" />
                              </Link> */}
                            {prospectData?.prospect && (
                              <div className="chat-sidebar-det">
                                <h3>
                                  {prospectData?.prospect?.first_name
                                    ? `${prospectData?.prospect.first_name} ${
                                        prospectData?.prospect.last_name
                                          ? prospectData?.prospect?.last_name
                                          : ""
                                      }`
                                    : prospectData?.prospect?.last_name
                                    ? prospectData?.prospect?.last_name
                                    : ""}
                                  {/* {prospectData?.prospect?.first_name +
                                    " " +
                                    prospectData?.prospect?.last_name} */}
                                </h3>
                                <p>
                                  {prospectData?.prospect?.phone}

                                  <br />
                                  {/* {prospectData?.prospect?.property_address +
                                    ", " +
                                    prospectData?.prospect?.property_city +
                                    ", " +
                                    prospectData?.prospect?.property_state +
                                    ", " +
                                    prospectData?.prospect?.property_zip} */}
                                  {[
                                    prospectData?.prospect?.property_address &&
                                      prospectData?.prospect?.property_address +
                                        ", ",
                                    prospectData?.prospect?.property_city &&
                                      prospectData?.prospect?.property_city +
                                        ", ",
                                    prospectData?.prospect?.property_state &&
                                      prospectData?.prospect?.property_state +
                                        ", ",
                                    prospectData?.prospect?.property_zip &&
                                      prospectData?.prospect?.property_zip,
                                  ]}
                                </p>
                                <a
                                  href={
                                    `http://www.zillow.com/homes/${
                                      prospectData?.prospect?.property_address +
                                      " " +
                                      prospectData?.prospect?.property_city +
                                      ", " +
                                      prospectData?.prospect?.property_state +
                                      " " +
                                      prospectData?.prospect?.property_zip
                                    }`
                                      .toString()
                                      .split(" ")
                                      .join("-") + "_rb"
                                  }
                                  target="_blank"
                                >
                                  View on Zillow
                                </a>
                                <p>
                                  {" "}
                                  <strong>
                                    {" "}
                                    {prospectData?.prospect?.campaign?.name ? (
                                      <i className="highlightedCampaignName">
                                        {" "}
                                        {
                                          prospectData?.prospect?.campaign?.name
                                        }{" "}
                                      </i>
                                    ) : (
                                      <span className="text-danger">
                                        Campaign Not Exist
                                      </span>
                                    )}
                                  </strong>{" "}
                                </p>
                              </div>
                            )}
                          </div>
                        </Col>

                        <Col sm={12} xs={12} md={3} xl={3}>
                          <div className="d-flex p-3 justify-content-center gap-3">
                            <Button
                              className={
                                "has-icon small me-1 mb-1 dripIconModal action-btn"
                              }
                              onClick={() => {
                                setSideBar(!sidebar)
                              }}
                            >
                              <img src={NotesIcon} alt="" />
                            </Button>
                            <Button
                              className={
                                "has-icon small me-1 mb-1 dripIconModal action-btn"
                              }
                              onClick={() => {
                                setEditProspectModal({ isOpen: true })
                              }}
                            >
                              <img src={EditProspect} alt="" />
                            </Button>
                            <Button
                              className={
                                "has-icon small me-1 mb-1 dripIconModal action-btn"
                              }
                              onClick={() => {
                                setDeleteProspect({ isOpen: true })
                              }}
                            >
                              <img src={DeleteProspect} alt="" />
                            </Button>
                          </div>
                        </Col>
                      </Row>

                      <div>
                        <div className="chat-tag-wrap chat-sidebar-tags">
                          {prospectData?.prospect?.dnc ? (
                            <Button
                              className={"botton-btn me-1 "}
                              color={"danger"}
                              onClick={e => {
                                setDNC(prospectData?.prospect?._id, false)
                              }}
                              title="Remove From DNC"
                            >
                              <i className="fas fa-phone-slash" />
                              <span>DNC</span>
                            </Button>
                          ) : (
                            <Button
                              className={"botton-btn me-1 "}
                              color={"outline-secondary"}
                              onClick={e => {
                                setDNC(prospectData?.prospect?._id, true)
                              }}
                              title="Add To DNC"
                            >
                              <i className="fas fa-phone-slash" />
                              <span>DNC</span>
                            </Button>
                          )}{" "}
                          {prospectData?.prospect?.verified === false ? (
                            <Button
                              className={"botton-btn me-1 "}
                              color={"outline-secondary"}
                              onClick={() =>
                                verifiedNumber(
                                  prospectData?.prospect?._id,
                                  true
                                )
                              }
                              title="Add to Verified"
                            >
                              <i className="fas fa-check" />
                              <span>Verified</span>
                            </Button>
                          ) : (
                            <Button
                              className={"botton-btn me-1 "}
                              color={"success"}
                              onClick={() =>
                                verifiedNumber(
                                  prospectData?.prospect?._id,
                                  false
                                )
                              }
                              title="Remove From Verified"
                            >
                              <i className="fas fa-check" />
                              <span>Verified</span>
                            </Button>
                          )}{" "}
                          {prospectData?.prospect?.Wrong_Number === false ? (
                            <Button
                              className={"botton-btn me-1 "}
                              color={"outline-secondary"}
                              onClick={() => {
                                wrongNumber(prospectData?.prospect?._id, true)
                                if (prospectData?.prospect?.isDripAdded) {
                                  removeDrip()
                                }
                              }}
                              title="Add To Wrong Number"
                            >
                              <i className="fas fa-times" />
                              <span>Wrong Number</span>
                            </Button>
                          ) : (
                            <Button
                              className={"botton-btn me-1 "}
                              color={"primary"}
                              onClick={() =>
                                wrongNumber(prospectData?.prospect?._id, false)
                              }
                              title="Remove From Wrong Number"
                            >
                              <i className="fas fa-times" />
                              <span>Wrong Number</span>
                            </Button>
                          )}{" "}
                          {prospectData?.prospect?.isLead === false ? (
                            <Button
                              className={"botton-btn me-1 "}
                              color={"outline-secondary"}
                              onClick={() =>
                                qualifiedNumber(
                                  prospectData?.prospect?._id,
                                  true
                                )
                              }
                              title="Add To Qualified"
                            >
                              <i className="fas fa-star" />
                              <span>Qualified</span>
                            </Button>
                          ) : (
                            <Button
                              className={"botton-btn me-1 "}
                              color={"info"}
                              onClick={() =>
                                qualifiedNumber(
                                  prospectData?.prospect?._id,
                                  false
                                )
                              }
                              title="Remove From Qualified"
                            >
                              <i className="fas fa-star" />
                              <span>Qualified</span>
                            </Button>
                          )}{" "}
                          {prospectData?.prospect?.isPriority === false ? (
                            <Button
                              className={"botton-btn me-1 "}
                              color={"outline-secondary"}
                              onClick={() =>
                                priorityNumber(
                                  prospectData?.prospect?._id,
                                  true
                                )
                              }
                              title="Add To Priority"
                            >
                              <i className="fas fa-bolt" />
                              <span>Priority</span>
                            </Button>
                          ) : (
                            <Button
                              className={"botton-btn me-1 "}
                              color={"warning"}
                              onClick={() =>
                                priorityNumber(
                                  prospectData?.prospect?._id,
                                  false
                                )
                              }
                              title="Remove From Priority"
                            >
                              <i className="fas fa-bolt" />
                              <span>Priority</span>
                            </Button>
                          )}{" "}
                          {prospectData?.prospect?.isDripAdded === false ? (
                            <Button
                              className={"botton-btn me-1 "}
                              color={"outline-secondary"}
                              onClick={() => {
                                toggleDrip()
                              }}
                              title="Add To Drip"
                              disabled={prospectData?.prospect?.Wrong_Number}
                            >
                              <img
                                src={DripVaccent}
                                alt=""
                                style={{ marginBottom: "2px" }}
                              />{" "}
                              <span>Drip</span>
                            </Button>
                          ) : (
                            <Button
                              className={"botton-btn me-1 dripColor"}
                              backgroundColor={"#5932ea"}
                              color={"primary"}
                              onClick={() => {
                                setDripRemoveConfirm({
                                  isOpen: true,
                                  dripTemplateData:
                                    prospectData?.prospect?.dripTemplateId,
                                  dripData: prospectData?.prospect?.dripData,
                                  dripProspectId: prospectData?.prospect?._id,
                                })
                              }}
                              title="Remove From Drip"
                            >
                              <svg
                                marginBottom="2px"
                                width="14"
                                height="17"
                                viewBox="0 0 14 17"
                                fill="white"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7.46846 0.998848C7.19846 0.787168 6.82348 0.787168 6.55348 0.998848C5.12848 2.09505 0.920949 5.67093 0.943449 9.83649C0.943449 13.2082 3.66598 15.9601 7.01848 15.9601C10.371 15.9601 13.0935 13.2158 13.0935 9.84405C13.101 5.73897 8.88596 2.10261 7.46846 0.998848Z"
                                  fill="white"
                                />
                                <path
                                  d="M7.00977 0.840088V15.9601"
                                  stroke="#5932ea"
                                  stroke-width="1.125"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M7.00977 13.6617L12.7848 10.8342"
                                  stroke="#5932ea"
                                  stroke-width="1.125"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M7.00977 9.88177L12.5373 7.17529"
                                  stroke="#5932ea"
                                  stroke-width="1.125"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M7.00977 6.10171L10.7823 4.24951"
                                  stroke="#5932ea"
                                  stroke-width="1.125"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>{" "}
                              <span>Drip</span>
                            </Button>
                          )}{" "}
                          {prospectData?.conversations?.[convIndex]
                            ?.unreadCount ? (
                            <Button
                              // className={"mt-2"}
                              className={"botton-btn me-1 "}
                              onClick={() => changeStatus()}
                              color="primary"
                            >
                              <i className={"fas fa-envelope-open pe-1"}></i>{" "}
                              Mark As Read
                            </Button>
                          ) : null}
                        </div>
                      </div>

                      <div>
                        <h3 className="sm-head pt-3">CRM Option</h3>

                        {podioLoding ? (
                          <div style={{ marginTop: "10px" }}>
                            <button
                              color={"primary"}
                              className="main-btn border-btn circle-btn block-btn"
                            >
                              <span>
                                Pushing to podio in {count}
                                sec
                              </span>
                            </button>
                          </div>
                        ) : (
                          <button
                            color={"primary"}
                            onClick={() => {
                              pushToWHT()
                            }}
                            className="main-btn border-btn circle-btn block-btn"
                          >
                            <span>Push to Podio</span>
                          </button>
                        )}
                      </div>

                      <div className="seperator mt-3 mb-3"></div>
                      <h3 className="sm-head">Tags</h3>

                      <Select
                        className="cursor-pointer"
                        value={getSelectedTags()}
                        onChange={e => {
                          handleUpdateTags(e)
                        }}
                        options={masterData?.tags?.map(item => {
                          return {
                            label: item?.name,
                            value: item?._id,
                            data: item,
                          }
                        })}
                        isMulti
                        styles={colourStyles}
                      />

                      <h3 className="sm-head mt-3">Lead Stage</h3>

                      <div className="mb-5">
                        <Select
                          //  value={prospectData?.prospect?.lead}
                          className="cursor-pointer"
                          value={masterData?.leadStage?.map((lead, index) => {
                            if (lead._id === prospectData?.prospect?.lead)
                              return {
                                label: lead.lead_Stage,
                                value: lead._id,
                              }
                          })}
                          onChange={e => {
                            handleUpdateLeadStage(e?.value)
                          }}
                          options={masterData?.leadStage?.map((lead, index) => {
                            return {
                              label: lead.lead_Stage,
                              value: lead._id,
                            }
                          })}
                          styles={colorStyleForLead}
                        />

                        {/* <img src={dropDownArrow} alt="" />
                        <Input
                          type="select"
                          className="bg-transparent px-3 mb-3"
                          value={prospectData?.prospect?.lead}
                          onChange={e => {
                            handleUpdateLeadStage(e.target.value)
                          }}
                        >
                          {masterData?.leadStage?.length > 0 &&
                            masterData?.leadStage?.map((lead, index) => (
                              <option value={lead?._id} key={index}>
                                {lead?.lead_Stage}
                              </option>
                            ))}
                        </Input> */}
                      </div>

                      {prospectData?.reminder?._id ? (
                        <div className="pb-50">
                          <h3 className="sm-head">Reminder</h3>
                          <div className="p-3 rounded-3 reminder-box">
                            <Row>
                              <div className=" d-flex justify-content-flex-start gap-2 align-items-center  pl-1">
                                <img
                                  src={purpleCalendar}
                                  alt=""
                                  style={{ display: "inline" }}
                                />
                                <span>
                                  {moment
                                    .utc(prospectData?.reminder?.date)
                                    .local()
                                    .format("MM-DD-YYYY")}
                                </span>
                              </div>
                            </Row>
                            <Row>
                              <Col>
                                <span>Note : </span>
                                <span className="reminder-note">
                                  {prospectData?.reminder?.note}
                                </span>
                              </Col>
                            </Row>
                            <div
                              className="text-end d-flex justify-content-end align-items-center"
                              style={{ margin: "0px" }}
                            >
                              <Button
                                type="submit"
                                color="primary"
                                className="main-btn pl-2 pr-2"
                                onClick={() => {
                                  handleDeleteReminder(
                                    prospectData?.reminder?._id
                                  )
                                }}
                              >
                                <span className="d-flex gap-2 align-items-center ">
                                  {" "}
                                  <i className="fas fa-check"></i> Mark as
                                  Complete
                                </span>
                              </Button>{" "}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="pb-50">
                          <button
                            color={"primary"}
                            onClick={() => {
                              setReminderModal({ isOpen: true })
                              setSideBar(false)
                            }}
                            className="main-btn border-btn circle-btn block-btn"
                          >
                            <img src={plusPurple} alt="" />
                            <span>Add Reminder</span>
                          </button>
                        </div>
                      )}
                      {/* add reminder modal new */}
                      <Modal
                        isOpen={reminderModal.isOpen}
                        toggle={() => {
                          setReminderModal({ isOpen: false })
                        }}
                        className="c-modal"
                        centered={true}
                      >
                        <div className="modal-body ">
                          <h5 className="modal-title mt-0 mb-3">
                            Add Reminder
                          </h5>
                          <button
                            type="button"
                            onClick={() => {
                              setReminderModal({ isOpen: false })
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <img src={close} alt="" />
                          </button>

                          {
                            <AvForm
                              onValidSubmit={(e, v) =>
                                handleCreateReminder(e, v)
                              }
                              className="needs-validation"
                            >
                              <Row>
                                <Col xl="12">
                                  <FormGroup className="mb-3">
                                    <AvField
                                      name="note"
                                      placeholder="Enter Note"
                                      type="textarea"
                                      rows={5}
                                      errorMessage="Enter Note"
                                      className="form-control"
                                      validate={{
                                        required: { value: true },
                                        maxLength: { value: 320 },
                                      }}
                                      id="validationCustom01"
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="2">
                                  <Label
                                    htmlFor="calendar"
                                    className="main-btn color text-white rounded-circle display-1 d-flex justify-content-center"
                                    style={{ width: "50px", height: "50px" }}
                                  >
                                    <img src={calendar} alt="" />
                                  </Label>
                                </Col>
                                <Col xl="7" className="d-flex">
                                  <FormGroup className="mb-3 ">
                                    <AvField
                                      id="calendar"
                                      name="calendar"
                                      placeholder="Enter Note"
                                      type="date"
                                      rows={5}
                                      errorMessage="Enter Note"
                                      className="form-control border-bottom"
                                      validate={{
                                        required: { value: true },
                                        maxLength: { value: 320 },
                                      }}
                                      min={
                                        new Date().toISOString().split("T")[0]
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>

                              <div className="text-end py-2 d-flex justify-content-end align-items-center gap-2">
                                <Button
                                  className="main-btn secondary-btn"
                                  onClick={() => {
                                    setReminderModal({ isOpen: false })
                                  }}
                                  role="button"
                                >
                                  <span>Cancel</span>
                                </Button>
                                <Button
                                  type="submit"
                                  color="primary"
                                  className="main-btn pl-2 pr-2"
                                >
                                  <span>Add</span>
                                </Button>{" "}
                              </div>
                            </AvForm>
                          }
                        </div>
                      </Modal>

                      {/* Prospect edit new modal */}

                      <Modal
                        isOpen={editProspectModal?.isOpen}
                        toggle={() => {
                          setEditProspectModal({
                            isOpen: !editProspectModal?.isOpen,
                          })
                        }}
                        className="c-modal"
                        // backdrop="static"
                        centered={true}
                      >
                        <div className="modal-body ">
                          <h5 className="modal-title mt-0 mb-3">
                            {" "}
                            Edit Prospect Name
                          </h5>
                          <button
                            type="button"
                            onClick={() => {
                              setEditProspectModal({
                                isOpen: !editProspectModal?.isOpen,
                              })
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <img src={close} alt="" />
                          </button>

                          {
                            <AvForm
                              onValidSubmit={(e, v) => handleEditProspect(e, v)}
                              className="needs-validation"
                            >
                              <Row>
                                <Col xl="12">
                                  <FormGroup className="mb-3">
                                    <AvField
                                      type="text"
                                      className="form-control cc-form-control"
                                      name="first_name"
                                      label="First Name"
                                      validate={{
                                        required: { value: true },
                                        pattern: {
                                          value: "^[A-Za-z0-9 ]+$",
                                          errorMessage: "Enter valid name",
                                        },
                                      }}
                                      value={prospectData?.prospect?.first_name}
                                    ></AvField>
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <FormGroup className="mb-3">
                                    <AvField
                                      type="text"
                                      className="form-control cc-form-control"
                                      name="last_name"
                                      label="Last Name"
                                      validate={{
                                        required: { value: true },
                                        pattern: {
                                          value: "^[A-Za-z0-9 ]+$",
                                          errorMessage: "Enter valid name",
                                        },
                                      }}
                                      value={prospectData?.prospect?.last_name}
                                    ></AvField>
                                  </FormGroup>
                                </Col>
                              </Row>

                              <div className="text-end py-2 d-flex justify-content-end align-items-center gap-2">
                                <Button
                                  className="main-btn secondary-btn"
                                  onClick={() => {
                                    setEditProspectModal({ isOpen: false })
                                  }}
                                  role="button"
                                >
                                  <span>Cancel</span>
                                </Button>
                                <Button
                                  type="submit"
                                  color="primary"
                                  className="main-btn"
                                >
                                  <span>Update</span>
                                </Button>{" "}
                              </div>
                            </AvForm>
                          }
                        </div>
                      </Modal>
                    </div>
                  </div>
                </Col>

                <Col sm={12} xs={12} md={6} lg={6} xl={7}>
                  <div className="c-card mt-3">
                    <div className="chat-conversation">
                      <SimpleBar style={{ height: "516px" }}>
                        <ul
                          className="conversation-list"
                          data-simplebar
                          style={{ maxHeight: "367px", padding: "0px" }}
                        >
                          <li className="clearfix">
                            <div className="conversation-text first-box">
                              <div className="chat-message ">
                                <div className="flee-user-name">
                                  <div style={{ color: "black" }}>
                                    {prospectData?.conversations?.length > 1 ? (
                                      <h2 className="d-flex gap-2 align-items-center">
                                        <span
                                          className={`prospect-nav-button prev cursor-pointer ${
                                            convIndex == 0
                                              ? "disabled-opacity"
                                              : ""
                                          }`}
                                          disabled={convIndex == 0}
                                          onClick={() => {
                                            if (convIndex > 0) {
                                              setConvIndex(convIndex - 1)
                                              // checkSystemNumber(
                                              //   prospectData?.conversations?.[
                                              //     convIndex - 1
                                              //   ]?.systemNumber
                                              // )
                                            }
                                          }}
                                          title="Previous conversation"
                                        >
                                          <img
                                            src={arrowLeft}
                                            alt=""
                                            style={{ width: "20px" }}
                                          />
                                        </span>
                                        <span>
                                          {`${convIndex + 1}/${
                                            prospectData?.conversations?.length
                                          }`}{" "}
                                          Conversations
                                        </span>
                                        <span
                                          className={`prospect-nav-button next cursor-pointer ${
                                            convIndex ==
                                            prospectData?.conversations
                                              ?.length -
                                              1
                                              ? "disabled-opacity"
                                              : ""
                                          }`}
                                          disabled={
                                            convIndex ===
                                            prospectData?.conversations
                                              ?.length -
                                              1
                                          }
                                          onClick={() => {
                                            if (
                                              convIndex <
                                              prospectData?.conversations
                                                ?.length -
                                                1
                                            ) {
                                              setConvIndex(convIndex + 1)
                                              // checkSystemNumber(
                                              //   prospectData?.conversations?.[
                                              //     convIndex + 1
                                              //   ]?.systemNumber
                                              // )
                                            }
                                          }}
                                          title="Next conversation"
                                        >
                                          <img
                                            src={arrowRight}
                                            alt=""
                                            style={{ width: "20px" }}
                                          />
                                        </span>
                                      </h2>
                                    ) : (
                                      <h2>Conversations</h2>
                                    )}
                                    <div className="d-flex justify-content-between w-100 align-items-center flex-wrap gap-2">
                                      {prospectData?.conversations?.[convIndex]
                                        ?.systemNumber ? (
                                        <p>
                                          From :&nbsp;
                                          {
                                            prospectData?.conversations?.[
                                              convIndex
                                            ]?.systemNumber
                                          }
                                          &nbsp; To :&nbsp;
                                          {
                                            prospectData?.conversations?.[
                                              convIndex
                                            ]?.prospectNumber
                                          }
                                          <br />
                                        </p>
                                      ) : null}
                                    </div>
                                  </div>
                                  <button
                                    className="has-drop"
                                    onClick={() => setSideBar(true)}
                                  >
                                    <img src={threeDot} alt="" />
                                  </button>
                                  {/* <div className="conv-actions-wr">
                                    <i
                                      onClick={() => setSideBar(true)}
                                      className="ion ion-md-more conv-icon"
                                    />

                                    <i className="ion ion-ios-trash conv-icon text-danger" />
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </li>
                          {}
                          {prospectData?.conversations?.[convIndex]?.messages
                            ?.length ? (
                            prospectData?.conversations?.[
                              convIndex
                            ]?.messages?.map((item, index) => (
                              <li
                                className={`clearfix ${
                                  item?.status !== "received" && "odd"
                                }`}
                                key={item?._id}
                              >
                                <div className="conversation-text mb-3">
                                  <div
                                    className={
                                      index ==
                                        prospectData?.conversations?.[convIndex]
                                          ?.messages?.length -
                                          1 &&
                                      prospectData?.conversations?.[convIndex]
                                        ?.unreadCount
                                        ? "chat-message new-mg"
                                        : "chat-message"
                                    }
                                  >
                                    {index ==
                                      prospectData?.conversations?.[convIndex]
                                        ?.messages?.length -
                                        1 &&
                                    prospectData?.conversations?.[convIndex]
                                      ?.unreadCount ? (
                                      <div className="new-text ms-3">New</div>
                                    ) : null}
                                    <p>{item?.body}</p>
                                  </div>
                                  <div className="mt-1 chat-bottom-text">
                                    {moment(item?.createdAt)
                                      .local()
                                      .format("MM-DD-YYYY | hh:mm A")}
                                    &nbsp;|&nbsp;
                                    {item?.status?.charAt(0).toUpperCase() +
                                      item?.status?.slice(1)}
                                  </div>
                                </div>
                              </li>
                            ))
                          ) : (
                            <div className="chat-illus illus-empty-state ">
                              {isLoading ? (
                                <>
                                  <img
                                    src={illus}
                                    alt=""
                                    style={{ width: "90%", height: "90%" }}
                                  />
                                  <div className="d-flex justify-content-center flex-row">
                                    <img
                                      src={chatLoading}
                                      alt=""
                                      style={{ width: "21px", height: "21px" }}
                                    />
                                    <h3> Loading Conversation...</h3>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <img
                                    src={illus}
                                    alt=""
                                    style={{ width: "90%", height: "90%" }}
                                  />
                                  <h3>No Message Found</h3>
                                </>
                              )}
                            </div>
                          )}
                        </ul>
                      </SimpleBar>
                      <AvForm
                        className={`needs-validation validation-none chat-msg-form  ${
                          checkedNumber?.length &&
                          (checkedNumber[convIndex]?.status !== "Active" ||
                            checkedNumber[convIndex]?.status === "NotAvailable")
                            ? "pt-1"
                            : ""
                        }`}
                        onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                        key={sendReloadKey}
                      >
                        <div style={{ marginBottom: "8px" }}>
                          {/* {prospectData?.conversations?.length &&
                            console.log(
                              checkedNumber.some(
                                item =>
                                  item.number.toString() ==
                                    prospectData?.conversations[
                                      convIndex
                                    ]?.messages?.[0]?.systemNumber?.toString() &&
                                  item?.status === "Active"
                              ),
                              "999999999999999999999999999999999999999999999",
                              checkedNumber,
                              "============",
                              prospectData?.conversations[convIndex]
                                ?.messages?.[0]?.systemNumber
                            )} */}
                          {prospectData?.conversations?.length &&
                          !checkNumberLoading &&
                          !checkedNumber.some(
                            item =>
                              item.number?.toString() ==
                                prospectData?.conversations[
                                  convIndex
                                ]?.messages[0]?.systemNumber?.toString() &&
                              item?.status === "Active"
                          ) ? (
                            <span className="text-danger ">
                              <img
                                src={Exclamation}
                                style={{ width: "18px", height: "18px" }}
                                alt=""
                              />
                              <strong>
                                The System Number for this conversation is{" "}
                                {checkedNumber.some(
                                  item =>
                                    item.number ==
                                    prospectData?.conversations[convIndex]
                                      ?.messages[0]?.systemNumber
                                )
                                  ? "inactive!"
                                  : "not available! "}
                              </strong>
                            </span>
                          ) : null}
                          {prospectData?.conversations?.length &&
                          !checkNumberLoading &&
                          !checkedNumber.some(
                            item =>
                              item.number ==
                                prospectData?.conversations[convIndex]
                                  ?.messages[0]?.systemNumber &&
                              item?.status === "Active"
                          ) ? (
                            anotherActiveNumber !== null ? (
                              <strong
                                className="text-primary"
                                style={{
                                  textDecoration: "underLine",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setConvIndex(anotherActiveNumber)
                                }}
                              >
                                {" "}
                                Start conversation with active number{" "}
                              </strong>
                            ) : (
                              <strong
                                className="d-flex justify-content-flex-start align-items-center gap-2 mt-1  "
                                style={{
                                  display: "block",
                                }}
                              >
                                {" "}
                                <Switch
                                  name="toggleFor new conversation"
                                  onChange={e => {
                                    setCreateNewConv(e)
                                  }}
                                  checked={createNewConv}
                                  uncheckedIcon={false} // Hide the unchecked icon
                                  checkedIcon={false} // Hide the checked icon
                                  height={20}
                                  width={35}
                                  onColor="#5932ea"
                                  offColor="#d3d3d3"
                                />
                                Start conversation with new number{" "}
                              </strong>
                            )
                          ) : null}
                        </div>

                        <div className=" d-flex gap-2">
                          <div className="flex-grow-1 chat-inputbar">
                            <div className="form-group">
                              <div className="inp-grp">
                                <AvField
                                  name={"message"}
                                  type="textarea"
                                  className="chat-input mb-0"
                                  placeholder="Type a message"
                                  required
                                  value={send}
                                  validate={{
                                    required: {
                                      value: send?.length ? false : true,
                                      errorMessage: "Text field is required",
                                    },
                                  }}
                                  onChange={e => {
                                    setSend(e.target.value)
                                  }}
                                />
                              </div>
                            </div>
                            <span style={{ float: "right", fontSize: "12px" }}>
                              {send?.length <= 160 ? (
                                <i
                                  className="fas fa-check-circle fa-sm me-1"
                                  style={{ color: "Green" }}
                                ></i>
                              ) : null}
                              {send?.length <= 320 && send?.length > 160 ? (
                                <i
                                  className="fas fa-exclamation-circle fa-sm me-1"
                                  style={{ color: "orange" }}
                                ></i>
                              ) : null}
                              {send?.length > 320 ? (
                                <i
                                  className="fas fa-times-circle fa-sm me-1"
                                  style={{ color: "red" }}
                                ></i>
                              ) : null}
                              {send?.length ? send?.length : 0}/320
                            </span>
                          </div>
                          <div className=" chat-send">
                            <div className="">
                              <button
                                // id={"Popover1"}
                                className={"has-icon main-btn"}
                                title={"Quick reply"}
                                onClick={e => {
                                  e.preventDefault()
                                  setPopoverOpen({
                                    isOpen: !popoverOpen.isOpen,
                                  })
                                }}
                              >
                                <img src={astrixIcon} alt="" />
                              </button>

                              <button
                                type="submit"
                                color="success"
                                className={`main-btn success-btn ${
                                  (prospectData?.prospect?.litigator ||
                                    prospectData?.prospect?.dnc ||
                                    prospectData?.prospect?.Wrong_Number ||
                                    isSending ||
                                    !send?.trim()?.length ||
                                    !validMessage(send) ||
                                    !checkedNumber.some(
                                      item =>
                                        item.number ==
                                          prospectData?.conversations[convIndex]
                                            ?.messages[0]?.systemNumber &&
                                        item?.status === "Active"
                                    )) &&
                                  !createNewConv
                                    ? "disabled"
                                    : null
                                }`}
                                // disabled={
                                //   prospectData?.prospect?.litigator ||
                                //   prospectData?.prospect?.dnc ||
                                //   prospectData?.prospect?.Wrong_Number ||
                                //   isSending ||
                                //   !send?.trim()?.length ||
                                //   checkedNumber[convIndex]?.status !== "Active" ||
                                //   checkedNumber[convIndex]?.status === "NotAvailable"
                                // }
                                disabled={
                                  (prospectData?.prospect?.litigator ||
                                    prospectData?.prospect?.dnc ||
                                    prospectData?.prospect?.Wrong_Number ||
                                    isSending ||
                                    !send?.trim()?.length ||
                                    !validMessage(send) ||
                                    !checkedNumber.some(
                                      item =>
                                        item.number ==
                                          prospectData?.conversations[convIndex]
                                            ?.messages[0]?.systemNumber &&
                                        item?.status === "Active"
                                    )) &&
                                  !createNewConv
                                }
                                title={
                                  prospectData?.prospect?.litigator ||
                                  prospectData?.prospect?.dnc ||
                                  prospectData?.prospect?.Wrong_Number
                                    ? prospectData?.prospect?.litigator &&
                                      prospectData?.prospect?.dnc &&
                                      prospectData?.prospect?.Wrong_Number
                                      ? "Attempt to send to wrong number, DNC and unsubscribed recipient"
                                      : prospectData?.prospect?.dnc &&
                                        prospectData?.prospect?.litigator
                                      ? "Attempt to send to DNC and unsubscribed recipient"
                                      : prospectData?.prospect?.Wrong_Number &&
                                        prospectData?.prospect?.litigator
                                      ? "Attempt to send to wrong number and unsubscribed recipient"
                                      : prospectData?.prospect?.Wrong_Number &&
                                        prospectData?.prospect?.dnc
                                      ? "Attempt to send to wrong number and DNC"
                                      : prospectData?.prospect?.litigator
                                      ? "Attempt to send to unsubscribed recipient"
                                      : prospectData?.prospect?.dnc
                                      ? "Attempt to send to DNC"
                                      : prospectData?.prospect?.Wrong_Number
                                      ? "Attempt to send to wrong number"
                                      : null
                                    : !validMessage(send)
                                    ? "No data available for replacement with merge field "
                                    : null
                                }

                                // Attempt to send to wrong number and DNC
                              >
                                {checkNumberLoading ? (
                                  <img
                                    src={chatLoading}
                                    alt=""
                                    style={{ width: "21px", height: "21px" }}
                                  />
                                ) : (
                                  <img src={sendIcon} alt="" />
                                )}
                                <span className={"d-none d-lg-inline-block"}>
                                  {checkNumberLoading ? "Loading" : "Send"}
                                </span>
                                {/* <img src={sendIcon} alt="" />
                                <span className={"d-none d-lg-inline-block"}>
                                  Send
                                </span> */}
                              </button>
                            </div>
                          </div>
                        </div>
                      </AvForm>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>

      {/* quick replies modal  */}

      {/* edit note new modal  */}
      <Modal
        isOpen={editNoteModal?.isOpen}
        toggle={() => {
          setEditNoteModal({ isOpen: !editNoteModal?.isOpen })
        }}
        className="c-modal"
        centered={true}
      >
        <div className="modal-body ">
          <h5 className="modal-title mt-0 mb-3"> Edit Note</h5>
          <button
            type="button"
            onClick={() => {
              setEditNoteModal({ isOpen: false, note: "", note_id: "" })
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <img src={close} alt="" />
          </button>

          {
            <AvForm
              className="needs-validation"
              onValidSubmit={(e, v) => handleEditNote(e, v)}
            >
              <Row>
                <Col xl="12">
                  <FormGroup className="mb-3">
                    <AvField
                      type="textarea"
                      className="form-control cc-form-control"
                      name="note"
                      rows={5}
                      label="Note"
                      validate={{ required: { value: true } }}
                      value={editNoteModal?.note}
                    ></AvField>
                  </FormGroup>
                </Col>
              </Row>

              <div className="text-end py-2 d-flex justify-content-end align-items-center gap-2">
                <Button
                  className="main-btn secondary-btn"
                  onClick={() => {
                    setEditNoteModal({ isOpen: false, note: "", note_id: "" })
                    setSideBar(true)
                  }}
                  role="button"
                >
                  <span>Cancel</span>
                </Button>
                <Button type="submit" color="primary" className="main-btn">
                  <span>Submit</span>
                </Button>{" "}
              </div>
            </AvForm>
          }
        </div>
      </Modal>

      {deleteProspect?.isOpen ? (
        <SweetAlert
          title="Are you sure?"
          info
          showCancel
          confirmBtnText="Yes, remove it"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="dark"
          onConfirm={() => {
            handleDeleteProspect()
          }}
          onCancel={() => {
            setDeleteProspect({ isOpen: false })
          }}
        >
          You want to remove{" "}
          <stong>
            {" "}
            {prospectData?.prospect.first_name +
              " " +
              prospectData?.prospect.last_name}{" "}
          </stong>
          ? You won't be able to revert this!
        </SweetAlert>
      ) : null}
      {dripRemoveConfirm?.isOpen ? (
        <SweetAlert
          title="Are you sure?"
          info
          showCancel
          confirmBtnText="Yes, remove it"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="dark"
          onConfirm={() => {
            removeDrip(dripRemoveConfirm?.dripProspectId)
          }}
          onCancel={() => {
            setDripRemoveConfirm({ isOpen: false })
          }}
        >
          <div className="d-flex flex-column align-items-start gap-1 p-2 border-bottom mb-2">
            <div>
              <strong>Drip Start Date: </strong>{" "}
              {moment
                .utc(dripRemoveConfirm?.dripData?.dripStartDate)
                .local()
                .format("YYYY-MM-DD hh:mm:ss A")}
            </div>
          </div>
          You want to remove drip? You won't be able to revert this!
        </SweetAlert>
      ) : null}

      {confirmAction?.isOpen ? (
        <SweetAlert
          title="Are you sure?"
          info
          showCancel
          confirmBtnText="Yes, remove it"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="dark"
          onConfirm={() => {
            confirmAction.confirm()
          }}
          onCancel={() => {
            setConfirmAction({ isOpen: false })
          }}
        >
          {confirmAction.text}? You won't be able to revert this!
        </SweetAlert>
      ) : null}

      {/* add drip new modal  */}
      <Modal
        isOpen={addDripModal}
        toggle={() => {
          toggleDrip()
        }}
        backdrop="static"
        centered={true}
        className="drip-select-modal"
        key={dripReloadKey}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Select A Drip Automations</h5>
          <button
            type="button"
            onClick={() => {
              toggleDrip()
              setDripTemplateDetail({ isOpen: false, id: null, data: {} })
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="drip-select-container">
            <div key={dripReloadKey}>
              {!dripTemplateDetail?.isOpen ? (
                <div className="drip-aut-list">
                  <table className="c-table table">
                    <thead>
                      <tr>
                        <th>Drip Name</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {masterData?.dripTemplate &&
                        masterData?.dripTemplate?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item.name}</td>
                              <td>
                                <button
                                  className="main-btn sm secondary-btn min-btn"
                                  onClick={() => {
                                    getDripTemplateDetail(item._id)
                                  }}
                                >
                                  <span>View Messages</span>
                                </button>
                              </td>
                              <td>
                                <button
                                  className="main-btn sm white-icon min-btn"
                                  onClick={() => {
                                    addDrip(item._id)
                                  }}
                                >
                                  <img src={dripIconBorder} alt="" />
                                  <span>Select</span>
                                </button>
                              </td>
                            </tr>
                          )
                        })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="drip-messages-container">
                  <h3 className="head">{dripTemplateDetail?.data?.name}</h3>
                  <div className="drip-message-main-container">
                    {dripTemplateDetail?.data?.data?.map((item, index) => {
                      return (
                        <div className="drip-message-wrap" key={index}>
                          <div className="top">
                            <h3 className="head">Message {index + 1}</h3>
                            <span>Day {item.day}</span>
                          </div>
                          <p className="para">{item?.message}</p>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              if (dripTemplateDetail?.isOpen) {
                setDripTemplateDetail({
                  isOpen: false,
                  id: null,
                  data: {},
                })
              } else {
                setDripTemplateDetail({
                  isOpen: false,
                  id: null,
                  data: {},
                })
                setAddDripModal(false)
              }
            }}
          >
            {dripTemplateDetail?.isOpen ? "Back" : "Cancel"}
          </button>
          {dripTemplateDetail?.isOpen ? (
            <button
              type="submit"
              className="btn btn-primary"
              onClick={() => {
                addDrip()
              }}
            >
              Select
            </button>
          ) : null}
        </div>
      </Modal>

      <Modal
        isOpen={popoverOpen.isOpen}
        toggle={() => {
          setPopoverOpen({ isOpen: !popoverOpen.isOpen })
        }}
        className="c-modal modal-lg"
        // backdrop="static"
        centered={true}
      >
        <div className="modal-body">
          <h5 className="modal-title mt-0 mb-2 ">Quick Replies</h5>
          <button
            type="button"
            onClick={() => {
              setPopoverOpen({ isOpen: !popoverOpen.isOpen })
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <img src={close} alt="" />
          </button>

          {!masterData.quickReplies?.length ? (
            <div className="spinner-wr">
              <Spinner style={{ color: "#5932ea" }} />
            </div>
          ) : null}
          <DataTable
            // title=""
            columns={phoneNumberColumns}
            data={masterData.quickReplies}
            pagination
            paginationPerPage={5}
            paginationRowsPerPageOptions={[5, 15, 25, 50]}
            noHeader={true}
            noTableHead={true}
            // className="c-table"
          />
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default DetailProspect
